import React, { useEffect, useState } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { format, addDays, parseISO, startOfDay, isSameDay } from 'date-fns';

const BASE_WIDTH = 1366;
const WIDTH_INCREMENT = 150;
const INITIAL_DAYS = 7;

// Function to generate display dates from today onward based on a number of days
const getDisplayDates = (numDays) => {
  const today = startOfDay(new Date());
  const dates = [];
  for (let i = 0; i < numDays; i++) {
    const date = addDays(today, i);
    dates.push({
      label: i === 0 ? 'Today' : format(date, 'EEE, dd MMM'),
      date: date,
    });
  }
  return dates;
};

// Custom tooltip for hover information
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        {payload.map((entry, index) => (
          <div key={index} className='value-wrapper'>
            <div className='dot' style={{ backgroundColor: entry.fill }}></div>
            <p className='label'>
              {entry.name}: {entry.value}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

// Function to calculate number of days to show based on window width
const calculateNumDays = (width) => {
  if (width <= BASE_WIDTH) {
    return INITIAL_DAYS;
  }
  return INITIAL_DAYS + Math.floor((width - BASE_WIDTH) / WIDTH_INCREMENT);
};

const Burndown = ({ burndown }) => {
  const [numDays, setNumDays] = useState(calculateNumDays(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setNumDays(calculateNumDays(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!burndown) return null;

  const displayDates = getDisplayDates(numDays);

  // Transform burndown data for the chart
  const data = displayDates.map(({ label, date }) => {
    const dayData = burndown.find((day) =>
      isSameDay(parseISO(day.day), date),
    ) || {
      first_emails: 0,
      follow_ups: 0,
      unapproved_user: 0,
      unapproved_chain: 0,
      unapproved_unwritten: 0,
    };
    return {
      name: label,
      first_emails: dayData.first_emails,
      follow_ups: dayData.follow_ups,
      unapproved_user: dayData.unapproved_user,
      unapproved_chain: dayData.unapproved_chain,
      unapproved_unwritten: dayData.unapproved_unwritten,
    };
  });

  const isTopMostBar = (entry, index, barDataKey) => {
    // Check if this bar is the highest non-zero bar for this data point
    const keys = Object.keys(entry);
    const values = Object.values(entry);

    return (
      keys.findIndex((key) => key === barDataKey) ===
      values.findLastIndex((value) => value !== 0)
    );
  };

  return (
    <div>
      <ResponsiveContainer width='100%' height={200}>
        <ComposedChart data={data}>
          <CartesianGrid vertical={false} stroke='#D9D9D9' />
          <XAxis
            dataKey='name'
            axisLine={{ stroke: '#a3abb7' }}
            tickLine={false}
            tickMargin={8}
            tick={{
              fontSize: '13px',
              color: '#152647',
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickCount={6}
            width={30}
            tick={{
              fontSize: '13px',
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey='first_emails'
            stackId='a'
            fill='#07A0C3'
            name='First Emails'
            barSize={64}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-first-emails-${index}`}
                radius={
                  isTopMostBar(entry, index, 'first_emails') ? [4, 4, 0, 0] : 0
                }
              />
            ))}
          </Bar>
          <Bar
            dataKey='follow_ups'
            stackId='a'
            fill='#64B6AC'
            name='Follow-ups'
            barSize={64}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-follow-ups-${index}`}
                radius={
                  isTopMostBar(entry, index, 'follow_ups') ? [4, 4, 0, 0] : 0
                }
              />
            ))}
          </Bar>
          <Bar
            dataKey='unapproved_user'
            stackId='a'
            fill='rgba(255, 208, 4, 1)'
            name='Needs your approval'
            barSize={64}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-unapproved_user-${index}`}
                radius={
                  isTopMostBar(entry, index, 'unapproved_user')
                    ? [4, 4, 0, 0]
                    : 0
                }
              />
            ))}
          </Bar>
          <Bar
            dataKey='unapproved_chain'
            stackId='a'
            fill='rgba(255, 208, 4, 0.2)'
            name='Follow-up to unapproved'
            barSize={64}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-unapproved_chain-${index}`}
                radius={
                  isTopMostBar(entry, index, 'unapproved_chain')
                    ? [4, 4, 0, 0]
                    : 0
                }
              />
            ))}
          </Bar>
          <Bar
            dataKey='unapproved_unwritten'
            stackId='a'
            fill='rgba(0, 0, 0, 0.2)'
            name='Unwritten (pending)'
            barSize={64}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-unapproved_chain-${index}`}
                radius={
                  isTopMostBar(entry, index, 'unapproved_unwritten')
                    ? [4, 4, 0, 0]
                    : 0
                }
              />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Burndown;
