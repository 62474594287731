import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import './conversation.css';
import DreamModalInner from './DreamModalInner';
import DreamSideModalLoader from './DreamSideModalLoader';
import DreamSideModal from './DreamSideModal';
import MessageText from './MessageText';
import ConversationOpts from './ConversationsOpts';

function ConversationModal({ show, onHide, id, idIsScheduledEmail }) {
  const { getAccessTokenSilently } = useAuth0();
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConversationDetails = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(
          `/conversations/${id}${idIsScheduledEmail ? '?asScheduledEmail=true' : ''}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setConversation(response.data);
      } catch (error) {
        console.error('Error fetching conversation details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchConversationDetails();
    }
  }, [id, getAccessTokenSilently]);

  if (loading || !conversation) {
    return (
      <DreamSideModal show={show} onHide={onHide}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading Conversation</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  const renderStatus = (conversation_status) => {
    if (conversation_status !== null) {
      const cs =
        conversation_status.slice(0, 1).toUpperCase() +
        conversation_status.slice(1).toLowerCase();

      let add;
      if (cs === 'Replied') {
        add = 'neutral';
      } else {
        add = 'positive';
      }

      return <p className={`status-tag ${add}`}>{cs}</p>;
    }
  };

  const getAKnownOrg = () => {
    const prospectWithOrg = conversation.prospects.find(
      (x) => !!x.known_org_name,
    );

    if (!prospectWithOrg) {
      return conversation.prospects[0].name;
    }

    return prospectWithOrg.known_org_name;
  };

  return (
    <DreamSideModal show={show} handleClose={onHide} paired>
      <DreamModalInner.OptsWrapper>
        <ConversationOpts
          onHide={onHide}
          conversation={conversation}
          linkedIn={conversation?.prospects[0]?.linkedin_url}
          koLinkedIn={conversation?.prospects[0]?.known_org_linkedin_url}
          koDomain={conversation?.prospects[0]?.known_org_domain}
          setConversation={setConversation}
          offlineToggle
          forward
        />
        <DreamModalInner>
          <DreamModalInner.Header>
            Conversation with {getAKnownOrg()}
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='conversation-wrapper'>
              <div className='heading'>
                <div className='set'>
                  <p>Last message</p>
                  <p>
                    {new Date(conversation.last_message_time).toLocaleString()}
                  </p>
                </div>
                <div className='set'>
                  <p>Stage</p>
                  <p>{renderStatus(conversation.status)}</p>
                </div>
              </div>
              <div className='messages-wrapper'>
                <h6>Message thread</h6>
                {conversation.message_texts.reverse().map((text, index) => (
                  <div className='message-wrapper' key={index}>
                    <MessageText
                      message={text}
                      count={`${conversation.message_texts.length - index}/${conversation.message_texts.length}`}
                      showTime
                    />
                  </div>
                ))}
              </div>
            </div>
          </DreamModalInner.Body>
        </DreamModalInner>
      </DreamModalInner.OptsWrapper>
    </DreamSideModal>
  );
}

export default ConversationModal;
