import React from 'react';
import BaseType from './BaseType';

const Viewed = ({ items, children, count, time, onClick, expanded }) => {
  const org = items[0].metadata?.known_org;
  return (
    <BaseType
      logos={[org?.logo_url]}
      mainText={`${org?.name}`}
      tagText={`${count} email opens`}
      tagType='semi-positive'
      time={time}
      onClick={onClick}
      expanded={expanded}
    >
      {children}
    </BaseType>
  );
};

export default Viewed;
