import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import ResearchPointModal from '../analyst/ResearchPointModal';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import './funnel.css';

import GoogleIcon from '../../icons/google.png';
import LinkedInIcon from '../../icons/linkedin.png';
import OpenAIIcon from '../../icons/openai.png';
import CrunchbaseIcon from '../../icons/crunchbase.png';

const sourceIcons = {
  google: <img src={GoogleIcon} alt='Google icon' />,
  openai: <img src={OpenAIIcon} alt='OpenAI icon' />,
  linkedin: <img src={LinkedInIcon} alt='OpenAI icon' />,
  crunchbase: <img src={CrunchbaseIcon} alt='Crunchbase icon' />,
};

const ResearchPointSelectorModal = ({
  show,
  handleClose,
  onChoose,
  loading,
  initialResearchPoints = [],
  type,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [researchPointsLoading, setResearchPointsLoading] = useState(false);
  const [showResearchPointModal, setShowResearchPointModal] = useState(false);
  const [selectedResearchPoints, setSelectedResearchPoints] = useState([]);
  const [researchPoints, setResearchPoints] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(null);

  const fetchData = async (id, setSelected) => {
    try {
      setResearchPointsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await api.get('/analyst/research-points', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fetchedResearchPoints = response.data
        .filter((dp) => dp.type === type)
        .filter((x) => !initialResearchPoints.find((y) => y.id === x.id));

      setResearchPoints(fetchedResearchPoints);
      setResearchPointsLoading(false);

      if (id && setSelected) {
        console.log('here');
        setSelectedResearchPoints((prev) => {
          const found = fetchedResearchPoints.find((x) => x.id === id);
          if (found) {
            return [...prev, found];
          } else {
            return prev;
          }
        });
      }
    } catch (error) {
      console.error('Error fetching research points:', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show, initialResearchPoints, getAccessTokenSilently]);

  const handleSelect = (datapoint) => {
    setDeleteWarning(null);
    setSelectedResearchPoints((prevSelected) => {
      if (prevSelected.includes(datapoint)) {
        return prevSelected.filter((dp) => dp !== datapoint);
      } else {
        return [...prevSelected, datapoint];
      }
    });
  };

  const handleDeleteConfirm = async (datapoint) => {
    try {
      const token = await getAccessTokenSilently();
      setResearchPoints((prev) => prev.filter((x) => x.id !== datapoint.id));
      await api.delete(`/analyst/research-points/${datapoint.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error fetching research points:', error);
      alert(
        'An error occured deleting the datapoint, please refresh and try again',
      );
      setResearchPoints((prev) => [...prev, datapoint]);
    }
  };

  const handleChoose = () => {
    onChoose(selectedResearchPoints);
  };

  const handleCreateNew = () => {
    setShowResearchPointModal(true);
  };

  const handleDeleteClick = (event, datapoint) => {
    event.stopPropagation();
    setDeleteWarning(datapoint.id);
    setSelectedResearchPoints((prevSelected) =>
      prevSelected.filter((x) => x.id !== datapoint.id),
    );
  };
  return (
    <>
      <DreamSideModal show={show} handleClose={handleClose} position='right'>
        <DreamModalInner>
          {loading ? (
            <DreamSideModalLoader>{loading}</DreamSideModalLoader>
          ) : (
            <>
              <DreamModalInner.Header>
                Add research to table
              </DreamModalInner.Header>
              <DreamModalInner.Body>
                <div className='datapoint-selector-modal analyst'>
                  <div className='datapoints-wrapper'>
                    {researchPointsLoading && (
                      <div className='dp-loading-wrapper'>
                        <Spinner />
                      </div>
                    )}
                    {!researchPointsLoading && (
                      <>
                        {researchPoints.length > 0 ? (
                          <div className='datapoints-selection-wrapper'>
                            {researchPoints.map((datapoint) => (
                              <div
                                className={`datapoint-frame  ${selectedResearchPoints.includes(datapoint) ? 'active' : ''}`}
                                key={datapoint.id}
                              >
                                <div
                                  className={`datapoint-item`}
                                  onClick={() => handleSelect(datapoint)}
                                >
                                  <span className='name'>{datapoint.name}</span>
                                  <span className='sources'>
                                    {datapoint?.sources?.map(
                                      (source) => sourceIcons[source],
                                    )}
                                  </span>

                                  <span
                                    className={`delete-button ${deleteWarning === datapoint.id && 'active'}`}
                                    onClick={(event) =>
                                      handleDeleteClick(event, datapoint)
                                    }
                                  >
                                    <TrashIcon />
                                  </span>
                                </div>
                                {deleteWarning === datapoint.id && (
                                  <div className='delete-line'>
                                    <b>
                                      Deleting will not remove research from
                                      lists
                                    </b>
                                    <span
                                      onClick={() =>
                                        handleDeleteConfirm(datapoint)
                                      }
                                    >
                                      Delete
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className='empty-state'>
                            <ResearchPointIcon />
                            <p>Recently used research will appear here </p>
                            <Button onClick={handleCreateNew}>
                              View research options
                              <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {researchPoints.length > 0 && !researchPointsLoading && (
                    <div className='add-new-wrapper'>
                      <Button onClick={handleCreateNew}>
                        View research options
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  )}
                </div>
              </DreamModalInner.Body>
              <DreamModalInner.Footer>
                <div className='analyst'>
                  <Button
                    variant='primary'
                    onClick={handleChoose}
                    disabled={selectedResearchPoints.length === 0}
                  >
                    Add selected
                  </Button>
                </div>
                <Button variant='outline-primary' onClick={handleClose}>
                  Cancel
                </Button>
              </DreamModalInner.Footer>
            </>
          )}
        </DreamModalInner>
      </DreamSideModal>

      {showResearchPointModal && (
        <ResearchPointModal
          show={showResearchPointModal}
          handleClose={(id) => {
            setShowResearchPointModal(false);
            fetchData(id, true); // Refresh datapoints after closing the ResearchPointModal
          }}
          type={type}
        />
      )}
    </>
  );
};

export default ResearchPointSelectorModal;
