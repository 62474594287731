import React, { useState } from 'react';
import DreamSideModal from './DreamSideModal';
import DreamModalInner from './DreamModalInner';
import ImportedProspect from './ImportedProspect';
import './imported-prospects-list-modal.css';

function ImportedProspectsListModal({ show, onHide, prospects, company }) {
  const [showProspectData, setShowProspectData] = useState(null);

  const handleOpen = (prospect) => {
    setShowProspectData(prospect);
  };
  return (
    <>
      <DreamSideModal show={show} handleClose={onHide}>
        <DreamModalInner>
          <DreamModalInner.Header>
            Prospects imported for {company.name}
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='imported-prospects-list'>
              {prospects.map((prospect) => {
                return (
                  <div
                    className='prospect-set'
                    key={prospect.name + prospect.email}
                  >
                    <div className='left-side'>
                      <p className='name'>{prospect.name}</p>
                      <p className='email'>{prospect.email || 'None'}</p>
                    </div>
                    <div className='right-side'>
                      {Object.keys(prospect).length > 2 && (
                        <span onClick={() => handleOpen(prospect)}>
                          View Data
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </DreamModalInner.Body>
        </DreamModalInner>
      </DreamSideModal>
      {showProspectData && (
        <ImportedProspect
          show
          onHide={() => setShowProspectData(null)}
          prospect={showProspectData}
        />
      )}
    </>
  );
}

export default ImportedProspectsListModal;
