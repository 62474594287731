import React, { useState } from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import manifests from '../../utils/function-manifests';

import './choose-filter-type-modal.css';

function ChooseFilterTypeModal({ onHide, show, onChoose }) {
  const company_filter = manifests.company_filter;
  const [chosenType, setChosenType] = useState(null);

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>Choose Filter Type</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='cft-modal'>
            <h6>Available filters</h6>
            <div className='filter-set'>
              {company_filter
                .filter((x) => !x.hide_from_selection)
                .map((filter, i) => {
                  return (
                    <div
                      className={`filter ${chosenType === filter ? 'active' : ''} `}
                      key={i}
                      onClick={() => setChosenType(filter)}
                    >
                      {filter.icon}
                      <p className='name'>{filter.name}</p>
                      <p>{filter.description}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={() => onChoose(chosenType)} disabled={!chosenType}>
            Add filter{' '}
            <div className='yellow-icon'>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </Button>
          <Button variant='outline-primary' onClick={() => onHide()}>
            Cancel
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default ChooseFilterTypeModal;
