import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import api from '../../api';

const FixedOrRelativeDate = ({
  onChange,
  value: { comparison, value, operator, types = [], exclude_types = false },
  includeNoOption,
  includeCRMEngagementTypes,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [engagementTypes, setEngagementTypes] = useState([]);

  useEffect(() => {
    const fetchEngagementTypes = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/crm/engagements/types', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEngagementTypes(response.data.types);
      } catch (error) {
        console.error('Error fetching engagement types:', error);
      }
    };

    fetchEngagementTypes();
  }, [getAccessTokenSilently]);

  const unitToMilliseconds = {
    days: 24 * 60 * 60 * 1000,
    weeks: 7 * 24 * 60 * 60 * 1000,
    months: 30 * 24 * 60 * 60 * 1000,
  };

  const calculateRelativeInput = (milliseconds) => {
    const days = milliseconds / unitToMilliseconds.days;
    const weeks = milliseconds / unitToMilliseconds.weeks;
    const months = milliseconds / unitToMilliseconds.months;

    if (Number.isInteger(months) && months > 0) {
      return { amount: months, unit: 'months' };
    } else if (Number.isInteger(weeks) && weeks > 0) {
      return { amount: weeks, unit: 'weeks' };
    } else {
      return { amount: days, unit: 'days' };
    }
  };

  const relativeInput = calculateRelativeInput(value);

  const operatorOptions = {
    '>': 'After',
    '>=': 'After or on',
    '<': 'Before',
    '<=': 'Before or on',
  };

  const handleRelativeChange = (newRelativeInput) => {
    const milliseconds =
      newRelativeInput.amount * unitToMilliseconds[newRelativeInput.unit];
    onChange({
      value: milliseconds,
      operator,
      comparison,
      types,
      exclude_types,
    });
  };

  const handleValueChange = (newValue, isRelative = false) => {
    onChange({
      value: newValue,
      operator,
      types,
      exclude_types,
      comparison: isRelative ? 'relative' : undefined,
    });
  };

  const handleOperatorChange = (newOperator) => {
    onChange({
      value,
      operator: newOperator,
      comparison,
      exclude_types,
      types,
    });
  };

  const handleEngagementTypesChange = (selectedOptions) => {
    onChange({
      value,
      operator,
      comparison,
      types: selectedOptions.map((option) => option.value),
      exclude_types,
    });
  };

  const handleExcludeTypesChange = (exclude) => {
    onChange({
      value,
      operator,
      comparison,
      types,
      exclude_types: exclude,
    });
  };

  return (
    <div
      className={`fixed-or-relative-date ${includeCRMEngagementTypes ? 'crm' : ''}`}
    >
      <Form.Control
        as='select'
        value={operator}
        size='lg'
        onChange={(e) => handleOperatorChange(e.target.value)}
      >
        {includeNoOption && (
          <option key='no' value=''>
            {includeNoOption}
          </option>
        )}
        {Object.entries(operatorOptions).map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Form.Control>
      {operator && (
        <>
          <div className='radio-comparison'>
            <Form.Check
              type='radio'
              label='Exact date'
              name='date-type'
              checked={comparison !== 'relative'}
              onChange={() => handleValueChange(value)}
            />
            <Form.Check
              type='radio'
              label='Relative date'
              name='date-type'
              checked={comparison === 'relative'}
              onChange={() => handleValueChange(value, true)}
            />
          </div>

          {comparison === 'relative' ? (
            <div className='relative-date-inputs'>
              <Form.Control
                type='number'
                value={relativeInput.amount}
                onChange={(e) =>
                  handleRelativeChange({
                    ...relativeInput,
                    amount: parseInt(e.target.value, 10) || 0,
                  })
                }
                size='lg'
                placeholder='Amount'
              />
              <Form.Control
                as='select'
                value={relativeInput.unit}
                onChange={(e) =>
                  handleRelativeChange({
                    ...relativeInput,
                    unit: e.target.value,
                  })
                }
              >
                <option value='days'>Days ago</option>
                <option value='weeks'>Weeks ago</option>
                <option value='months'>Months ago</option>
              </Form.Control>
            </div>
          ) : (
            <Form.Control
              type='date'
              value={value}
              size='lg'
              onChange={(e) => handleValueChange(e.target.value)}
            />
          )}
        </>
      )}
      {operator && includeCRMEngagementTypes && (
        <div className='engagement-types-area'>
          <div className='exclusion-radios'>
            <Form.Check
              type='radio'
              id='include-selected'
              name='stage-behaviour'
              label='Include selected types'
              checked={!exclude_types}
              onChange={() => handleExcludeTypesChange(false)}
            />
            <Form.Check
              type='radio'
              id='exclude-selected'
              name='stage-behaviour'
              label='Exclude selected types'
              checked={exclude_types}
              onChange={() => handleExcludeTypesChange(true)}
            />
          </div>

          <Select
            isMulti
            value={types.map((type) => ({ label: type, value: type }))}
            options={engagementTypes.map((type) => ({
              value: type.type,
              label: `${type.type} (${type.count})`,
            }))}
            onChange={handleEngagementTypesChange}
            placeholder='Select activity types'
            className='additional-options-select'
            classNamePrefix='additional-options-select'
          />
        </div>
      )}
    </div>
  );
};

export default FixedOrRelativeDate;
