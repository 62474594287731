import React, { useEffect, useState } from 'react';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModal from '../../misc/DreamSideModal';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import api from '../../../api';

import './research-related-prospect-search-modal.css';

function ImportProspectsFromListModal({
  show,
  handleClose,
  oagentId,
  refreshProspectSearch,
  editMode,
  prospectSearchId,
  metadata,
}) {
  const [numProspects, setNumProspects] = useState();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setNumProspects(metadata?.num_prospects || 3);
  }, [metadata, editMode, oagentId]);

  const handleSaveSettings = async () => {
    setSaving(true);
    const httpMethod = editMode && prospectSearchId ? 'put' : 'post';

    try {
      const token = await getAccessTokenSilently();
      const endpoint =
        editMode && prospectSearchId
          ? `/oagents/${oagentId}/prospect-search/${prospectSearchId}`
          : `/oagents/${oagentId}/prospect-search`;

      await api[httpMethod](
        endpoint,
        {
          type: 'imported_prospects',
          metadata: {
            num_prospects: numProspects,
            dataKey: 'Prospects',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      refreshProspectSearch();

      setSaving(false);
      handleClose();
    } catch (error) {
      console.error(
        `Error saving prospect search (${httpMethod.toUpperCase()}):`,
        error,
      );
      setSaving(false);
      setError('Failed to save settings. Please try again.');
    }
  };

  if (saving || loading) {
    return (
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {saving ? 'Saving Search' : 'Loading Data'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          Import prospects listed in target list
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form research-related-prospect-search'>
            <div className='top-description'>
              <p>
                When you've imported prospects from a CSV or through a CRM, they
                will appear in the target list next to the companies they work
                for.
              </p>
              <p>
                This type of search will import those prospects along with their
                linkedin information (if found).
              </p>
            </div>
            <Form.Group className='range-flex'>
              <Form.Label>Maximum prospects to return per company</Form.Label>
              <Form.Text>
                Each prospect added to a campaign costs 1 credit.
              </Form.Text>
              <RangeSlider
                value={numProspects}
                onChange={(e) => setNumProspects(e.target.value)}
                min={1}
                max={20}
                tooltipLabel={(currentValue) => `${currentValue}`}
                variant='info'
                size='lg'
              />
              <div className='indicator-line'>
                <span>1</span>
                <span>20</span>
              </div>
            </Form.Group>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={() => handleSaveSettings()}>Save</Button>
          <Button variant='outline-primary' onClick={() => handleClose()}>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default ImportProspectsFromListModal;
