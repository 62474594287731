import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faPlus } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as RightPointerIcon } from '../../icons/right-pointer.svg';
import { ReactComponent as FollowUpIcon } from '../../icons/follow-up.svg';
import { ReactComponent as NewThreadIcon } from '../../icons/new-thread.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';

import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

import ConfirmModal from '../confirm-modals/ConfirmModal';

import './emails.css';

const EmailList = ({
  sequenceId,
  emails,
  setEmails,
  onChooseEmail,
  selectedEmail,
  refreshEmails,
  onDelete,
  editMode,
  wizard,
}) => {
  const [alertMessage, setAlertMessage] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [pendingEmails, setPendingEmails] = useState(emails);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);

  const saveEmails = async (emails) => {
    const firstEmail = emails.sort((a, b) => a.email_order - b.email_order)[0];
    if (!firstEmail.is_new_thread) {
      setAlertMessage(
        'The first active email in the sequence must start a new thread.',
      );
      return;
    }
    setAlertMessage('');
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/emails/${sequenceId}`,
        { emails },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setEmails(emails);
      refreshEmails(token, selectedEmail);
    } catch (error) {
      console.error('Error saving emails:', error);
    }
  };

  useEffect(() => {
    setPendingEmails(emails);
  }, [emails]);

  const handleAddEmail = () => {
    const isFirstEmail = pendingEmails.length === 0;

    // In the wizard we haven't yet sent our first email so it's all good to create messages not in draft.
    // Outside of the wizard though and messages must be created in draft first.
    const newEmail = {
      idForDropping: 'tmp_' + Date.now(),
      delay: 1,
      email_order: pendingEmails.length,
      contents: '',
      active: wizard ? true : false,
      is_new_thread: isFirstEmail,
      subject_type: 'agent_generate',
      subject_value: '',
      draft: wizard ? false : true,
    };
    setPendingEmails([...pendingEmails, newEmail]);
    saveEmails([...pendingEmails, newEmail]);
  };

  const handleDeleteEmail = (emailId) => {
    const updatedEmails = pendingEmails.filter(
      (email) => email.idForDropping !== emailId && email.id !== emailId,
    );
    setPendingEmails(updatedEmails);
    saveEmails(updatedEmails);
    onDelete(emailId);
  };

  const confirmDeleteEmail = (emailIdForDropping) => {
    setShowConfirmModal(true);
    setEmailToDelete(emailIdForDropping);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (pendingEmails.find((x) => x.draft)) {
      alert('Cannot change order of emails while drafts are active');
      return;
    }

    const updatedEmails = Array.from(pendingEmails);
    const [removed] = updatedEmails.splice(result.source.index, 1);
    updatedEmails.splice(result.destination.index, 0, removed);

    // Update the order of emails
    updatedEmails.forEach((email, index) => {
      email.email_order = index;
    });

    // Ensure the first email is always a new thread
    if (updatedEmails[0]) {
      updatedEmails[0].is_new_thread = true;
    }

    saveEmails(updatedEmails);
  };

  const renderEmailLabel = (index, email) => {
    if (email.is_new_thread) {
      return index === 0 ? 'Initial Email' : 'New Thread';
    }
    return `Follow Up ${index}${email.draft ? ' (Draft)' : ''}`;
  };

  const isActive = (email) => {
    return email?.id
      ? email.id === selectedEmail?.id
      : email.idForDropping === selectedEmail?.idForDropping;
  };

  return (
    <>
      <Form className='email-list-form'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='emails'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='dream-list'
              >
                {alertMessage && <Alert variant='danger'>{alertMessage}</Alert>}
                {pendingEmails
                  .sort((a, b) => (a.email_order < b.email_order ? -1 : 1))
                  .map((email, index) => (
                    <Draggable
                      key={email.idForDropping || email.id}
                      draggableId={`email-${email.idForDropping || email.id}`}
                      index={index}
                      isDragDisabled={editMode}
                    >
                      {(provided) => (
                        <div
                          className={`email-editor ${email.is_new_thread ? '' : 'follow-up'} ${email.draft ? 'draft' : ''}
                            ${isActive(email) && 'active'} ${editMode && !isActive(email) && 'disabled'}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          onClick={() => {
                            if (!editMode) {
                              return onChooseEmail(email);
                            }
                          }}
                        >
                          {isActive(email) && <RightPointerIcon />}
                          <div className='label-wrapper'>
                            <div className='icon-area'>
                              <div
                                className='drag-handle'
                                {...provided.dragHandleProps}
                              >
                                <FontAwesomeIcon icon={faGripVertical} />
                              </div>
                              {email.is_new_thread ? (
                                <NewThreadIcon />
                              ) : (
                                <FollowUpIcon />
                              )}
                            </div>
                            <div className='form-left'>
                              <Form.Label className='email-name-label'>
                                {renderEmailLabel(index, email)}
                              </Form.Label>
                            </div>
                            {email.email_order !== 0 && (
                              <div className='delete-button'>
                                <TrashIcon
                                  onClick={(e) => {
                                    if (!editMode) {
                                      e.stopPropagation();
                                      confirmDeleteEmail(
                                        email.idForDropping || email.id,
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
                <div className='add-email-wrapper'>
                  <Button
                    variant='primary'
                    onClick={handleAddEmail}
                    disabled={editMode}
                  >
                    Add new email to sequence
                    <div className='yellow-icon'>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirm={() => {
          handleDeleteEmail(emailToDelete);
          setShowConfirmModal(false);
        }}
        title='Confirm Delete'
        bodyText='Are you sure you want to delete this email from the sequence?'
        confirmLabel='Delete'
        cancelLabel='Cancel'
      />
    </>
  );
};

export default EmailList;
