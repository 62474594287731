import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import { prospectSearchTypes as searchTypes } from '../../../utils/prospect-search-types';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { Button, Spinner, Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import './prospects-step.css';

import AddProspectSearchModal from './AddProspectSearchModal';
import ConfirmModal from '../../confirm-modals/ConfirmModal';
import GenericEmailGuidanceModal from './GenericEmailGuidanceModal';

function ProspectsSettings({ oagentId, setCanSearch }) {
  const { getAccessTokenSilently } = useAuth0();
  const [prospectSearches, setProspectSearches] = useState(null);
  const [searchSettings, setSearchSettings] = useState({});
  const [showAddStepModal, setShowAddStepModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showEmailGuidanceModal, setShowEmailGuidanceModal] = useState(false);
  const [editing, setEditing] = useState(null);

  const [loading, setLoading] = useState(true);

  const fetchProspectSearches = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/prospect-search`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProspectSearches(response.data || []);
    } catch (error) {
      console.error('Error fetching prospect searches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCanSearch(prospectSearches?.length > 0);
  }, [prospectSearches]);

  const fetchSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/oagents/${oagentId}/prospect-search-settings`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSearchSettings(response.data);
    } catch (error) {
      console.error('Error fetching prospect search settings:', error);
    }
  };

  useEffect(() => {
    fetchProspectSearches();
    fetchSettings();
  }, [getAccessTokenSilently, oagentId]);

  const handleSettingChange = async (field, value) => {
    try {
      // Optimistically update the state
      setSearchSettings((prevSettings) => ({
        ...prevSettings,
        [field]: value,
      }));

      // Send the update to the server
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/prospect-search-settings`,
        { [field]: value },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      // Revert the optimistic update in case of an error
      fetchSettings();
    }
  };

  const saveGenericEmailGuidance = async (guidance) => {
    await handleSettingChange('generic_email_guidance', guidance);
    setShowEmailGuidanceModal(false);
  };

  const deleteProspectSearch = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      // Optimistically update the state
      setProspectSearches((prospectSearches) =>
        prospectSearches.filter((x) => x.id !== id),
      );

      // Call the deactivate endpoint
      await api.post(
        `/oagents/${oagentId}/prospect-search/${id}/deactivate`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      // Refetch the prospect searches to ensure the data is up-to-date
      fetchProspectSearches();
    } catch (error) {
      console.error('Error clearing prospect profile:', error);
      // Revert the optimistic update in case of an error
      fetchProspectSearches();
    }
  };

  const editingType = editing ? searchTypes[editing.type] : null;

  return (
    <div className='ps-outer'>
      <div className='prospects-content'>
        <div className='ps-options-wrapper'>
          <div className='pso-header'>
            <h6>Target prospects</h6>
            <p>Who are you targetting?</p>
          </div>
          {(prospectSearches?.length > 0 || loading) && (
            <div className='current-searches-view'>
              {loading && (
                <div className='search-spinner'>
                  <Spinner />
                </div>
              )}
              {!loading && prospectSearches?.length > 0 && (
                <>
                  {prospectSearches.map((prospectSearch, index) => {
                    const manifest = searchTypes[prospectSearch.type];
                    if (!manifest) {
                      console.error(
                        `No manifest for prospectSearch type`,
                        prospectSearch.type,
                      );
                      return null;
                    }

                    return (
                      <>
                        <div
                          className='display-cell'
                          key={index}
                          onClick={() => setEditing(prospectSearch)}
                        >
                          {manifest.icon}
                          <div className='center'>
                            <p>{manifest.polite}</p>
                            <p>{manifest.shortDisplay(prospectSearch)}</p>
                          </div>
                          <div
                            className='delete-icon'
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowDeleteConfirmModal(prospectSearch.id);
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </div>
                        {index + 1 < prospectSearches.length && (
                          <div className='then-look-for'>Then look for...</div>
                        )}
                      </>
                    );
                  })}

                  <div className='add-step-wrapper'>
                    <Button
                      variant='outline-primary'
                      onClick={() => setShowAddStepModal(true)}
                    >
                      Setup prospect search <PlusIcon />
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}

          {prospectSearches?.length === 0 && (
            <Button
              className='big-add-prospect-button'
              variant='primary'
              onClick={() => setShowAddStepModal(true)}
            >
              Add a prospect search{' '}
              <div className='yellow-icon'>
                <PlusIcon />
              </div>
            </Button>
          )}

          {prospectSearches?.length > 0 && (
            <div className='settings-set dream-side-modal-form'>
              <h6>Search settings</h6>
              <Form.Group className='range-flex'>
                <Form.Label>Maximum prospects to return per company</Form.Label>
                <Form.Text>
                  Each prospect added to a campaign costs 1 credit.
                </Form.Text>
                <RangeSlider
                  value={searchSettings.max_prospects_per_company || 3}
                  onChange={(e) =>
                    handleSettingChange(
                      'max_prospects_per_company',
                      e.target.value,
                    )
                  }
                  min={1}
                  max={20}
                  tooltipLabel={(currentValue) => `${currentValue}`}
                  variant='info'
                  size='lg'
                />
                <div className='indicator-line'>
                  <span>1</span>
                  <span>20</span>
                </div>
              </Form.Group>

              <Form.Group
                controlId='formCampaignExclusive'
                className='checkbox-group'
              >
                <Form.Check
                  type='checkbox'
                  label="Don't include prospects already in another campaign"
                  checked={searchSettings.campaign_exclusive || false}
                  onChange={(e) =>
                    handleSettingChange('campaign_exclusive', e.target.checked)
                  }
                />
              </Form.Group>

              <Form.Group
                controlId='formGenericEmailBackup'
                className='checkbox-group'
              >
                <Form.Check
                  type='checkbox'
                  label='Add generic emails if a prospect cannot be found through your search sequence (eg. info@...)'
                  checked={searchSettings.generic_email_backup || false}
                  onChange={(e) =>
                    handleSettingChange(
                      'generic_email_backup',
                      e.target.checked,
                    )
                  }
                />
                {searchSettings.generic_email_backup && (
                  <Button
                    variant='link'
                    className='set-guidance-button'
                    onClick={() => setShowEmailGuidanceModal(true)}
                  >
                    Set guidance
                  </Button>
                )}
              </Form.Group>
            </div>
          )}
        </div>
      </div>

      {showAddStepModal && (
        <AddProspectSearchModal
          oagentId={oagentId}
          show
          handleClose={() => {
            fetchProspectSearches();
            setShowAddStepModal(false);
          }}
          refreshProspectSearch={fetchProspectSearches}
        />
      )}
      {showDeleteConfirmModal && (
        <ConfirmModal
          show
          handleClose={() => setShowDeleteConfirmModal(false)}
          handleConfirm={() => {
            deleteProspectSearch(showDeleteConfirmModal);
            setShowDeleteConfirmModal(false);
          }}
          title='Confirm remove search'
          bodyText="Are you sure you want to delete the prospect search? It won't remove already retrieved prospects but cannot be undone."
        />
      )}
      {showEmailGuidanceModal && (
        <GenericEmailGuidanceModal
          show={showEmailGuidanceModal}
          handleClose={() => setShowEmailGuidanceModal(false)}
          guidance={searchSettings.generic_email_guidance || ''}
          onSave={saveGenericEmailGuidance}
        />
      )}
      {editing && editingType && (
        <editingType.Modal
          show
          handleClose={() => {
            fetchProspectSearches();
            setEditing(null);
          }}
          oagentId={oagentId}
          refreshProspectSearch={fetchProspectSearches}
          prospectSearchId={editing.id}
          metadata={editing.metadata}
          editMode
        />
      )}
    </div>
  );
}

export default ProspectsSettings;
