// manifests/company_search.js
import React from 'react';
import linkedinSearchManifest from './linkedin_search';
import hotelSearchManifests from './hotel_searches';
import jobSearchManifest from './jobs';
import recentlyHiredManifest from './recently_hired';
import { ReactComponent as BusinessIcon } from '../../icons/business.svg';
import { ReactComponent as UploadListIcon } from '../../icons/upload-list.svg';
import { ReactComponent as GlobeIcon } from '../../icons/globe.svg';
import { ReactComponent as AgentSearchIcon } from '../../icons/agent-search.svg';
import { ReactComponent as CRMPeopleIcon } from '../../icons/crm-people.svg';
import { ReactComponent as CRMOrgIcon } from '../../icons/crm-org.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';

const companySearchManifest = [
  {
    name: 'Import from CSV',
    icon: <UploadListIcon />,
    search_category: 'CSV Import',
    is_foundational_type: true,
    is_exclusion_type: true,
    type: 'list',
    import: true,
    match_language: 'Companies matched',
    description: 'Upload a list of companies in CSV format.',
    speed_description: (
      <>
        <p>We're sourcing information for the companies you've imported</p>
        <p>
          Data is retrieved for <b>300</b> companies per hour.
        </p>
      </>
    ),
    additional_options: {
      list_of_companies: {
        input_type: 'list_from_csv',
      },
      list_contents: {
        description: 'List Contents',
        dev_name: 'list_contents',
        long_description: (
          <span>
            (Optional) Briefly describe the types of companies on the list (this
            helps your agents know if an entry for e.g. Apple means Apple Music
            or Apple Computer)
          </span>
        ),
        input_type: 'textarea',
      },
    },
    display: {},
  },
  {
    name: 'Sync contacts from CRM',
    icon: <CRMPeopleIcon />,
    type: 'prospect_crm',
    import: true,
    is_exclusion_type: true,
    is_crm_option: true,
    is_foundational_type: true,
    can_retrieve: true,
    description: 'Import contacts from your linked CRMs',
    additional_options: {
      owner_id: {
        input_type: 'crm_owners',
        dev_name: 'crm_owners',
        description: 'Owners',
        long_description:
          "Only companies with these user's as account owners will be imported.",
      },
      fields: {
        input_type: 'crm_fields',
        dev_name: 'crm_fields',
        description: 'Field Filters',
        long_description: 'Filter by fields on the company record',
        crm_model: 'contacts',
      },
      last_engagement: {
        input_type: 'fixed_or_relative_date',
        dev_name: 'last_engagement',
        description: 'Last activity time',
        long_description:
          'Contacts will only be imported if the last action on their profile matches',
        none_value: 'Any activity time',
        include_crm_engagement_types: true,
      },
      max_companies: {
        input_type: 'number',
        dev_name: 'max_companies',
        description: 'Number of companies',
        long_description:
          'Maximum is 200. For larger syncs, contact your Account Manager.',
        short: true,
      },
      max_prospects: {
        input_type: 'number',
        dev_name: 'max_prospects',
        description: 'Number of contacts',
        long_description:
          'Maximum is 200. For larger syncs, contact your Account Manager.',
        short: true,
      },
    },
  },
  {
    name: 'Sync orgs from CRM',
    icon: <CRMOrgIcon />,
    type: 'crm',
    import: true,
    is_exclusion_type: true,
    is_crm_option: true,
    is_foundational_type: true,
    can_retrieve: true,
    description: 'Import companies from your linked CRMs',
    speed_description: (
      <>
        <p>We're sourcing information for the companies you've imported</p>
        <p>
          Data is retrieved for <b>300</b> companies per hour.
        </p>
      </>
    ),
    additional_options: {
      owner_id: {
        input_type: 'crm_owners',
        dev_name: 'crm_owners',
        description: 'Owners',
        long_description:
          "Only companies with these user's as account owners will be imported.",
      },
      stages: {
        input_type: 'crm_stages',
        dev_name: 'crm_stages',
        description: 'Deal stages',
        exclude_toggle_field: 'exclude_stages',
      },
      max_companies: {
        input_type: 'number',
        dev_name: 'max_companies',
        description: 'Number of companies to sync',
        long_description:
          'Maximum is 200. For larger syncs, contact your Account Manager.',
        short: true,
      },
      fields: {
        input_type: 'crm_fields',
        dev_name: 'crm_fields',
        description: 'Field filters',
        crm_model: 'accounts',
        long_description: 'Filter by fields on the company record',
      },
    },
    display: {},
  },
  {
    name: 'Agent Search',
    icon: <AgentSearchIcon />,
    type: 'agent_search',
    is_foundational_type: true,
    hide_from_basic_selection: true,
    description:
      "Your agent will try and build your list using multiple online sources and it's own knowledge.",
    validate: ['list_contents'],
    additional_options: {
      list_contents: {
        description: 'List Contents',
        dev_name: 'list_contents',
        long_description: (
          <span>
            What you want your agent to search for. See <a href='/'>docs</a> for
            more details.
          </span>
        ),
        input_type: 'textarea',
      },
      search_term_guidance: {
        description: 'Search Term Guidance',
        dev_name: 'search_term_guidance',
        long_description: (
          <span>
            (Optional) Further considerations your agent will have when
            determining what search terms to use to look for companies.
          </span>
        ),
        input_type: 'textarea',
      },
      ai_guidance: {
        description: 'Identifying company guidance',
        dev_name: 'identifying_company_guidance',
        long_description: (
          <span>
            (Optional) Further considerations your agent will make during
            company extraction. See <a href='/'>docs</a> for more details.
          </span>
        ),
        input_type: 'textarea',
      },
      prefer_news_search: {
        description: 'Prefer news search',
        dev_name: 'prefer_news_search',
        checkbox_inline_description: (
          <span>
            Should your agent use news search instead of a standard search
            engine search?
          </span>
        ),
        input_type: 'checkbox',
      },
      date_cutoff: {
        description: 'Cut-off date for articles',
        dev_name: 'cutoff_for_articles',
        input_type: 'date',
        long_description: (
          <span>
            (Optional) If using news search, what is the oldest publication date
            for news you want to use?
          </span>
        ),
      },
      include_discovery_context: {
        description: 'Include discovery context',
        dev_name: 'include_discovery_context',
        checkbox_inline_description: (
          <span>
            Should your agent summarise information discovered when finding the
            company?
          </span>
        ),
        input_type: 'checkbox',
      },
    },
    credit_cost: 2,
    speed_description: (
      <p>
        Retrieval speed varies between <b>50</b> to <b>100</b> retrievals per
        hour
      </p>
    ),
    list_language: 'Companies retrieved',
  },

  linkedinSearchManifest,
  jobSearchManifest,
  recentlyHiredManifest,
  {
    name: 'Announcement Search',
    icon: <GlobeIcon />,
    type: 'announcement_search',
    long_description: `Find companies based on news or information about them. Your agent will search using publicly availably information to find companies that match your requirements.
You can go into as much or as little detail as you want.`,
    validate: ['announcement'],
    description:
      'Search for companies based on recent news or information about them.',
    additional_options: {
      announcement: {
        description: 'Find companies that have recently announced...',
        input_type: 'textarea',
        samples: [
          { label: '...profit warnings', value: 'profit warnings' },
          { label: '...office openings', value: 'office openings' },
          { label: '...new CEO hires', value: 'new CEO hires' },
          { label: '...design awards', value: 'design awards' },
        ],
      },
    },
    credits_cost: 2,
    speed_description: (
      <p>
        Agent will retrieve up to <b>300</b> companies per hour.
      </p>
    ),
  },
  {
    name: 'Local Business Search',
    icon: <BusinessIcon />,
    type: 'google_local_search',
    is_foundational_type: true,
    validate: ['location', 'search_term'],
    search_category: 'Bricks & Mortar',
    description:
      'Find companies that don’t have a significant internet presence, eg. brick and mortar businesses.',
    additional_options: {
      search_term: {
        description: 'What kind of company to look for?',
        long_description:
          "For example 'Marketing Agencies', 'Plumbers', 'Dentists'",
        input_type: 'text',
        dev_name: 'search_term',
      },
      location: {
        description: 'What area to search in?',
        long_description: 'Start typing a region or location',
        input_type: 'google_location_auto_complete',
        dev_name: 'location',
      },
    },
    tests: [
      {
        name: 'Test Local Search',
        description: (
          <>
            <p>
              Press run test to see a snapshot of the results of your search.
            </p>
            <p>
              <b>NB:</b> Results are restricted to 15 companies in this test.
            </p>
          </>
        ),
        type: 'google_local_search',
        inputs: [],
        responseFn: ({ companies, steps }) => (
          <>
            <div className='companies-list'>
              <p>{companies.join(', ')}</p>
            </div>
          </>
        ),
      },
    ],
    display: {
      show: 'search_term',
      how: 'text',
    },
    credits_cost: 1,
  },
  {
    name: 'Group of Businesses Search',
    icon: <BusinessIcon />,
    type: 'group_search',
    is_foundational_type: true,
    validate: ['location', 'search_term', 'group_search_term'],
    search_category: 'Bricks & Mortar',
    description:
      'Find groups that owns bricks and mortar companies (e.g. Restaurant Groups, Dental Groups etc).',
    additional_options: {
      group_search_term: {
        description: 'What kind of group are you looking for?',
        long_description:
          "For example 'Restaurant Groups', 'Football Associations', 'Dental Partnerships'",
        input_type: 'text',
        dev_name: 'group_search_term',
      },
      search_term: {
        description: 'What kind of company do they own?',
        long_description:
          "For example 'Restaurants', 'Football Clubs', 'Dentists'",
        input_type: 'text',
        dev_name: 'search_term',
      },
      location: {
        description: 'Where to look for those companies.',
        long_description: 'Start typing a region or location',
        input_type: 'google_location_auto_complete',
        dev_name: 'location',
      },
    },
    credits_cost: 2,
  },
  {
    import: true,
    can_retrieve: true,
    name: 'From your inbox',
    icon: <EmailIcon />,
    is_foundational_type: true,
    type: 'companies_you_know',
    long_description:
      'Go through connected account inboxes and retrieve companies that they have been in contact with historically. This works by using the Sent messages and assessing the warmth of relationships indicated. Emails of a personal nature are ignored.',
    description: 'Retrieve companies you have spoken with previously',
    additional_options: {
      domains_to_ignore: {
        description: 'Emails to ignore',
        long_description:
          'Which domains to ignore email addresses from (your agent will ignore gmail and personal emails etc by default but there may be others)',
        input_type: 'multiselect-no-options',
      },
      oauth_ids: {
        description: 'Connected email account',
        long_description:
          'Which email address to retrieve companies that you know from',
        input_type: 'oauth-selector',
      },
      list_contents: {
        description: 'Overview of companies',
        long_description:
          'What kinds of companies are we likely to find? This helps differentiate between Apple and Apple Music. ',
        input_type: 'textarea',
      },
    },
  },
  ...hotelSearchManifests,
];

export default companySearchManifest;
