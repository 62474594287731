import React, { useState, useEffect } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import DataTable from 'react-data-table-component';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import FakePic from '../../feed/items/FakePic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as NoFunnelsIcon } from '../../../icons/no-funnels.svg';
import FakeBatch from './FakeBatch';

function CompaniesList({
  oagentId,
  forceRunSummaryFetch,
  nextFn,
  nextText,
  knownOrgs,
  setKnownOrgs,
  isInitialCheck,
  hasFunnels,
  loadFromSources,
  isSyncing,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [runSummary, setRunSummary] = useState(null);
  const [error, setError] = useState(null); // New state for error handling
  const [selectedRows, setSelectedRows] = useState([]); // State to hold selected rows

  useEffect(() => {
    fetchAllData();

    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'new_known_org') {
          setKnownOrgs((prevOrgs) => {
            if (!prevOrgs.find((x) => x.id === data.known_org.id)) {
              return [...prevOrgs, data.known_org];
            }
            return prevOrgs;
          });
        } else if (data.sub_type === 'import_run_update') {
          setRunSummary(data.company_summary);
        }
      }
    });

    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [oagentId]);

  useEffect(() => {
    fetchAllData();
  }, [forceRunSummaryFetch]);

  const fetchAllData = async () => {
    setError(null); // Reset error before fetching new data
    try {
      const token = await getAccessTokenSilently();
      const [runSummaryResponse] = await Promise.all([
        api.get(`/oagents/${oagentId}/import-run-summary/companies`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setRunSummary(runSummaryResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };
  const handleDelete = async () => {
    setError(null); // Reset error before deleting
    try {
      const token = await getAccessTokenSilently();
      const idsToDelete = selectedRows.map((row) => row.id);
      setKnownOrgs(knownOrgs.filter((org) => !idsToDelete.includes(org.id)));
      setSelectedRows([]); // Clear the selection

      await api.post(
        `/oagents/${oagentId}/deactivate-companies`,
        { ids: idsToDelete },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      // Optimistically remove the selected rows
    } catch (error) {
      console.error('Error during delete:', error);
      setError('Error during delete.');
    }
  };

  const processingOrWaiting =
    runSummary &&
    runSummary.status_counts
      .filter(
        (item) =>
          item.status === 'processing' ||
          item.status === 'waiting' ||
          item.status === 'retry',
      )
      .reduce((sum, item) => sum + parseInt(item.count, 10), 0);

  const totalOrgs = runSummary?.filtered_orgs_count;

  const columns = [
    {
      name: '',
      cell: (row) => {
        return row.linkedin_url ? (
          <a href={row.linkedin_url} target='_blank' rel='noopener noreferrer'>
            <FakePic type='org' url={row.logo_url} />
          </a>
        ) : (
          <FakePic type='org' url={row.logo_url} />
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '50px',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Matched datapoints',
      selector: (row) => <div className='text-wrap'>{row.reason}</div>,
      sortable: true,
    },
  ];

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const empty = {
    logo_url: null, // Trigger fakepic
    linkedin_url: null,
    name: <div className='fake-placeholder'></div>,
    reason: <div className='fake-placeholder reason'></div>,
  };
  const placeholders = Array(5).fill(empty);

  if (!isInitialCheck && knownOrgs.length === 0 && !hasFunnels) {
    return (
      <div className='ocl-wrapper'>
        <div className='ocl-empty-wrapper'>
          <div className='ocl-empty'>
            <NoFunnelsIcon />
            <h6>You’ve not added any sources</h6>
            <p>
              Choose ‘Add sources’ to select which companies, prospects and
              datapoints you want to add to this campaign.
            </p>
            <p>
              Once you’ve chosen your sources you can setup campaign triggers.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='ocl-wrapper'>
      <div className='embedded-list-header'>
        <div className='left'>
          <h5>
            {!isInitialCheck ? (
              <>
                <b>{knownOrgs.length} companies</b> in campaign
              </>
            ) : (
              'Company list'
            )}
          </h5>
          {selectedRows.length > 0 && (
            <Button
              variant='outline'
              onClick={handleDelete}
              className='table-delete-button'
            >
              <TrashIcon />
              Delete Selected
            </Button>
          )}
        </div>
        {processingOrWaiting === 0 && nextText && knownOrgs.length !== 0 && (
          <div className='right'>
            <Button variant='primary' onClick={nextFn}>
              Next: {nextText} <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        )}
        {processingOrWaiting !== 0 && processingOrWaiting <= totalOrgs && (
          <div className='processing-set'>
            <Spinner />
            {processingOrWaiting}/{totalOrgs} companies to check
          </div>
        )}
        {/* The below can happen when multiple queue items get input for a company. This can happen if there's an error in an earlier run or similar. */}
        {processingOrWaiting !== 0 && processingOrWaiting > totalOrgs && (
          <div className='processing-set'>
            <Spinner />
            {processingOrWaiting} checks in progress
          </div>
        )}
      </div>

      {!isInitialCheck && processingOrWaiting !== 0 && (
        <>
          <div className='nice-progress-bar'>
            <div
              className='filler'
              style={{
                width: `${processingOrWaiting <= totalOrgs ? ((totalOrgs - processingOrWaiting) / totalOrgs) * 100 : 10}%`,
              }}
            ></div>
          </div>
          <div className='progress-description'>
            <p>
              We check each of the companies on your source list to make sure
              they match the datapoints you've listed.
            </p>
          </div>
        </>
      )}

      {error && <Alert variant='danger'>{error}</Alert>}

      {!isInitialCheck && knownOrgs.length === 0 && (
        <div className='ocl-empty-wrapper'>
          <div className='ocl-empty'>
            <NoFunnelsIcon />
            <h6>Add your companies</h6>
            <p>
              Load companies from your selected sources.
              <br />
              <br />
              We’ll match any datapoints or filters to retrieve the companies
              that match your needs.
            </p>
            <Button
              className={`inner-sync ${isSyncing ? 'syncing' : ''}`}
              onClick={loadFromSources}
              disabled={isSyncing}
            >
              {isSyncing && (
                <>
                  <Spinner /> Finding companies
                </>
              )}
              {!isSyncing && <>Load companies from sources</>}
            </Button>
          </div>
        </div>
      )}

      {isInitialCheck && (
        <div className='fake-batch-padding'>
          <FakeBatch count={4} />
        </div>
      )}

      {knownOrgs.length > 0 && (
        <div className='dream-table-wrapper'>
          <DataTable
            columns={columns}
            data={!isInitialCheck ? knownOrgs : placeholders}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            selectAllContextualLabel='Select All/De-select All'
            fixedHeader
          />
        </div>
      )}
    </div>
  );
}

export default CompaniesList;
