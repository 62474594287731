import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

import { ReactComponent as PlusIcon } from '../../icons/big-plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';

const gapOptions = {
  3600000: 'Every Hour',
  86400000: 'Every Day',
  604800000: 'Every Week',
  2592000000: 'Every Month',
  0: 'Never',
};

const ScheduleFunnelButton = ({ funnelId, useCRMLanguage }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [nextRun, setNextRun] = useState(null);
  const [currentGap, setCurrentGap] = useState(null);
  const [selectedGap, setSelectedGap] = useState(null); // New state for selected gap
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [numCompanies, setNumCompanies] = useState(20);

  const handleIncrement = () => {
    setNumCompanies((prev) => (prev < 100 ? prev + 1 : prev));
  };

  const handleDecrement = () => {
    setNumCompanies((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 1 && value <= 100) {
      setNumCompanies(value);
    }
  };

  const fetchScheduling = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      const response = await api.get(`/run-funnel/${funnelId}/scheduling`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNextRun(
        response.data.scheduled
          ? new Date(
              parseInt(response.data.nextRunTimestamp, 10),
            ).toLocaleString()
          : 'Not scheduled',
      );
      setCurrentGap(response.data.gap);
      setSelectedGap(response.data.gap); // Initialize selected gap with current gap
      setNumCompanies(response.data.max_companies || 20);
    } catch (error) {
      console.error('Error fetching scheduling information:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    fetchScheduling();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGap(currentGap); // Reset selected gap to current gap on close
  };

  const handleSave = async () => {
    try {
      const token = await getAccessTokenSilently();
      setSaving(true);
      await api.post(
        `/run-funnel/${funnelId}/scheduling`,
        { gap: selectedGap, max_companies: numCompanies },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // Fetch updated scheduling info
      fetchScheduling();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving scheduling information:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Button variant='outline-primary' onClick={handleOpenModal}>
        {useCRMLanguage ? 'Schedule re-check' : 'Schedule sourcing'}
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {useCRMLanguage ? 'Schedule re-check' : 'Schedule sourcing'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner animation='border' />
          ) : (
            <div className='dream-side-modal-form schedule-sourcing'>
              <Form.Group controlId='gap'>
                <Form.Label>
                  {useCRMLanguage
                    ? 'Re-check CRM for matches'
                    : 'Agent should add more companies'}
                </Form.Label>
                <Form.Control
                  as='select'
                  value={selectedGap}
                  onChange={(e) => setSelectedGap(e.target.value)}
                >
                  {Object.entries(gapOptions).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {!useCRMLanguage && (
                <Form.Group
                  controlId='numCompanies'
                  className='funnel-run-number-companies'
                >
                  <div>
                    <Form.Label>
                      How many companies do you want to find per run?
                    </Form.Label>
                    <div className='number-input-wrapper'>
                      <Button
                        className='decrement-btn'
                        onClick={handleDecrement}
                      >
                        <MinusIcon />
                      </Button>
                      <Form.Control
                        type='number'
                        value={numCompanies}
                        onChange={handleInputChange}
                        min={1}
                        max={100}
                      />
                      <Button
                        className='increment-btn'
                        onClick={handleIncrement}
                      >
                        <PlusIcon />
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              )}
              {nextRun !== 'Not scheduled' && (
                <Form.Group>
                  <p>Will next run at {nextRun}</p>
                </Form.Group>
              )}
              <div className='schedule filter-warning'>
                {useCRMLanguage ? (
                  <>
                    <b>Stop!</b>
                    <p>
                      You do not need to use this setting to fetch newly added
                      contacts or companies, this will happen automatically
                    </p>
                    <p>
                      This setting is only useful if your list relies on data
                      that may change (e.g. if you are importing contacts that
                      haven't been contacted in 90 days, running this daily will
                      get fresh matches daily).
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Your agent will source companies based on your settings.
                    </p>
                  </>
                )}
              </div>
              <Modal.Footer>
                <Button variant='outline-primary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={handleSave}
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save'}
                </Button>
              </Modal.Footer>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduleFunnelButton;
