import React, { useState, useEffect } from 'react';
import { Form, Alert, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Papa from 'papaparse';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

const CsvUploader = ({
  onCsvDataChange,
  formGroupClassName,
  noShowLinkedIn,
  noShowName,
}) => {
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [columnMappings, setColumnMappings] = useState({});
  const [uploadId, setUploadId] = useState(null);
  const [selectedNameColumn, setSelectedNameColumn] = useState('');
  const [selectedLinkedInColumn, setSelectedLinkedInColumn] = useState('');
  const [selectedProspectNameColumn, setSelectedProspectNameColumn] =
    useState('');
  const [selectedProspectEmailColumn, setSelectedProspectEmailColumn] =
    useState('');
  const [selectedProspectLinkedInColumn, setSelectedProspectLinkedInColumn] =
    useState('');
  const [selectedOtherColumns, setSelectedOtherColumns] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProspectList, setIsProspectList] = useState(false);
  const [sizeLimitWarning, setSizeLimitWarning] = useState(null);
  const [size, setSize] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  const handleCsvFileChange = async (event) => {
    const file = event.target.files[0];

    setSizeLimitWarning(false);

    // Check file size
    const MAX_FILE_SIZE_MB = 5;
    const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

    if (!file || file.size > MAX_FILE_SIZE) {
      setErrorMessage(
        `File size exceeds ${MAX_FILE_SIZE_MB}MB. Contact support if you need to upload a very large data-set.`,
      );
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      const token = await getAccessTokenSilently();

      // API call to upload the full CSV file
      const uploadPromise = api.post('/upload-csv', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      let internalSize = 0;

      // Locally read and parse the CSV to extract the first few rows for analysis
      const analysisPromise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
          const csvString = target.result;
          const { data } = Papa.parse(csvString, { header: true });

          internalSize = data.length - 1; // In case state has race condition
          setSize(data.length - 1); // -1 is the header

          if (data.length > 0) {
            // Limit the data for analysis to 10 rows
            const sliceData = data.slice(0, 10);

            api
              .post(
                '/get-csv-analysis',
                { csvData: sliceData },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              )
              .then(resolve)
              .catch(reject);
          } else {
            reject(new Error('CSV data is empty or invalid.'));
          }
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });

      // Wait for both upload and analysis results
      const [uploadResponse, analysisResponse] = await Promise.all([
        uploadPromise,
        analysisPromise,
      ]);

      const { id, columns } = uploadResponse.data;
      const { isProspectList, columnMappings, csvSizeLimit } =
        analysisResponse.data;

      console.log(internalSize, csvSizeLimit);
      if (internalSize > csvSizeLimit) {
        setSizeLimitWarning(csvSizeLimit);
      }

      setIsProspectList(isProspectList);
      setCsvHeaders(
        columns.map((header) => ({
          value: header,
          label: header,
        })),
      );

      setUploadId(id);
      // Pre-select columns based on returned columnMappings
      setSelectedNameColumn({
        value: columnMappings.company_name,
        label: columnMappings.company_name,
      });
      setSelectedLinkedInColumn({
        value: columnMappings.company_linkedin_url,
        label: columnMappings.company_linkedin_url,
      });
      setSelectedProspectNameColumn({
        value: columnMappings.prospect_name,
        label: columnMappings.prospect_name,
      });
      setSelectedProspectEmailColumn({
        value: columnMappings.prospect_email,
        label: columnMappings.prospect_email,
      });
      setSelectedProspectLinkedInColumn({
        value: columnMappings.prospect_linkedin_url,
        label: columnMappings.prospect_linkedin_url,
      });

      const occupiedColumns = Object.values(columnMappings);
      setSelectedOtherColumns(
        csvHeaders.filter((header) => !occupiedColumns.includes(header.value)),
      );

      // Notify parent component with ID and selections
      onCsvDataChange({
        id,
        selectedColumns: {
          name: selectedNameColumn?.value,
          linkedin_url: selectedLinkedInColumn?.value,
          prospect_name: selectedProspectNameColumn?.value,
          prospect_email: selectedProspectEmailColumn?.value,
          prospect_linkedin_url: selectedProspectLinkedInColumn?.value,
          other: selectedOtherColumns.map((col) => col.value),
        },
        type: isProspectList ? 'prospect' : 'company',
      });

      setErrorMessage('');
    } catch (error) {
      setErrorMessage(
        'Error uploading and analyzing CSV. Check CSV is not malformed',
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!csvHeaders.length) return;
    onCsvDataChange({
      id: uploadId,
      selectedColumns: {
        name: selectedNameColumn?.value,
        linkedin_url: selectedLinkedInColumn?.value,
        prospect_name: selectedProspectNameColumn?.value,
        prospect_email: selectedProspectEmailColumn?.value,
        prospect_linkedin_url: selectedProspectLinkedInColumn?.value,
        other: selectedOtherColumns.map((col) => col.value),
      },
      type: isProspectList ? 'prospect' : 'company',
    });
  }, [
    selectedNameColumn,
    selectedLinkedInColumn,
    selectedProspectNameColumn,
    selectedProspectEmailColumn,
    selectedProspectLinkedInColumn,
    selectedOtherColumns,
    isProspectList,
  ]);

  const handleCsvColumnSelect = (selectedOption) => {
    setSelectedNameColumn(selectedOption);
    setSelectedOtherColumns((prev) =>
      prev.filter((x) => x.value !== selectedOption.value),
    );
  };

  const handleLinkedInColumnSelect = (selectedOption) => {
    setSelectedLinkedInColumn(selectedOption);
    setSelectedOtherColumns((prev) =>
      prev.filter((x) => x.value !== selectedOption.value),
    );
  };

  const handleProspectNameColumnSelect = (selectedOption) => {
    setSelectedProspectNameColumn(selectedOption);
    setSelectedOtherColumns((prev) =>
      prev.filter((x) => x.value !== selectedOption.value),
    );
  };

  const handleProspectEmailColumnSelect = (selectedOption) => {
    setSelectedProspectEmailColumn(selectedOption);
    setSelectedOtherColumns((prev) =>
      prev.filter((x) => x.value !== selectedOption.value),
    );
  };

  const handleProspectLinkedInColumnSelect = (selectedOption) => {
    setSelectedProspectLinkedInColumn(selectedOption);
    setSelectedOtherColumns((prev) =>
      prev.filter((x) => x.value !== selectedOption.value),
    );
  };

  const handleOtherOptionSelect = (selectedOptions) => {
    setSelectedOtherColumns(selectedOptions);
  };

  const toggleProspectList = () => {
    setIsProspectList(!isProspectList);
  };

  return (
    <Form.Group
      className={`inner-opt-group csv-uploader ${formGroupClassName}`}
      controlId='csvUploader'
    >
      <div>
        <Form.Label>Upload File</Form.Label>
        <Form.Text>Ensure this is in CSV format.</Form.Text>
        <Form.Control
          type='file'
          accept='.csv'
          onChange={handleCsvFileChange}
          size='lg'
          disabled={isLoading}
        />
      </div>
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      {sizeLimitWarning && (
        <Alert variant='warning'>
          {`Your account can upload ${sizeLimitWarning} records at a time, this CSV contains ${size} records.`}
          <br />
          {`Only the first ${sizeLimitWarning} will be imported. Contact your Account Manager to increase your limits.`}
        </Alert>
      )}
      {isLoading ? (
        <div className='spinner-wrapper dream-side-modal-form-input-loading'>
          <Spinner animation='border' />
          <span className='spinner-loader'>Uploading & Analysing CSV...</span>
        </div>
      ) : (
        csvHeaders.length > 0 && (
          <>
            <Form.Group className='radio-set'>
              <Form.Check
                type='radio'
                label='Company List'
                name='listType'
                id='companyList'
                value='company'
                checked={!isProspectList}
                onChange={toggleProspectList}
              />
              <Form.Check
                type='radio'
                label='Prospect List'
                name='listType'
                id='prospectList'
                value='prospect'
                checked={isProspectList}
                onChange={toggleProspectList}
              />
            </Form.Group>
            {isProspectList && (
              <>
                <Form.Group
                  className={`inner-opt-group ${formGroupClassName}`}
                  controlId='selectCsvProspectNameColumn'
                >
                  <Form.Label>Prospect Name Column</Form.Label>
                  <Form.Text>
                    Select the column that contains the prospect name
                  </Form.Text>
                  <Select
                    options={csvHeaders}
                    onChange={handleProspectNameColumnSelect}
                    className='additional-options-select'
                    classNamePrefix='additional-options-select'
                    placeholder='Select a column'
                    size='lg'
                    value={selectedProspectNameColumn}
                  />
                </Form.Group>

                <Form.Group
                  className={`inner-opt-group ${formGroupClassName}`}
                  controlId='selectCsvProspectEmailColumn'
                >
                  <Form.Label>Prospect Email Column</Form.Label>
                  <Form.Text>
                    Select the column that contains the prospect email
                  </Form.Text>
                  <Select
                    options={csvHeaders}
                    onChange={handleProspectEmailColumnSelect}
                    className='additional-options-select'
                    classNamePrefix='additional-options-select'
                    placeholder='Select a column'
                    size='lg'
                    value={selectedProspectEmailColumn}
                  />
                </Form.Group>
                <Form.Group
                  className={`inner-opt-group ${formGroupClassName}`}
                  controlId='selectCsvProspectLinkedInColumn'
                >
                  <Form.Label>Prospect LinkedIn URL Column</Form.Label>
                  <Form.Text>
                    Select the column that contains the prospect LinkedIn URL
                  </Form.Text>
                  <Select
                    options={csvHeaders}
                    onChange={handleProspectLinkedInColumnSelect}
                    className='additional-options-select'
                    classNamePrefix='additional-options-select'
                    placeholder='Select a column'
                    size='lg'
                    value={selectedProspectLinkedInColumn}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group
              className={`inner-opt-group ${formGroupClassName}`}
              controlId='selectCsvNameColumn'
            >
              <Form.Label>Company Name Column</Form.Label>
              <Form.Text>
                Select the column that contains the company name
              </Form.Text>
              <Select
                options={csvHeaders}
                onChange={handleCsvColumnSelect}
                className='additional-options-select'
                classNamePrefix='additional-options-select'
                placeholder='Select a column'
                size='lg'
                value={selectedNameColumn}
              />
            </Form.Group>
            <Form.Group
              className={`inner-opt-group ${formGroupClassName}`}
              controlId='selectCsvLinkedInColumn'
            >
              <Form.Label>Company LinkedIn URL Column</Form.Label>
              <Form.Text>
                Select the column that contains the company LinkedIn URL
              </Form.Text>
              <Select
                options={csvHeaders}
                onChange={handleLinkedInColumnSelect}
                className='additional-options-select'
                classNamePrefix='additional-options-select'
                placeholder='Select a column'
                size='lg'
                value={selectedLinkedInColumn}
              />
            </Form.Group>
            <div>
              <Form.Group
                className={`inner-opt-group ${formGroupClassName}`}
                controlId='selectedAlternates'
              >
                <Form.Label>Columns to Include (Optional)</Form.Label>
                <Form.Text>
                  Select which other columns you'd like to add to your list
                </Form.Text>
                <CreatableSelect
                  isMulti
                  className='additional-options-select'
                  classNamePrefix='additional-options-select'
                  value={selectedOtherColumns}
                  options={csvHeaders}
                  onChange={handleOtherOptionSelect}
                  size='lg'
                />
              </Form.Group>
            </div>
          </>
        )
      )}
    </Form.Group>
  );
};

export default CsvUploader;
