import React from 'react';
import './fake-batch.css';

const getRand = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

function FakeBatch({ count }) {
  const output = Array(count).fill('foo');

  return (
    <div className='fake-set'>
      {output.map((i) => (
        <div className='fake-batch' key={i}>
          <div className='fake-pic'></div>
          {Array(getRand(1, 3))
            .fill('foo')
            .map((x) => (
              <div
                className='bar'
                key={`${x}-${i}`}
                style={{ width: `${getRand(6, 22)}%` }}
              />
            ))}
        </div>
      ))}
    </div>
  );
}

export default FakeBatch;
