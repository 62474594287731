import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './funnel-run-modal.css';

import { ReactComponent as PlusIcon } from '../../icons/big-plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';

const FunnelRunModal = ({
  show,
  handleClose,
  handleRun,
  hasFilter,
  useCRMLanguage,
}) => {
  const [numCompanies, setNumCompanies] = useState(useCRMLanguage ? null : 20);

  const handleIncrement = () => {
    setNumCompanies((prev) => (prev < 100 ? prev + 1 : prev));
  };

  const handleDecrement = () => {
    setNumCompanies((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 1 && value <= 100) {
      setNumCompanies(value);
    }
  };

  const handleSubmit = () => {
    handleRun(numCompanies);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {useCRMLanguage ? 'Re-check CRM' : 'Start sourcing'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!useCRMLanguage && (
          <Form.Group
            controlId='numCompanies'
            className='funnel-run-number-companies'
          >
            <div>
              <Form.Label>How many companies do you want to find?</Form.Label>
              <div className='number-input-wrapper'>
                <Button className='decrement-btn' onClick={handleDecrement}>
                  <MinusIcon />
                </Button>
                <Form.Control
                  type='number'
                  value={numCompanies}
                  onChange={handleInputChange}
                  min={1}
                  max={100}
                />
                <Button className='increment-btn' onClick={handleIncrement}>
                  <PlusIcon />
                </Button>
              </div>
            </div>
          </Form.Group>
        )}
        {hasFilter && (
          <div className='filter-warning'>
            <p>
              Your search has one or more filters applied. When we source
              companies we’ll check they match your filters, which means that
              the number of companies on found may be less than what you’ve
              entered above.
            </p>
          </div>
        )}
        {useCRMLanguage && (
          <div className='import-information'>
            <b>Stop!</b>
            <p>
              You do not need to use this setting to fetch newly added contacts
              or companies, this will happen automatically
            </p>
            <p>
              This setting is only useful if your list relies on data that may
              change (e.g. if you are importing contacts that haven't been
              contacted in 90 days, running this will get fresh matches).
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FunnelRunModal;
