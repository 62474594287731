import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';
import api from '../../../api';

function CRMOwners({ onChange, selectedOwners }) {
  const { getAccessTokenSilently } = useAuth0();
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const [accountsResponse, contactsResponse] = await Promise.all([
          api.get('/crm/accounts/owners', { headers }),
          api.get('/crm/contacts/owners', { headers }),
        ]);

        const combinedOwners = [
          ...accountsResponse.data.owners,
          ...contactsResponse.data.owners,
        ];

        const uniqueOwners = Array.from(
          new Map(combinedOwners.map((owner) => [owner.id, owner])).values(),
        );

        setOwners(uniqueOwners);
      } catch (error) {
        console.error('Error fetching owners:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOwners();
  }, [getAccessTokenSilently]);

  const options = owners.map((owner) => ({
    value: owner.id,
    label: `${owner.email} (ID: ${owner.id})`, // Updated label to include ID
  }));

  const handleChange = (selectedOptions) => {
    if (onChange) {
      onChange(selectedOptions.map((option) => option.value));
    }
  };

  if (loading) {
    return (
      <div className='spinner-wrapper dream-side-modal-form-input-loading'>
        <Spinner animation='border' />
        <span className='spinner-loader'>Loading owners...</span>
      </div>
    );
  }

  return (
    <Select
      isMulti
      value={options.filter((option) => selectedOwners.includes(option.value))}
      options={options}
      onChange={handleChange}
      placeholder='Select Owners'
      className='additional-options-select'
      classNamePrefix='additional-options-select'
    />
  );
}

export default CRMOwners;
