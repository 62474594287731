const list = [
  `Advertising Services`,
  `Oil and Gas`,
  `Printing Services`,
  `Design`,
  `Media and Telecommunications`,
  `Wineries`,
  `Electrical & Electronic Manufacturing`,
  `Textiles`,
  `Business Consulting and Services`,
  `Public Relations and Communications Services`,
  `Commercial and Service Industry Machinery Manufacturing`,
  `Maritime`,
  `Museums, Historical Sites, and Zoos`,
  `Real Estate and Equipment Rental Services`,
  `Civic & Social Organization`,
  `Nanotechnology Research`,
  `Fishery`,
  `IT System Installation and Disposal`,
  `Graphic Design`,
  `Civic and Social Organizations`,
  `Computer Hardware`,
  `Fisheries`,
  `Mining & Metals`,
  `Bars, Taverns, and Nightclubs`,
  `Retail Books and Printed News`,
  `Geothermal Electric Power Generation`,
  `Mobile Games`,
  `Import & Export`,
  `Housing and Community Development`,
  `Climate Data and Analytics`,
  `Waste Treatment and Disposal`,
  `Ranching and Fisheries`,
  `Shuttles and Special Needs Transportation Services`,
  `Historical Sites`,
  `Meat Products Manufacturing`,
  `Household Services`,
  `Events Services`,
  `Public Policy Offices`,
  `Community Services`,
  `IT System Training and Support`,
  `Biotechnology`,
  `Administrative and Support Services`,
  `Nuclear Electric Power Generation`,
  `Caterers`,
  `Emergency and Relief Services`,
  `Investment Management`,
  `Consumer Services`,
  `Dairy`,
  `Biotechnology Research`,
  `Engineering Services`,
  `Data Infrastructure and Analytics`,
  `Funds and Trusts`,
  `Mobile Computing Software Products`,
  `Retail`,
  `Media Production`,
  `Staffing and Recruiting`,
  `Transportation/Trucking/Railroad`,
  `Automation Machinery Manufacturing`,
  `Human Resources`,
  `Wireless Services`,
  `IT System Operations and Maintenance`,
  `Movies and Sound Recording`,
  `Public Assistance Programs`,
  `Racetracks`,
  `Housing Programs`,
  `Circuses and Magic Shows`,
  `Business Supplies & Equipment`,
  `Airlines and Aviation`,
  `Personal Care Product Manufacturing`,
  `Industrial Automation`,
  `Consumer Electronics`,
  `Veterinary`,
  `Medical and Diagnostic Laboratories`,
  `Environmental Quality Programs`,
  `Holding Companies`,
  `Farming, Ranching, Forestry`,
  `Soap and Cleaning Product Manufacturing`,
  `Wood Product Manufacturing`,
  `Boilers, Tanks, and Shipping Container Manufacturing`,
  `Book Publishing`,
  `Publishing`,
  `Mobile Food Services`,
  `Plastics and Rubber Product Manufacturing`,
  `Space Research and Technology`,
  `Sound Recording`,
  `Defense & Space`,
  `Paint, Coating, and Adhesive Manufacturing`,
  `Beverage Manufacturing`,
  `Public Safety`,
  `Repair and Maintenance`,
  `Wholesale Alcoholic Beverages`,
  `Apparel Manufacturing`,
  `Mobile Gaming Apps`,
  `Metal Valve, Ball, and Roller Manufacturing`,
  `Transportation Programs`,
  `Wholesale Footwear`,
  `Dairy Product Manufacturing`,
  `Retail Health and Personal Care Products`,
  `Public Relations & Communications`,
  `Physicians`,
  `Real Estate Agents and Brokers`,
  `Insurance and Employee Benefit Funds`,
  `Smart Meter Manufacturing`,
  `Subdivision of Land`,
  `Food and Beverage Services`,
  `Civil Engineering`,
  `Staffing & Recruiting`,
  `Security and Investigations`,
  `Leather Product Manufacturing`,
  `Commercial Real Estate`,
  `Wireless`,
  `Nonmetallic Mineral Mining`,
  `Utilities Administration`,
  `Computer Games`,
  `Renewable Energy Semiconductor Manufacturing`,
  `Semiconductor Manufacturing`,
  `Hospital & Health Care`,
  `Water, Waste, Steam, and Air Conditioning Services`,
  `Performing Arts and Spectator Sports`,
  `Courts of Law`,
  `Magnetic and Optical Media Manufacturing`,
  `Outsourcing/Offshoring`,
  `Non-profit Organization Management`,
  `Writing and Editing`,
  `Public Health`,
  `Railroad Equipment Manufacturing`,
  `Supermarkets`,
  `Paper & Forest Products`,
  `Highway, Street, and Bridge Construction`,
  `Specialty Trade Contractors`,
  `Sports and Recreation Instruction`,
  `Dentists`,
  `Electric Power Transmission, Control, and Distribution`,
  `Laundry and Drycleaning Services`,
  `Pipeline Transportation`,
  `Accessible Hardware Manufacturing`,
  `Legal Services`,
  `Hospitals and Health Care`,
  `Retail Gasoline`,
  `Law Enforcement`,
  `Newspaper Publishing`,
  `Embedded Software Products`,
  `Janitorial Services`,
  `Internet News`,
  `Data Security Software Products`,
  `Executive Office`,
  `Metal Ore Mining`,
  `Accessible Architecture and Design`,
  `Rubber Products Manufacturing`,
  `Reupholstery and Furniture Repair`,
  `Fossil Fuel Electric Power Generation`,
  `Truck Transportation`,
  `Research Services`,
  `Glass Product Manufacturing`,
  `Food and Beverage Retail`,
  `Security Guards and Patrol Services`,
  `Animation`,
  `Security & Investigations`,
  `Personal and Laundry Services`,
  `Credit Intermediation`,
  `Amusement Parks and Arcades`,
  `Wholesale Paper Products`,
  `Measuring and Control Instrument Manufacturing`,
  `Chiropractors`,
  `Medical Device`,
  `Leasing Residential Real Estate`,
  `Wholesale Computer Equipment`,
  `Wholesale Food and Beverage`,
  `Retail Art Supplies`,
  `Fundraising`,
  `Sporting Goods Manufacturing`,
  `Business Intelligence Platforms`,
  `Loan Brokers`,
  `Engines and Power Transmission Equipment Manufacturing`,
  `Armed Forces`,
  `Theater Companies`,
  `Investment Advice`,
  `Satellite Telecommunications`,
  `null`,
  `Financial Services`,
  `Retail Luxury Goods and Jewelry`,
  `Non-profit Organizations`,
  `Renewable Energy Power Generation`,
  `Food and Beverage Manufacturing`,
  `Machinery`,
  `Photography`,
  `Education Management`,
  `Executive Offices`,
  `Arts & Crafts`,
  `Vocational Rehabilitation Services`,
  `Recreational Facilities & Services`,
  `Services for Renewable Energy`,
  `Wholesale Luxury Goods and Jewelry`,
  `Retail Pharmacies`,
  `Metalworking Machinery Manufacturing`,
  `Retail Florists`,
  `Secretarial Schools`,
  `Household and Institutional Furniture Manufacturing`,
  `Optometrists`,
  `Food Production`,
  `Professional Training & Coaching`,
  `Periodical Publishing`,
  `Insurance Agencies and Brokerages`,
  `Retail Furniture and Home Furnishings`,
  `Broadcast Media`,
  `Motion Pictures & Film`,
  `Wholesale Photography Equipment and Supplies`,
  `School and Employee Bus Services`,
  `Women's Handbag Manufacturing`,
  `Alternative Medicine`,
  `Banking`,
  `Luxury Goods & Jewelry`,
  `Computer Software`,
  `Oil and Coal Product Manufacturing`,
  `Hotels and Motels`,
  `Golf Courses and Country Clubs`,
  `Economic Programs`,
  `Accounting`,
  `Freight and Package Transportation`,
  `Online Audio and Video Media`,
  `Furniture`,
  `E-Learning Providers`,
  `Telephone Call Centers`,
  `HVAC and Refrigeration Equipment Manufacturing`,
  `Building Construction`,
  `Professional Organizations`,
  `Industry Associations`,
  `Primary Metal Manufacturing`,
  `Regenerative Design`,
  `Taxi and Limousine Services`,
  `Utilities`,
  `Wholesale`,
  `Translation and Localization`,
  `Mining`,
  `Internet`,
  `Wholesale Motor Vehicles and Parts`,
  `IT System Custom Software Development`,
  `Technical and Vocational Training`,
  `Pension Funds`,
  `Insurance`,
  `Technology, Information and Media`,
  `Veterinary Services`,
  `Information Technology & Services`,
  `Retail Art Dealers`,
  `IT System Design Services`,
  `Lime and Gypsum Products Manufacturing`,
  `Venture Capital and Private Equity Principals`,
  `Computers and Electronics Manufacturing`,
  `Oil & Energy`,
  `Outpatient Care Centers`,
  `Renewables & Environment`,
  `Fabricated Metal Products`,
  `Online and Mail Order Retail`,
  `Wholesale Recyclable Materials`,
  `Water Supply and Irrigation Systems`,
  `Building Structure and Exterior Contractors`,
  `Temporary Help Services`,
  `Coal Mining`,
  `Fuel Cell Manufacturing`,
  `Software Development`,
  `Legislative Offices`,
  `Vehicle Repair and Maintenance`,
  `Dance Companies`,
  `Blogs`,
  `Radio and Television Broadcasting`,
  `Wholesale Chemical and Allied Products`,
  `Community Development and Urban Planning`,
  `Architectural and Structural Metal Manufacturing`,
  `Office Furniture and Fixtures Manufacturing`,
  `Sightseeing Transportation`,
  `Glass, Ceramics & Concrete`,
  `Securities and Commodity Exchanges`,
  `Facilities Services`,
  `Higher Education`,
  `Apparel & Fashion`,
  `Retail Motor Vehicles`,
  `Ground Passenger Transportation`,
  `Museums & Institutions`,
  `Construction`,
  `Performing Arts`,
  `Education Administration Programs`,
  `Environmental Services`,
  `Musicians`,
  `Wholesale Appliances, Electrical, and Electronics`,
  `Government Relations Services`,
  `Retail Building Materials and Garden Equipment`,
  `Desktop Computing Software Products`,
  `Recreational Facilities`,
  `Personal Care Services`,
  `Management Consulting`,
  `Online Media`,
  `Operations Consulting`,
  `Agriculture, Construction, Mining Machinery Manufacturing`,
  `Primary/Secondary Education`,
  `Building Equipment Contractors`,
  `Conservation Programs`,
  `IT System Data Services`,
  `Architecture & Planning`,
  `Wine & Spirits`,
  `Claims Adjusting, Actuarial Services`,
  `Robot Manufacturing`,
  `Medical Equipment Manufacturing`,
  `Wholesale Building Materials`,
  `Health and Human Services`,
  `Paper and Forest Product Manufacturing`,
  `Alternative Dispute Resolution`,
  `Public Policy`,
  `Capital Markets`,
  `Waste Collection`,
  `Office Administration`,
  `Wind Electric Power Generation`,
  `Communications Equipment Manufacturing`,
  `Correctional Institutions`,
  `Retail Groceries`,
  `E-learning`,
  `Warehousing and Storage`,
  `Transportation, Logistics, Supply Chain and Storage`,
  `Marketing & Advertising`,
  `Equipment Rental Services`,
  `Aviation and Aerospace Component Manufacturing`,
  `Physical, Occupational and Speech Therapists`,
  `Package/Freight Delivery`,
  `Professional Training and Coaching`,
  `Law Practice`,
  `Hospitality`,
  `Semiconductors`,
  `Landscaping Services`,
  `Footwear and Leather Goods Repair`,
  `Wholesale Machinery`,
  `Electronic and Precision Equipment Maintenance`,
  `Surveying and Mapping Services`,
  `Cosmetology and Barber Schools`,
  `Mattress and Blinds Manufacturing`,
  `Clay and Refractory Products Manufacturing`,
  `Cable and Satellite Programming`,
  `Design Services`,
  `Libraries`,
  `Motor Vehicle Manufacturing`,
  `Building Materials`,
  `Cosmetics`,
  `Pharmaceutical Manufacturing`,
  `Pet Services`,
  `Gambling Facilities and Casinos`,
  `Shipbuilding`,
  `Services for the Elderly and Disabled`,
  `Skiing Facilities`,
  `IT System Testing and Evaluation`,
  `Hospitals`,
  `Government Administration`,
  `Wholesale Furniture and Home Furnishings`,
  `Program Development`,
  `Chemical Manufacturing`,
  `Packaging and Containers Manufacturing`,
  `Home Health Care Services`,
  `Flight Training`,
  `Cutlery and Handtool Manufacturing`,
  `Transportation Equipment Manufacturing`,
  `Health, Wellness & Fitness`,
  `Farming`,
  `Ranching`,
  `Aviation & Aerospace`,
  `Computer Networking Products`,
  `Automotive`,
  `Nonresidential Building Construction`,
  `Electric Lighting Equipment Manufacturing`,
  `Forestry and Logging`,
  `Fine Arts Schools`,
  `Trusts and Estates`,
  `Legislative Office`,
  `Human Resources Services`,
  `Architecture and Planning`,
  `Fine Art`,
  `Blockchain Services`,
  `Wholesale Drugs and Sundries`,
  `Sugar and Confectionery Product Manufacturing`,
  `Nursing Homes and Residential Care Facilities`,
  `Air, Water, and Waste Program Management`,
  `Climate Technology Product Manufacturing`,
  `Oil Extraction`,
  `Plastics Manufacturing`,
  `Residential Building Construction`,
  `Individual & Family Services`,
  `Commercial and Industrial Machinery Maintenance`,
  `Digital Accessibility Services`,
  `Audio and Video Equipment Manufacturing`,
  `Artists and Writers`,
  `Broadcast Media Production and Distribution`,
  `Political Organizations`,
  `Administration of Justice`,
  `Warehousing`,
  `Wholesale Petroleum and Petroleum Products`,
  `Urban Transit Services`,
  `Gambling & Casinos`,
  `Travel Arrangements`,
  `Defense and Space Manufacturing`,
  `Business Content`,
  `Education`,
  `Philanthropic Fundraising Services`,
  `Market Research`,
  `Steam and Air-Conditioning Supply`,
  `Distilleries`,
  `Military and International Affairs`,
  `Military`,
  `Restaurants`,
  `Entertainment Providers`,
  `Mental Health Care`,
  `Industrial Machinery Manufacturing`,
  `Pharmaceuticals`,
  `International Trade & Development`,
  `Nanotechnology`,
  `Plastics`,
  `Internet Marketplace Platforms`,
  `Abrasives and Nonmetallic Minerals Manufacturing`,
  `Household Appliance Manufacturing`,
  `Alternative Fuel Vehicle Manufacturing`,
  `Glass, Ceramics and Concrete Manufacturing`,
  `Other`,
  `Fashion Accessories Manufacturing`,
  `Retail Office Supplies and Gifts`,
  `Railroad Manufacture`,
  `Zoos and Botanical Gardens`,
  `Spectator Sports`,
  `Primary and Secondary Education`,
  `Furniture and Home Furnishings Manufacturing`,
  `Appliances, Electrical, and Electronics Manufacturing`,
  `Baked Goods Manufacturing`,
  `Bed-and-Breakfasts, Hostels, Homestays`,
  `Tobacco`,
  `Savings Institutions`,
  `Interurban and Rural Bus Services`,
  `Medical Practices`,
  `Social Networking Platforms`,
  `Collection Agencies`,
  `Natural Gas Extraction`,
  `IT Services and IT Consulting`,
  `Wellness and Fitness Services`,
  `Entertainment`,
  `Sporting Goods`,
  `Movies, Videos, and Sound`,
  `Philanthropy`,
  `Biomass Electric Power Generation`,
  `Family Planning Centers`,
  `Telecommunications`,
  `Technology, Information and Internet`,
  `Venture Capital & Private Equity`,
  `Electrical Equipment Manufacturing`,
  `Government Relations`,
  `Airlines/Aviation`,
  `Chemicals`,
  `Agricultural Chemical Manufacturing`,
  `Oil, Gas, and Mining`,
  `Retail Office Equipment`,
  `Marketing Services`,
  `Mechanical Or Industrial Engineering`,
  `Logistics & Supply Chain`,
  `Printing`,
  `Insurance Carriers`,
  `Spring and Wire Product Manufacturing`,
  `International Trade and Development`,
  `Religious Institutions`,
  `Animal Feed Manufacturing`,
  `Professional Services`,
  `International Affairs`,
  `Animation and Post-production`,
  `Commercial and Industrial Equipment Rental`,
  `Electric Power Generation`,
  `Translation & Localization`,
  `Retail Musical Instruments`,
  `Motor Vehicle Parts Manufacturing`,
  `Sheet Music Publishing`,
  `Utility System Construction`,
  `Consumer Goods`,
  `Retail Apparel and Fashion`,
  `Food & Beverages`,
  `Computer and Network Security`,
  `Security Systems Services`,
  `Building Finishing Contractors`,
  `Manufacturing`,
  `Sports Teams and Clubs`,
  `Information Services`,
  `Computer Hardware Manufacturing`,
  `Language Schools`,
  `Child Day Care Services`,
  `Ambulance Services`,
  `Metal Treatments`,
  `Turned Products and Fastener Manufacturing`,
  `Maritime Transportation`,
  `Textile Manufacturing`,
  `Fire Protection`,
  `Solar Electric Power Generation`,
  `Wholesale Import and Export`,
  `Newspapers`,
  `Renewable Energy Equipment Manufacturing`,
  `Writing & Editing`,
  `Construction Hardware Manufacturing`,
  `Wholesale Metals and Minerals`,
  `Artificial Rubber and Synthetic Fiber Manufacturing`,
  `Interior Design`,
  `Think Tanks`,
  `Footwear Manufacturing`,
  `Natural Gas Distribution`,
  `Computer & Network Security`,
  `Wholesale Raw Farm Products`,
  `Robotics Engineering`,
  `Wholesale Apparel and Sewing Supplies`,
  `Consumer Goods Rental`,
  `Real Estate`,
  `Outsourcing and Offshoring Consulting`,
  `Internet Publishing`,
  `Machinery Manufacturing`,
  `Seafood Product Manufacturing`,
  `Packaging & Containers`,
  `Sports`,
  `Political Organization`,
  `Horticulture`,
  `Hydroelectric Power Generation`,
  `Medical Practice`,
  `Tobacco Manufacturing`,
  `Leasing Non-residential Real Estate`,
  `Music`,
  `Telecommunications Carriers`,
  `Retail Appliances, Electrical, and Electronic Equipment`,
  `Leisure, Travel & Tourism`,
  `Computer Networking`,
  `Fruit and Vegetable Preserves Manufacturing`,
  `Accommodation and Food Services`,
  `Wholesale Hardware, Plumbing, Heating Equipment`,
  `Executive Search Services`,
  `Judiciary`,
  `Postal Services`,
  `Individual and Family Services`,
  `Book and Periodical Publishing`,
  `Research`,
  `Strategic Management Services`,
  `Investment Banking`,
  `Breweries`,
  `Museums`,
  `Rail Transportation`,
  `Retail Recyclable Materials & Used Merchandise`,
  `Chemical Raw Materials Manufacturing`,
];

const val = list.map((x) => ({ label: x, value: x }));
export default val;
