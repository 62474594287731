import React, { useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';

import StageAdditionalOptions from '../StageAdditionalOptions';
import functionManifests from '../../utils/function-manifests';
import DreamModalInner from '../misc/DreamModalInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as ListBuilderIcon } from '../../icons/list-builder.svg';
import { ReactComponent as MagicWandIcon } from '../../icons/magic-wand.svg';
import { ReactComponent as ExclusionIcon } from '../../icons/exclusion.svg';
import { ReactComponent as AlertIcon } from '../../icons/stop.svg';
import { ReactComponent as PlugIcon } from '../../icons/plug.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as UpCaretIcon } from '../../icons/up-caret.svg';
import { ReactComponent as DownCaretIcon } from '../../icons/down-caret.svg';

import SearchVolumeEstimate from '../misc/SearchVolumeEstimate';
import { Link, useNavigate } from 'react-router-dom';
import DreamInfoExpander from '../misc/DreamInfoExpander';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import FunnelCRMSettingsModal from './FunnelCRMSettingsModal';

function SearchConfigModal({
  searchStage,
  handleTypeChange,
  handleMetadataChange,
  isImport,
  isExclusion,
  handleBack,
  handleSubmit,
  errors,
  name,
  useCaseOther,
  useCaseType,
  crmFilterSettings,
  setCRMFilterSettings,
}) {
  const { getAccessTokenSilently } = useAuth0();

  const [advancedSelected, setAdvancedSelected] = useState(false);
  const [retrieveCRMError, setRetrieveCRMError] = useState('');
  const [hasAnyCRMConnection, setHasAnyCRMConnection] = useState(false);
  const [isCRMStateLoading, setIsCRMStateLoading] = useState(false);
  const [isCRMSyncing, setIsCRMSyncing] = useState(false);
  const [isCRMInFailState, setIsCRMInFailState] = useState(false);
  const [isCRMFine, setIsCRMFine] = useState(false);
  const [isCRMUnstarted, setIsCRMUnstarted] = useState(false);
  const [showCRMSettingsModal, setShowCRMSettingsModal] = useState(false);
  const [haveStartedExpanding, setHaveStartedExpanding] = useState(false);

  const [view, setView] = useState('type_select');

  const [expanded, setExpanded] = useState([]);

  const navigate = useNavigate();

  const selectAdvanced = () => {
    setAdvancedSelected(true);
    handleTypeChange(null);
  };

  const handleStepTypeSelect = (type) => {
    setAdvancedSelected(false);

    const typeMatch = functionManifests.company_search.find(
      (x) => x.type === type,
    );

    // We want to update the default settings based on the kind of search we've selected
    if (typeMatch && typeMatch.is_crm_option) {
      setCRMFilterSettings((prev) => {
        return {
          ...prev,
          ignore_in_crm: false,
        };
      });
    } else {
      setCRMFilterSettings((prev) => {
        return {
          ...prev,
          ignore_in_crm: true,
        };
      });
    }

    handleTypeChange(type);
  };

  const checkShouldShowForType = (currentStage, option) => {
    if (currentStage !== 'company_search') {
      return true;
    }

    return isExclusion
      ? option.is_exclusion_type
      : isImport
        ? option.import
        : !option.import;
  };

  const retrieveCRMState = async () => {
    try {
      setIsCRMStateLoading(true);
      const token = await getAccessTokenSilently();
      const response = await api.get('/knit-link/integration-status', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const crms = response.data;
      if (crms && crms.length) {
        setHasAnyCRMConnection(true);
        setIsCRMSyncing(crms.some((x) => x.status === 'Running'));
        setIsCRMFine(crms.filter((x) => x.status === 'Complete').length >= 1);
        setIsCRMUnstarted(crms.some((x) => x.status === 'Unstarted'));
        setIsCRMInFailState(
          crms.some((x) => x.status === 'Incomplete' || x.status === 'Failed'),
        );
      }
    } catch (e) {
      console.error(`Error loading CRM sync status`);
      setRetrieveCRMError(`Unknown error syncing CRM status`);
    } finally {
      setIsCRMStateLoading(false);
    }
  };

  useEffect(() => {
    retrieveCRMState();
  }, [isImport]);

  const crmDualStates =
    isCRMFine && (isCRMSyncing || isCRMUnstarted || isCRMInFailState);

  return (
    <>
      <DreamModalInner className='funnel-create-modal funnel'>
        <DreamModalInner.Header>
          {isExclusion && (
            <>
              <div className='grey-icon'>
                <ExclusionIcon />
              </div>
              {'Create new Do Not Contact list'}
            </>
          )}
          {!isExclusion && (
            <div className='header-with-settings'>
              <div className='left'>
                <ListBuilderIcon />{' '}
                <>{isImport ? 'Import List' : 'Create new list'}</>
              </div>
              {isCRMFine && (
                <div
                  className='settings-area'
                  onClick={() => setShowCRMSettingsModal(true)}
                >
                  <SettingsIcon />
                  CRM Settings
                </div>
              )}
              {isCRMStateLoading && (
                <div className='settings-area loading'>
                  <Spinner size='sm' />
                  Checking CRM State
                </div>
              )}
            </div>
          )}
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {errors?.length > 0 && (
            <div className='errors-area'>
              <Alert variant='danger'>
                {errors.map((x, i) => (
                  <p key={i}>{x}</p>
                ))}
              </Alert>
            </div>
          )}
          <>
            {view === 'type_select' && (
              <>
                <div className='search-type-outer-wrapper'>
                  <div className='basic-section main-sets-section'>
                    {
                      <>
                        {Object.entries(
                          functionManifests['company_search']
                            ?.filter(
                              (option) => !option.hide_from_basic_selection,
                            )
                            .filter((option) => !option.is_crm_option)
                            .reduce((grouped, option) => {
                              const category =
                                option.search_category ||
                                (option.import
                                  ? 'Popular Imports'
                                  : 'Popular Searches');
                              if (!grouped[category]) grouped[category] = [];
                              grouped[category].push(option);
                              return grouped;
                            }, {}),
                        )
                          .filter(([category, options]) => {
                            return (
                              options.filter((option) =>
                                checkShouldShowForType(
                                  'company_search',
                                  option,
                                ),
                              ).length > 0
                            );
                          })
                          .map(([category, options], index) => {
                            let isExpanded =
                              expanded.length === 0 && index === 0
                                ? !haveStartedExpanding
                                : expanded.find((x) => x === category);

                            const toggleExpanded = () => {
                              setExpanded((prev) => {
                                if (isExpanded) {
                                  return prev.filter((x) => x !== category);
                                } else {
                                  return [category];
                                }
                              });
                              setHaveStartedExpanding(true);
                            };
                            return (
                              <div key={category} className='category-section'>
                                <h5 onClick={toggleExpanded}>
                                  {category}
                                  <span>
                                    {isExpanded ? (
                                      <UpCaretIcon />
                                    ) : (
                                      <DownCaretIcon />
                                    )}
                                  </span>
                                </h5>
                                <div
                                  className={`search-type-select ${isExpanded ? 'expanded' : 'collapsed'}`}
                                >
                                  {options
                                    .filter((option) =>
                                      checkShouldShowForType(
                                        'company_search',
                                        option,
                                      ),
                                    )
                                    .map((option) => (
                                      <div
                                        key={option.type}
                                        className={`option-wrapper ${searchStage?.type === option.type ? 'active' : ''}`}
                                        onClick={() =>
                                          handleStepTypeSelect(option.type)
                                        }
                                      >
                                        <div className='icon-wrapper'>
                                          {option.icon}
                                        </div>
                                        <div className='option-name'>
                                          <p>{option.name}</p>
                                          {option.experimental && (
                                            <p className='tag'>Beta</p>
                                          )}
                                        </div>
                                        <p className='long-description'>
                                          {option.description}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            );
                          })}
                      </>
                    }
                  </div>
                  {isImport &&
                    functionManifests['company_search'].find(
                      (x) => x.is_crm_option,
                    ) && (
                      <div className='basic-section crm-select'>
                        <h5>Sync from your CRM</h5>
                        {!isCRMStateLoading &&
                          !retrieveCRMError &&
                          hasAnyCRMConnection && (
                            <>
                              {isCRMInFailState && (
                                <div className='info-line'>
                                  <div className='alert'>
                                    <AlertIcon />
                                    {crmDualStates
                                      ? 'At least one of your CRM integrations has not synced as expected, please contact us.'
                                      : 'Your CRM has not synced as expected, please contact us.'}
                                  </div>
                                </div>
                              )}
                              {isCRMSyncing && (
                                <div className='info-line'>
                                  <div className='loading-line'>
                                    <Spinner />
                                    <span>
                                      {crmDualStates
                                        ? 'You’ve added a new CRM, we’re syncing data and will let you know when it’s ready.'
                                        : `We’re syncing data for your CRM. We’ll let you know via email when it’s ready.`}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div
                                className={`crm-options search-type-select expanded ${crmDualStates ? 'dual-states' : ''}`}
                              >
                                {functionManifests['company_search']
                                  ?.filter(
                                    (option) =>
                                      !option.hide_from_basic_selection,
                                  )
                                  .filter((option) => option.is_crm_option)
                                  .filter((option) =>
                                    checkShouldShowForType(
                                      'company_search',
                                      option,
                                    ),
                                  )
                                  .map((option) => (
                                    <div
                                      key={option.type}
                                      className={`option-wrapper crm-icons ${searchStage?.type === option.type ? 'active' : ''} ${!isCRMFine ? 'disabled' : ''}`}
                                      onClick={() => {
                                        if (isCRMFine) {
                                          return handleStepTypeSelect(
                                            option.type,
                                          );
                                        }
                                      }}
                                    >
                                      <div className='icon-wrapper'>
                                        {option.icon}
                                      </div>
                                      <div className='option-name'>
                                        <p>{option.name}</p>
                                        {option.experimental && (
                                          <p className='tag'>Beta</p>
                                        )}
                                      </div>
                                      {
                                        <p className='long-description'>
                                          {option.description}
                                        </p>
                                      }
                                    </div>
                                  ))}
                              </div>
                            </>
                          )}
                        {!isCRMStateLoading &&
                          !retrieveCRMError &&
                          !hasAnyCRMConnection && (
                            <div className='no-crm-state'>
                              <PlugIcon />
                              <h6>You have not connected any CRMs</h6>
                              <Link to='/settings/integrations'>
                                Connect your CRM
                              </Link>
                            </div>
                          )}
                        {isCRMStateLoading && (
                          <div className='loading-crm-state'>
                            <Spinner />
                          </div>
                        )}
                        {retrieveCRMError && (
                          <div className='loading-crm-error'>
                            <AlertIcon />
                            <span>
                              We can't currently retrieve the state of your CRM
                              connection. Please try again.
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  {!isExclusion && (
                    <div className='advanced-seciton'>
                      <h5>I want to create my own search</h5>
                      <div
                        className={`setup-prompts-button ${advancedSelected && 'active'}`}
                        onClick={selectAdvanced}
                      >
                        <MagicWandIcon />
                        Setup my own prompts
                      </div>
                    </div>
                  )}
                  {isExclusion && (
                    <DreamInfoExpander
                      heading='How do Do Not Contact lists work?'
                      icon={<FontAwesomeIcon icon={faInfoCircle} />}
                      alwaysExpanded
                    >
                      <p>
                        Companies in this list will never be added to campaigns,
                        however companies that are already in campaigns will not
                        be removed.
                      </p>
                      <p>
                        Your agent will also continue to find these companies
                        for your target lists.
                      </p>
                    </DreamInfoExpander>
                  )}
                </div>
              </>
            )}
            {view === 'option_select' && (
              <div>
                <div className='options-tab-content'>
                  <div className='options-tab-content-header'>
                    <h4>
                      {
                        functionManifests['company_search']?.find(
                          (x) => x.type === searchStage?.type,
                        )?.name
                      }
                    </h4>
                    {searchStage?.type && (
                      <p className='long-description'>
                        {functionManifests['company_search'].find(
                          (x) => x.type === searchStage?.type,
                        )?.long_description ||
                          functionManifests['company_search'].find(
                            (x) => x.type === searchStage?.type,
                          )?.description}
                      </p>
                    )}
                  </div>
                  <StageAdditionalOptions
                    manifest={functionManifests['company_search']}
                    stage={searchStage}
                    handleMetadataChange={handleMetadataChange}
                  />
                </div>
                <div className='match-count'>
                  {searchStage?.type &&
                    functionManifests['company_search'].find(
                      (x) => x.type === searchStage?.type,
                    )?.match_language && (
                      <div className='search-step-matches-view'>
                        <b>
                          <SearchVolumeEstimate
                            type={searchStage.type}
                            metadata={searchStage?.metadata}
                          />
                        </b>{' '}
                        {functionManifests['company_search'].find(
                          (x) => x.type === searchStage?.type,
                        )?.match_sample_text || 'matches'}
                      </div>
                    )}
                </div>
              </div>
            )}
          </>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          {view === 'type_select' && (
            <>
              <Button
                variant='primary'
                onClick={() => {
                  if (!advancedSelected) {
                    setView('option_select');
                  } else {
                    return navigate(
                      `/advanced-mode?name=${encodeURIComponent(name)}&use_case_type=${useCaseType}&use_case_description=${useCaseOther}`,
                    );
                  }
                }}
                disabled={!searchStage?.type && !advancedSelected}
              >
                Settings <FontAwesomeIcon icon={faArrowRight} />
              </Button>
              <Button variant='outline-primary' onClick={handleBack}>
                Back
              </Button>
            </>
          )}
          {view === 'option_select' && (
            <>
              <Button variant='primary' onClick={handleSubmit}>
                Create List
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => setView('type_select')}
              >
                Back
              </Button>
            </>
          )}
          {view === 'test_select' && (
            <>
              <Button
                variant='outline-primary'
                onClick={() => setView('option_select')}
              >
                Back
              </Button>
            </>
          )}
        </DreamModalInner.Footer>
      </DreamModalInner>

      {showCRMSettingsModal && (
        <FunnelCRMSettingsModal
          show
          handleClose={() => setShowCRMSettingsModal(false)}
          crmFilterSettings={crmFilterSettings}
          setCRMFilterSettings={setCRMFilterSettings}
        />
      )}
    </>
  );
}

export default SearchConfigModal;
