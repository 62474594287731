import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as NotFoundIcon } from '../../../icons/not-found.svg';

function NoAnswer({ id }) {
  return (
    <OverlayTrigger
      placement='top'
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip id={`tooltip-${id}`}>
          Your agent couldn't confidently answer this question.
        </Tooltip>
      }
    >
      <span>
        <div className='not-found-frame'>
          <NotFoundIcon />
        </div>
      </span>
    </OverlayTrigger>
  );
}

export default NoAnswer;
