import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import CompaniesSettings from './CompaniesSettings';
import EmbeddedCompaniesList from './EmbeddedCompaniesList';
import api from '../../../api';

import { usePusher } from '../../../contexts/PusherContext';

function Companies({ advance, nextText, oagentId, wizard }) {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState('list');
  const [hasNoCompanies, setHasNoCompanies] = useState(true);
  const [knownOrgs, setKnownOrgs] = useState([]);
  const [isInitialCheck, setIsInitialCheck] = useState(true);
  const [error, setError] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const [forceRunSummaryFetch, setForceRunSummaryFetch] = useState(false);

  const pusherChannel = usePusher();
  const assessSyncing = (data) => {
    const processingOrWaiting =
      data &&
      data.status_counts
        .filter(
          (item) => item.status === 'processing' || item.status === 'waiting',
        )
        .reduce((sum, item) => sum + parseInt(item.count, 10), 0);
    setIsSyncing(processingOrWaiting > 0);
  };

  const handleResync = async () => {
    setError(null); // Reset error before resynchronizing
    setIsSyncing(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/wizard-company-sync`,
        { force: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setForceRunSummaryFetch(!forceRunSummaryFetch);
    } catch (error) {
      console.error('Error during resync:', error);
      setError('Error during resync.');
    }
  };

  const checkKnownOrgs = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/known-orgs`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setKnownOrgs(response.data);

      if (response.data.length > 0) {
        setHasNoCompanies(false);
      } else {
        setHasNoCompanies(true);
      }
    } catch (err) {
      console.error('Error fetching known orgs:', err);
      setError('Error fetching known orgs: ' + err.message);
    } finally {
      setIsInitialCheck(false);
    }
  };

  const fetchDetails = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/oagents/${oagentId}/import-run-summary/company`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      assessSyncing(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  useEffect(() => {
    checkKnownOrgs();
    fetchDetails();

    if (!pusherChannel) return;
    const pusherCallback = (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'import_run_update') {
          assessSyncing(data.company_summary);
        }
      }
    };

    pusherChannel.bind('oagent_status_update', pusherCallback);

    // Cleanup on unmount
    return () => {
      pusherChannel.unbind('oagent_status_update', pusherCallback);
    };
  }, [oagentId, pusherChannel]);

  const handleAdvance = async () => {
    advance();
  };

  return (
    <div className='companies-step-wrapper'>
      <div className='left-side'>
        <CompaniesSettings
          oagentId={oagentId}
          wizard={wizard}
          nextFn={handleAdvance}
          exitWithoutSaving={!hasNoCompanies ? () => setStep('list') : false}
          setCanSearch={setCanSearch}
        />

        {canSearch && (
          <div className='big-sync-button-area'>
            <Button
              className={`big-sync-button ${isSyncing && 'syncing'}`}
              onClick={() => handleResync()}
              disabled={isSyncing}
            >
              {isSyncing && (
                <>
                  <Spinner /> Finding companies
                </>
              )}
              {!isSyncing && <>Load companies from sources</>}
            </Button>
          </div>
        )}
      </div>
      <div className='right-side'>
        {error && <Alert variant='danger'>{error}</Alert>}
        <EmbeddedCompaniesList
          openSettings={() => {}}
          nextFn={handleAdvance}
          nextText={nextText}
          oagentId={oagentId}
          knownOrgs={knownOrgs}
          setKnownOrgs={setKnownOrgs}
          isInitialCheck={isInitialCheck}
          forceRunSummaryFetch={forceRunSummaryFetch}
          hasFunnels={canSearch}
          loadFromSources={() => handleResync()}
          isSyncing={isSyncing}
        />
      </div>
    </div>
  );
}

export default Companies;
