import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as ErrorPageIcon } from '../../icons/error-page.svg';

import './not-found-page.css';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className='not-found-page'>
      <ErrorPageIcon />
      <h6>Could not find the specified resource</h6>
      <div>
        <p>
          You may be trying to access something you don't have authorization
          for.
        </p>
        <p>Or you may have just taken a wrong turn! Check and try again.</p>
      </div>
      <Button onClick={() => navigate('/')}>Return to your dashboard</Button>
    </div>
  );
}

export default NotFoundPage;
