import React, { memo } from 'react';
import NoAnswer from './NoAnswer';

const ValueCell = (params) => {
  if (params.noValueFound) {
    return <></>;
  }

  if (params.value || params.value === 0) {
    return (
      <span className='tether-holder'>
        {params.value}
        {params.tethered && <div className='tether-dot'></div>}
      </span>
    );
  } else {
    return (
      <div className='value-no-answer tether-holder'>
        <div className='not-found-outer'>
          <NoAnswer />
        </div>
        {params.tethered && <div className='tether-dot'></div>}
      </div>
    );
  }
};

export default memo(ValueCell);
