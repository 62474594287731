/* eslint-disable react/display-name */
import React from 'react';
import './DreamModal.css';

const DreamModalInner = ({ children, className }) => {
  return <div className={`dream-modal-inner ${className}`}>{children}</div>;
};

DreamModalInner.Header = ({ children }) => {
  return <div className='dream-modal-inner-header'>{children}</div>;
};

DreamModalInner.Body = ({ children }) => {
  return <div className='dream-modal-inner-body'>{children}</div>;
};

DreamModalInner.Footer = ({ children }) => {
  return <div className='dream-modal-inner-footer'>{children}</div>;
};

DreamModalInner.OptsWrapper = ({ children }) => {
  return <div className='dream-modal-inner-opts-wrapper'>{children}</div>;
};

DreamModalInner.OptsMenu = ({ children, closeCascade }) => {
  return (
    <DreamModalInner className='opts-menu'>
      <DreamModalInner.Body>
        <div className='opts-inner'>{children}</div>
        <div className='opts-lower' onClick={closeCascade}></div>
      </DreamModalInner.Body>
    </DreamModalInner>
  );
};

export default DreamModalInner;
