import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Form } from 'react-bootstrap';
import api from '../../../api';

function CRMStages({ onChange, selectedStages, onChangeBehaviour, behaviour }) {
  const { getAccessTokenSilently } = useAuth0();
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const response = await api.get('/crm/deals/stages', { headers });

        setStages(response.data.stages);
      } catch (error) {
        console.error('Error fetching stages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStages();
  }, [getAccessTokenSilently]);

  const options = stages.map((stage) => ({
    value: stage.id,
    label: `${stage.name} (${stage.status})`,
  }));

  const handleChange = (selectedOptions) => {
    if (onChange) {
      onChange(selectedOptions.map((option) => option.value));
    }
  };

  const handleRadioChange = (value) => {
    if (onChangeBehaviour) {
      onChangeBehaviour(value);
    }
  };

  if (loading) {
    return (
      <div className='spinner-wrapper dream-side-modal-form-input-loading'>
        <Spinner animation='border' />
        <span className='spinner-loader'>Loading stages...</span>
      </div>
    );
  }

  return (
    <>
      <div className='exclusion-radios'>
        <Form.Check
          type='radio'
          id='include-selected'
          name='stage-behaviour'
          label='Include selected stages'
          checked={!behaviour}
          onChange={() => handleRadioChange(false)}
        />
        <Form.Check
          type='radio'
          id='exclude-selected'
          name='stage-behaviour'
          label='Exclude selected stages'
          checked={behaviour === true}
          onChange={() => handleRadioChange(true)}
        />
      </div>
      <Select
        isMulti
        value={options.filter((option) =>
          selectedStages.includes(option.value),
        )}
        options={options}
        onChange={handleChange}
        placeholder='Select Deal Stages'
        className='additional-options-select'
        classNamePrefix='additional-options-select'
      />
    </>
  );
}

export default CRMStages;
