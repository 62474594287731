import React, { useState } from 'react';

function textRenderer(val) {
  return `${val.name} joined ${val.hire_date} as ${val.job_title}`;
}
const RecentHires = ({ value: val }) => {
  // Can happen with malformation of the search on the backend
  if (!val) {
    return <></>;
  }

  let recentHires = [];
  try {
    recentHires = JSON.parse(val);
  } catch (e) {
    console.error(`Could not parse `, val);
    return <></>;
  }

  const recentHire = recentHires[0];

  if (!recentHire) {
    return <></>;
  }

  return (
    <>
      <a
        className='tiny-link-button'
        target='_blank'
        href={recentHire?.linkedin_url}
        rel='noreferrer'
      >
        {textRenderer(recentHire)}
      </a>
    </>
  );
};

export default RecentHires;
