import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { Spinner } from 'react-bootstrap';
import api from '../../../api';
import { useAuth0 } from '@auth0/auth0-react';
import './highlighted-email.css';
import DreamInfoExpander from '../../misc/DreamInfoExpander';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const HighlightedEmailBody = ({
  email,
  emailId,
  prospectId,
  oagentId,
  feedback,
}) => {
  const {
    email: text,
    missing_research,
    adapted,
    contradictory_guidance,
  } = email;
  const [highlightedText, setHighlightedText] = useState(text);
  const [tooltips, setTooltips] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false); // State for feedback section
  const { getAccessTokenSilently } = useAuth0();

  const fetchHighlightedText = async () => {
    setLoading(true); // Set loading to true before starting the fetch
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/assess-datapoint-usage',
        { text, emailId, prospectId, oagentId },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Only update if emailId matches
      const highlights = response.data.highlights;
      if (response.data.emailId === emailId) {
        let newText = text;
        let newTooltips = [];

        highlights.forEach(({ string, research_point }, index) => {
          const tooltipId = `tooltip-${index}`;
          newText = newText.replace(
            new RegExp(`(${string})`, 'g'),
            `<span class="highlight" data-tip data-tooltip-id="${tooltipId}">$1</span>`,
          );
          newTooltips.push({ id: tooltipId, content: research_point });
        });

        setHighlightedText(newText);
        setTooltips(newTooltips);
      }
    } catch (error) {
      setHighlightedText(text);
      console.error('Error fetching highlighted text:', error);
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }
  };

  useEffect(() => {
    setHighlightedText(text);
    fetchHighlightedText();
  }, [text, emailId]);

  const toggleFeedback = () => {
    setIsFeedbackExpanded(!isFeedbackExpanded);
  };

  // Render the highlighted text with tooltips
  if (loading) {
    return (
      <div className='he-spinner-wrapper'>
        <Spinner animation='border' />
      </div>
    );
  }

  return (
    <div className='draft-view'>
      <div>
        <div
          className='highlighted-email-body'
          dangerouslySetInnerHTML={{
            __html: highlightedText.replace(/\n/g, '<br />'),
          }}
        />
        {tooltips.map(({ id, content }) => (
          <Tooltip key={id} id={id} place='top' effect='solid'>
            {content}
          </Tooltip>
        ))}
      </div>

      {feedback && feedback.length > 0 && (
        <div className='info-expander-wrapper'>
          <DreamInfoExpander
            heading={`Supervisor feedback - ${feedback.length} items`}
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
          >
            <ul className='supervision-notes'>
              {feedback.map((inaccuracy, index) => {
                if (!inaccuracy || typeof inaccuracy !== 'string') return;
                return <li key={index}>{inaccuracy}</li>;
              })}
            </ul>
          </DreamInfoExpander>
        </div>
      )}

      {missing_research && missing_research.length > 0 && (
        <div className='info-expander-wrapper'>
          <DreamInfoExpander
            heading={`Missing research issues`}
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
          >
            <p>
              For this prospect there was some missing research that seemed
              pertinent to the email:
            </p>
            <ul className='missing-research'>
              {missing_research.map((research) => (
                <li key={research}>{research}</li>
              ))}
            </ul>
            <p>Here's how your agent adapted the email:</p>
            <p>
              <b> "{adapted}"</b>
            </p>
          </DreamInfoExpander>
        </div>
      )}

      {contradictory_guidance && contradictory_guidance.length > 0 && (
        <div className='info-expander-wrapper'>
          <DreamInfoExpander
            heading={`Contradictory guidance`}
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
          >
            <p>
              For this prospect there was some guidance that seemed to
              contradict itself:
            </p>
            <ul className='missing-research'>
              {contradictory_guidance.map((research) => (
                <li key={research}>{research}</li>
              ))}
            </ul>
            <p>Here's how your agent adapted the email:</p>
            <p>
              <b> "{adapted}"</b>
            </p>
          </DreamInfoExpander>
        </div>
      )}
    </div>
  );
};

export default HighlightedEmailBody;
