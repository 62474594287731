import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form } from 'react-bootstrap';
import api from '../../api';
import { ReactComponent as ThumbsUpIcon } from '../../icons/green-thumb-up.svg';
import './task-list.css';
import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import DreamSideModal from '../misc/DreamSideModal';
import { Link } from 'react-router-dom';
import MessageText from '../misc/MessageText';
import FakePic from '../feed/items/FakePic';
import GuidanceApprovalModal from './GuidanceApprovalModal';
import ConversationOpts from '../misc/ConversationsOpts';

function ApprovalModal({ show, onHide, task, chooseNext }) {
  const { getAccessTokenSilently } = useAuth0();
  const [approval, setApproval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [guidance, setGuidance] = useState('');
  const [showGuidanceForm, setShowGuidanceForm] = useState(false);

  const fetchApprovalDetails = async () => {
    setLoading(true);
    setGuidance('');
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/approvals/${task.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApproval(response.data);
    } catch (error) {
      console.error('Error fetching approval details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (task) {
      fetchApprovalDetails();
    }
  }, [task, getAccessTokenSilently]);

  const handleAction = async (action) => {
    if (loading) return;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/approvals/${task.id}/action`,
        { action, guidance },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Dispatch custom event when modal is closed
      const hasNextTask = chooseNext();
      if (!hasNextTask) {
        const event = new Event('modalClose');
        window.dispatchEvent(event);
      }
    } catch (error) {
      console.error(`Error performing action ${action}:`, error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !approval) {
    return (
      <DreamSideModal show={show} onHide={onHide}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  const mailLang = approval.is_response ? 'reply' : 'message';

  return (
    <DreamSideModal show={show} handleClose={onHide} paired>
      <DreamModalInner.OptsWrapper>
        <ConversationOpts
          onHide={onHide}
          conversation={{ id: approval.conversation_id }}
          linkedIn={approval.linkedin_url}
          koLinkedIn={approval.known_org_linkedin_url}
          koDomain={approval.known_org_domain}
          setConversation={() => {}}
          forward={approval.conversation_id && approval.is_response}
        />
        <DreamModalInner>
          <DreamModalInner.Header>Approval required</DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='approvals-wrapper'>
              <div className='campaign-link-topper'>
                <p>
                  <b>Campaign:</b> {approval.campaign_name}
                </p>
                <Link to={`/campaigns/${approval.id}`}>View campaign</Link>
              </div>
              <div className='heading'>
                <h6>You need to approve this {mailLang}</h6>
                <p>
                  Your campaign settings require approval for messages.{' '}
                  <Link to={`/campaign/${approval.oagent_id}/settings`}>
                    Change settings
                  </Link>
                  .
                </p>
              </div>
              {approval.is_response && approval.messageTexts.length > 0 && (
                <div className='responding-to-wrapper'>
                  <MessageText
                    message={
                      approval.messageTexts[approval.messageTexts.length - 1]
                    }
                    shortHeader
                  />
                </div>
              )}
              <div className='staged-wrapper'>
                <div className='banner'>
                  {!approval.approved && (
                    <>
                      <b>This {mailLang} hasn't been sent.</b> You can approve
                      or request amendments below.
                    </>
                  )}
                </div>
                <div className='to-who-area'>
                  <div className='left-side'>
                    <FakePic url={approval?.prospects[0]?.logo_url} />
                    <div className='title'>
                      <p>
                        {approval?.prospects[0]?.name}{' '}
                        <small>• {approval?.prospects[0]?.company}</small>
                      </p>
                      <p>{approval?.prospects[0]?.title}</p>
                    </div>
                  </div>
                  <div className='right-side'>
                    <Link to={`/prospect/${approval?.prospects[0]?.id}`}>
                      View prospect
                    </Link>
                  </div>
                </div>
                <div className='buttons-area'>
                  {!approval.approved ? (
                    <>
                      <Button
                        onClick={() => handleAction('approve')}
                        disabled={loading}
                        variant='outline-primary'
                      >
                        Approve {mailLang}
                      </Button>
                      <Button
                        onClick={() => setShowGuidanceForm(true)}
                        disabled={loading}
                        variant='outline-primary'
                      >
                        Make changes
                      </Button>
                      <Button
                        variant='outline-danger'
                        className='reject'
                        onClick={() => handleAction('reject')}
                        disabled={loading}
                      >
                        Don't {mailLang}
                      </Button>
                    </>
                  ) : (
                    <div className='approved-wrapper'>
                      <ThumbsUpIcon />
                      <h6>You approved this {mailLang}.</h6>
                      <p>Changed your mind?</p>
                      <Button
                        variant='outline-danger'
                        onClick={() => handleAction('ignore')}
                        disabled={loading}
                      >
                        Cancel {mailLang}
                      </Button>
                    </div>
                  )}
                </div>
                <div className='message-wrapper'>
                  <div className='body-area'>
                    {approval.is_new_thread && (
                      <div className='section'>
                        <div className='header'>
                          <h6>Subject</h6>
                        </div>
                        <hr />
                        <div className='body'>{approval.subject}</div>
                      </div>
                    )}
                    <div className='section'>
                      <div className='header'>
                        <h6>
                          {mailLang.slice(0, 1).toUpperCase() +
                            mailLang.slice(1)}
                        </h6>
                      </div>
                      <hr />
                      <div
                        className='body'
                        dangerouslySetInnerHTML={{
                          __html: approval.content.replace(/\n/g, '<br />'),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {showGuidanceForm && !approval.approved && (
                <GuidanceApprovalModal
                  show={show}
                  approval={approval}
                  handleClose={() => setShowGuidanceForm(false)}
                  handleGuidanceGenerate={() => {
                    setShowGuidanceForm(false);
                    onHide();
                  }}
                  handleEditSubmit={() => {
                    setShowGuidanceForm(false);
                    fetchApprovalDetails();
                  }}
                  taskId={task.id}
                />
              )}
            </div>
          </DreamModalInner.Body>
        </DreamModalInner>
      </DreamModalInner.OptsWrapper>
    </DreamSideModal>
  );
}

export default ApprovalModal;
