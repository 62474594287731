import React, { useState, useEffect } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { usePusher } from '../../contexts/PusherContext';
import DataTable from 'react-data-table-component';
import api from '../../api';
import FakePic from '../feed/items/FakePic';
import MessageDetailsModal from './MessageDetailsModal';
import { format } from 'date-fns';
import ApprovalModal from '../tasks/ApprovalModal';

function MessageList({ oagentId, openSettings }) {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchMessageList();

    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        fetchMessageList();
      }
    });

    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [oagentId]);

  const fetchMessageList = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/message-list/${oagentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEmails(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages.');
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    setSelectedEmail(row);
    setShowModal(true);
  };

  const sortSendTime = (a, b) => {
    const aVal =
      a.status === 'SENT'
        ? -1
        : !a.approved
          ? 999999999999 * 100000
          : a.to_send_time
            ? new Date(a.to_send_time).getTime()
            : 0;
    const bVal =
      b.status === 'SENT'
        ? -1
        : !b.approved
          ? 999999999999 * 100000
          : b.to_send_time
            ? new Date(b.to_send_time).getTime()
            : 0;

    return aVal > bVal ? -1 : 1;
  };

  const columns = [
    {
      name: 'Prospect',
      selector: (row) => (
        <div className='name-wrapper'>
          <FakePic url={row.prospect_logo_url} />
          <span>{row.prospect_name}</span>
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        return a.prospect_name < b.prospect_name ? -1 : 1;
      },
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: true,
      width: '500px',
    },
    {
      name: 'Organization',
      selector: (row) => row.known_org_name || '-',
      sortable: true,
    },
    {
      name: 'Message sequence',
      selector: (row) =>
        !row.is_new_thread
          ? 'Follow-up'
          : row.email_order === 0
            ? 'First email'
            : 'New thread',
      sortable: true,
    },
    {
      name: 'Send Time',
      selector: (row) => {
        if (row.status === 'SENT') {
          return <div className='tag positive'>Sent</div>;
        }

        if (!row.approved) {
          return <div className='tag warning'>Awaiting approval</div>;
        }

        if (row.to_send_time) {
          return format(new Date(row.to_send_time), 'EEEE p');
        }

        return <div className='tag neutral'>Scheduling</div>;
      },
      sortable: true,
      sortFunction: sortSendTime,
    },
  ];

  if (loading) {
    return (
      <div className='middle-loading-view'>
        <Spinner animation='border' />
      </div>
    );
  }

  return (
    <div className='message-list-wrapper'>
      {error && <Alert variant='danger'>{error}</Alert>}

      <div className='embedded-list-header'>
        <div className='left'>
          <h5>Outreach Messages</h5>
          {openSettings && (
            <Button variant='outline-primary' onClick={openSettings}>
              Edit message sequence & prompts
            </Button>
          )}
        </div>
      </div>
      <div className='dream-table-wrapper'>
        <DataTable
          columns={columns}
          data={emails.sort(sortSendTime)}
          pagination
          onRowClicked={handleRowClick}
          highlightOnHover
          progressPending={loading}
          progressComponent={
            <div className='loading-spinner'>
              <Spinner animation='border' />
            </div>
          }
        />
      </div>
      {selectedEmail && selectedEmail.approved && (
        <MessageDetailsModal
          show={showModal}
          oagentId={oagentId}
          onHide={() => {
            setShowModal(false);
            fetchMessageList();
          }}
          email={selectedEmail}
        />
      )}
      {selectedEmail && !selectedEmail.approved && (
        <ApprovalModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            fetchMessageList();
          }}
          task={{ id: selectedEmail.scheduled_email_id }}
        />
      )}
    </div>
  );
}

export default MessageList;
