import React from 'react';
import { ReactComponent as KnownJobTitleIcon } from '../icons/job-title.svg';
import { ReactComponent as AgentIcon } from '../icons/agent-search.svg';
import { ReactComponent as ResearchRelatedIcon } from '../icons/research-point.svg';
import { ReactComponent as UploadListIcon } from '../icons/upload-list.svg';

import KnownJobTitleModal from '../components/campaigns/steps/KnownJobTitleModal';
import GuidanceProspectSearchModal from '../components/campaigns/steps/GuidanceProspectSearchModal';
import ResearchRelatedModal from '../components/campaigns/steps/ResearchRelatedModal';
import ImportProspectsFromListModal from '../components/campaigns/steps/ImportProspectsFromListModal.js';

const prospectSearchTypes = {
  known_job_title: {
    polite: 'By job title',
    shortDisplay: ({ metadata }) => {
      if (!metadata?.job_titles || !Array.isArray(metadata.job_titles)) {
        return 'No job titles';
      }

      if (metadata.job_titles.length <= 2) {
        return metadata.job_titles.join(', ');
      }

      return `${metadata.job_titles.slice(0, 2).join(', ')} + ${metadata.job_titles.length - 2} others`;
    },
    icon: <KnownJobTitleIcon />,
    Modal: KnownJobTitleModal,
    description: 'Find people based on their job title from LinkedIn',
  },
  agent_guidance: {
    polite: 'Agent search',
    shortDisplay: ({ metadata }) => {
      if (metadata.guidance) {
        return metadata.guidance.slice(0, 150) + '...';
      }
      return '';
    },
    icon: <AgentIcon />,
    Modal: GuidanceProspectSearchModal,
    description:
      'Have your agent look through prospects based on your guidance',
  },
  research_related: {
    polite: 'Related to research',
    icon: <ResearchRelatedIcon />,
    Modal: ResearchRelatedModal,
    description:
      'Have your agent look for prospects related to research listed with the company',
    shortDisplay: ({ metadata }) => {
      if (metadata.guidance) {
        return metadata.guidance;
      }
      return '';
    },
  },
  imported_prospects: {
    polite: 'From a direct import',
    icon: <UploadListIcon />,
    Modal: ImportProspectsFromListModal,
    description: 'Agent will pull through Prospects listed with the company',
    shortDisplay: () =>
      'Agent will pull through Prospects uploaded in your list',
  },
};

export { prospectSearchTypes };
