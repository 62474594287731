import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';

import './guidance-approval-modal.css';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

function GuidanceApprovalModal({
  handleClose,
  handleGuidanceGenerate,
  handleEditSubmit,
  show,
  approval,
  taskId,
}) {
  const { getAccessTokenSilently } = useAuth0();

  const [guidance, setGuidance] = useState('');
  const [subject, setSubject] = useState(approval.subject);
  const [content, setContent] = useState(approval.content);
  const [choice, setChoice] = useState('guidance');
  const [isLoading, setIsLoading] = useState('');

  useEffect(() => {
    setSubject(approval.subject);
    setContent(approval.content);
    setGuidance('');
    setChoice('guidance');
  }, [approval]);

  const makeEditChanges = async () => {
    if (isLoading) return;
    setIsLoading('Making edits');

    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/approvals/${taskId}/action`,
        { action: 'edit', content, subject },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Dispatch custom event when modal is closed
      const event = new Event('modalClose');
      window.dispatchEvent(event);
      handleEditSubmit();
    } catch (error) {
      console.error(`Error performing action improve:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeGuidanceChanges = async () => {
    if (isLoading) return;
    setIsLoading('Requesting changes');

    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/approvals/${taskId}/action`,
        { action: 'improve', guidance },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Dispatch custom event when modal is closed
      const event = new Event('modalClose');
      window.dispatchEvent(event);
      handleGuidanceGenerate();
    } catch (error) {
      console.error(`Error performing action improve:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeChanges = () => {
    if (choice === 'edit') {
      makeEditChanges();
    } else {
      makeGuidanceChanges();
    }
  };

  if (isLoading) {
    return (
      <DreamSideModal show={show}>
        <DreamModalInner>
          <DreamSideModalLoader>{isLoading}</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal handleClose={handleClose} show={show}>
      <DreamModalInner>
        <DreamModalInner.Header>Edit message</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='guidance-approval-modal dream-side-modal-form'>
            <p className='top-description'>
              Give instructions to your messaging agent, or if you want to make
              small tweaks yourself you can edit directly.
            </p>
            <div className='choices-set'>
              <div className={`choice ${choice !== 'guidance' && 'inactive'}`}>
                <div
                  className='choice-header'
                  onClick={() => setChoice('guidance')}
                >
                  <input
                    name='choice'
                    type='radio'
                    checked={choice === 'guidance'}
                    onClick={() => setChoice('guidance')}
                  />
                  <span>As your agent to change your message</span>
                </div>
                <div className='form-area'>
                  <Form.Group>
                    <Form.Label>What changes do you want to make?</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={guidance}
                      onClick={() => setChoice('guidance')}
                      onChange={(e) => setGuidance(e.target.value)}
                      placeholder='e.g. "Make sure to highlight our annual savings guarantee'
                      rows={3}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className={`choice edit ${choice !== 'edit' && 'inactive'}`}>
                <div
                  className='choice-header'
                  onClick={() => setChoice('edit')}
                >
                  <input
                    name='choice'
                    type='radio'
                    checked={choice === 'edit'}
                    onClick={() => setChoice('edit')}
                  />
                  <span>Edit the message yourself</span>
                </div>
                <div className='form-area'>
                  {approval.is_new_thread && (
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        value={subject}
                        type='text'
                        onClick={() => setChoice('edit')}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      value={content}
                      as='textarea'
                      onClick={() => setChoice('edit')}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={() => makeChanges()}>
            Request changes
            <div className='yellow-icon'>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </Button>
          <Button onClick={handleClose} variant='outline-primary'>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default GuidanceApprovalModal;
