import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CompaniesList = ({
  allFunnels,
  existingFunnels,
  isFunnelInExisting,
  handleAddFunnel,
  handleRemoveFunnel,
}) => {
  const navigate = useNavigate();

  const formattedDate = (dateString) => {
    const dateOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, dateOptions);
  };

  return (
    <div className='edit-companies-modal'>
      <label>Your lists</label>
      <div className='items-to-choose'>
        {allFunnels
          .sort((a, b) => {
            const dateComparison =
              new Date(b.created_at) - new Date(a.created_at);
            if (dateComparison !== 0) {
              return dateComparison;
            }
            return (
              (b.details?.filteredOrgsCount || 0) -
              (a.details?.filteredOrgsCount || 0)
            );
          })
          .filter((funnel) => !funnel.exclusion_list)
          .map((funnel) => (
            <div
              key={funnel.id}
              className={`company item sexy-white ${
                isFunnelInExisting(funnel.id) ? 'active' : ''
              }`}
            >
              <div className='left-area'>
                <h6>{funnel.name}</h6>
                <small>
                  {funnel?.details?.filteredOrgsCount || 0} companies • Created{' '}
                  {formattedDate(funnel?.created_at)}
                </small>
              </div>
              <div className='button-wrapper'>
                {!isFunnelInExisting(funnel.id) ? (
                  <Button
                    variant='outline-primary'
                    onClick={() => handleAddFunnel(funnel.id)}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant='primary'
                    onClick={() => handleRemoveFunnel(funnel.id)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>
          ))}
        <Button
          onClick={() => navigate('/funnel')}
          variant='outline-primary'
          className='create-new-button'
        >
          Create new List
        </Button>
      </div>
    </div>
  );
};

export default CompaniesList;
