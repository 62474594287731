import React, { useState } from 'react';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { prospectSearchTypes as searchTypes } from '../../../utils/prospect-search-types';
import './add-prospect-search-modal.css';

function AddProspectSearchModal({
  oagentId,
  show,
  handleClose,
  refreshProspectSearch,
}) {
  const [selected, setSelected] = useState(null);
  const [typeIsOpen, setTypeIsOpen] = useState(false);

  const selectedType = searchTypes[selected];

  return (
    <>
      <DreamSideModal show={show} handleClose={handleClose}>
        <DreamModalInner>
          <DreamModalInner.Header>Setup prospect search</DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='add-prospect-search-modal'>
              <div className='header'>
                <h6>Add additional search</h6>
                <p>
                  Add a search to find prospects in the event that your previous
                  searches don't find the maximum number of prospects you're
                  looking for.
                </p>
              </div>

              <div className='prospect-search-overview'>
                {Object.keys(searchTypes).map((searchType) => {
                  const type = searchTypes[searchType];
                  const active = selected === searchType;

                  return (
                    <div
                      className={`search-type ${active && 'active'}`}
                      key={searchType}
                      onClick={() => setSelected(searchType)}
                    >
                      {type.icon}
                      <div className='main-body'>
                        <p>{type.polite}</p>
                        <p>{type.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <Button onClick={() => setTypeIsOpen(true)} disabled={!selected}>
              Add prospect search{' '}
              <div className='yellow-icon'>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Button>
            <Button variant='outline-primary' onClick={handleClose}>
              Back
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>
      {selected && selectedType && typeIsOpen && (
        <selectedType.Modal
          show
          handleClose={() => {
            setTypeIsOpen(false);
            handleClose();
          }}
          oagentId={oagentId}
          editMode={false}
          refreshProspectSearch={refreshProspectSearch}
        />
      )}
    </>
  );
}

export default AddProspectSearchModal;
