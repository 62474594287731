import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [imposedWarnings, setImposedWarnings] = useState(false);
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    // Reset imposedWarnings when the URL changes
    setImposedWarnings(false);
  }, [location.pathname]); // Runs every time the pathname changes

  return (
    <SidebarContext.Provider
      value={{
        isSidebarVisible,
        setSidebarVisible,
        imposedWarnings,
        setImposedWarnings,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
