import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ImportedProspectsListModal from '../../misc/ImportedProspectsListModal';

const ProspectsData = ({ value, row }) => {
  const [showProspectsListModal, setShowProspectsListModal] = useState(false);
  const [selectedProspectsListData, setSelectedProspectsListData] = useState(
    [],
  );
  const [selectProspectsListCompanyData, setSelectedProspectsListCompanyData] =
    useState(null);

  const handleProspectsDataClick = (jobData, company) => {
    setSelectedProspectsListData(jobData);
    setSelectedProspectsListCompanyData(company);
    setShowProspectsListModal(true);
  };

  let prospects = [];
  try {
    console.log(row);
    prospects = JSON.parse(value);
  } catch (e) {
    console.error(`Could not parse `, value);
  }

  if (prospects.length === 0) {
    return <></>;
  }

  return (
    <>
      <Button
        variant='link'
        className='tiny-link-button'
        onClick={() => handleProspectsDataClick(prospects, row)}
      >
        {`${prospects.length} ${prospects.length === 1 ? 'prospect' : 'prospects'} imported`}
      </Button>

      {showProspectsListModal && (
        <ImportedProspectsListModal
          show={showProspectsListModal}
          onHide={() => setShowProspectsListModal(false)}
          prospects={selectedProspectsListData}
          company={selectProspectsListCompanyData}
        />
      )}
    </>
  );
};

export default ProspectsData;
