import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { usePusher } from '../../contexts/PusherContext';

import { ReactComponent as WarningTriangleIcon } from '../../icons/warning-triangle.svg';
import api from '../../api';

const TaskCountTag = ({ campaignId, warningTriangleAllowed }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [taskCount, setTaskCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasWarningTriangle, setHasWarningTriangle] = useState(false);

  const fetchTasks = async () => {
    try {
      const token = await getAccessTokenSilently();
      const [approvals, escalations] = await Promise.all([
        api.get(`/approvals${campaignId ? `?campaign_id=${campaignId}` : ''}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(
          `/escalations${campaignId ? `?campaign_id=${campaignId}` : ''}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
      ]);

      const processedApprovals = approvals.data.map((item) => ({
        ...item,
        type: 'approval',
        date: new Date(item.scheduled_time),
      }));

      if (approvals.data.find((x) => x.is_response)) {
        setHasWarningTriangle(true);
      }

      const processedEscalations = escalations.data.map((item) => ({
        ...item,
        type: 'escalation',
        date: new Date(item.created_at),
      }));

      const mergedTasks = [...processedApprovals, ...processedEscalations];
      setTaskCount(mergedTasks.length);
    } catch (error) {
      setTaskCount('?');
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();

    const handleModalClose = () => {
      fetchTasks();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, []);

  useEffect(() => {
    if (!pusherChannel) return;

    pusherChannel.bind('oagent_status_update', () => {
      // fetchTasks();
    });

    return () => {
      if (pusherChannel) {
        pusherChannel.unbind('oagent_status_update');
      }
    };
  }, [pusherChannel]);

  if (loading) {
    return (
      <div className='nav-task-count-tag'>
        <Spinner animation='border' size='sm' />
      </div>
    );
  }

  if (taskCount === 0) {
    return null;
  }

  if (warningTriangleAllowed && hasWarningTriangle) {
    Tooltip;
    return (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id='warning-triangle'>You have replies to approve</Tooltip>
        }
      >
        <WarningTriangleIcon />
      </OverlayTrigger>
    );
  }
  return <div className='nav-task-count-tag'>{taskCount}</div>;
};

export default TaskCountTag;
