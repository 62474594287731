import React from 'react';
// import ReplyReceived from './ReplyReceived';
// import MeetingBooked from './MeetingBooked';
import FirstContact from './FirstContact';
import FollowUp from './FollowUp';
import CompanyAdded from './CompanyAdded';
import ProspectAdded from './ProspectAdded';
import Viewed from './Viewed';

const FeedSummary = ({
  items,
  count,
  time,
  type,
  children,
  onClick,
  expanded,
}) => {
  switch (type) {
    // case 'REPLY_RECEIVED':
    //   return (
    //     <ReplyReceived count={count} time={time}>
    //       {children}
    //     </ReplyReceived>
    //   );
    // case 'MEETING_BOOKED':
    //   return (
    //     <MeetingBooked count={count} time={time}>
    //       {children}
    //     </MeetingBooked>
    //   );
    case 'VIEWED':
      return (
        <Viewed
          items={items}
          count={count}
          time={time}
          onClick={onClick}
          expanded={expanded}
        >
          {children}
        </Viewed>
      );
    case 'FIRST_CONTACT':
      return (
        <FirstContact
          items={items}
          count={count}
          time={time}
          onClick={onClick}
          expanded={expanded}
        >
          {children}
        </FirstContact>
      );
    case 'FOLLOW_UP':
      return (
        <FollowUp
          items={items}
          count={count}
          time={time}
          onClick={onClick}
          expanded={expanded}
        >
          {children}
        </FollowUp>
      );
    case 'COMPANY_ADDED_TO_OAGENT':
      return (
        <CompanyAdded
          items={items}
          count={count}
          time={time}
          onClick={onClick}
          expanded={expanded}
        >
          {children}
        </CompanyAdded>
      );
    case 'PROSPECT_ADDED_TO_OAGENT':
      return (
        <ProspectAdded
          items={items}
          count={count}
          time={time}
          onClick={onClick}
          expanded={expanded}
        >
          {children}
        </ProspectAdded>
      );
    default:
      return null;
  }
};

export default FeedSummary;
