import React, { useState, useEffect } from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { Button, Form } from 'react-bootstrap';
import './create-funnel.css';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

function FunnelCRMSettingsModal({
  show,
  handleClose,
  funnelId,
  crmFilterSettings: externalCrmFilterSettings,
  setCRMFilterSettings: setExternalCRMFilterSettings,
  selfManaged = false,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Internal state for self-managed mode
  const [internalCrmFilterSettings, setInternalCRMFilterSettings] = useState({
    ignore_in_crm: false,
  });

  const crmFilterSettings = selfManaged
    ? internalCrmFilterSettings
    : externalCrmFilterSettings;

  const setCRMFilterSettings = selfManaged
    ? setInternalCRMFilterSettings
    : setExternalCRMFilterSettings;

  useEffect(() => {
    if (selfManaged && funnelId && show) {
      loadSettings();
    }
  }, [selfManaged, funnelId, show]);

  const loadSettings = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/crm-settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCRMFilterSettings(response.data);
    } catch (error) {
      console.error('Error loading CRM settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    setSaving(true);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/${funnelId}/crm-settings`,
        { ignore_in_crm: crmFilterSettings.ignore_in_crm },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      handleClose(); // Close the modal after saving
    } catch (error) {
      console.error('Error saving CRM settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const updateCheckedState = (checked) => {
    setCRMFilterSettings((prev) => ({
      ...prev,
      ignore_in_crm: checked,
    }));
  };

  if (loading || saving) {
    return (
      <DreamSideModal show={show}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {loading ? 'Loading Settings' : 'Saving Settings'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>Edit CRM Settings</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='crm-filter-settings-modal dream-side-modal-form'>
            <Form.Group>
              <Form.Label>Avoid companies already in CRM</Form.Label>
              <Form.Check
                type='checkbox'
                label='If checked your agent will not add any companies already in your connected CRM(s) to this list.'
                checked={crmFilterSettings.ignore_in_crm}
                onChange={(e) => updateCheckedState(e.target.checked)}
              />
            </Form.Group>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          {selfManaged && (
            <Button onClick={saveSettings} variant='primary'>
              Save Settings
            </Button>
          )}
          <Button onClick={handleClose} variant='outline-primary'>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default FunnelCRMSettingsModal;
