import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Spinner, Form } from 'react-bootstrap';
import { ReactComponent as PlugIcon } from '../../icons/plug.svg';

import api from '../../api'; // Ensure this path is correct

const Integrations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [crmData, setCrmData] = useState(null);
  const [syncProspects, setSyncProspects] = useState(false);

  const knitRef = useRef(null);

  // Backend call to generate & get the authSessionToken
  const newSessionFn = async (e) => {
    e?.preventDefault();
    setIsCreatingSession(true);
    // Hit the backend to generate authSessionToken
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/knit-link/token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      knitRef?.current?.setAttribute('authsessiontoken', response.data.token);
      knitRef?.current?.setAttribute('skipIntro', true);
    } catch (error) {
      console.error('Error getting Knit token:', error);
    } finally {
      setIsCreatingSession(false);
    }
  };

  // Upon finishing the integration flow
  const onFinishFn = async (e) => {
    console.log('Finished integration flow');
    e.preventDefault();
    setIsCreatingSession(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post('/knit-link/integration', e.detail.integrationDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      knitRef?.current?.setAttribute('skipIntro', true);
    } catch (error) {
      console.error('Error creating Knit connection:', error);
    } finally {
      setIsCreatingSession(false);
    }
  };

  // Upon deactivate of integration
  const onDeactivateFn = async (e) => {
    console.log('Deactivated integration');
    e.preventDefault();
    setIsCreatingSession(true);
    try {
      const token = await getAccessTokenSilently();
      await api.delete(
        `/knit-link/integration/${e.detail.integrationDetails.integrationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      knitRef?.current?.setAttribute('skipIntro', true);
    } catch (error) {
      console.error('Error creating Knit connection:', error);
    } finally {
      setIsCreatingSession(false);
    }
  };

  // Upon closing of the knit-auth component
  const onKnitCloseFn = (e) => {
    console.log('Closed Knit Component');
    e.preventDefault();
    setIsCreatingSession(false);
  };

  useEffect(() => {
    console.log('Adding event handlers');
    // Assign functions to event listeners for onNewSession and onFinish events.
    knitRef.current?.addEventListener('onNewSession', newSessionFn);
    knitRef.current?.addEventListener('onFinish', onFinishFn);
    knitRef.current?.addEventListener('onDeactivate', onDeactivateFn);
    knitRef.current?.addEventListener('onKnitClose', onKnitCloseFn);
    // Set the token once the component has mounted
    newSessionFn();
    return () => {
      // Remove events listeners on unmount
      knitRef.current?.removeEventListener('onNewSession', newSessionFn);
      knitRef.current?.removeEventListener('onFinish', onFinishFn);
      knitRef.current?.removeEventListener('onDeactivate', onDeactivateFn);
      knitRef.current?.removeEventListener('onKnitClose', onKnitCloseFn);
    };
  }, [knitRef.current]);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await api.get('/knit-link/integration-settings', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { crms, settings } = response.data;
      setCrmData(crms);
      if (settings.length > 0) {
        const setting = settings[0];
        setSyncProspects(setting.sync_prospect_to_crm);
      }
    } catch (error) {
      console.error('Error fetching CRM settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [getAccessTokenSilently]);

  const saveSettings = async ({ syncProspects }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await api.post(
        '/knit-link/integration-settings',
        {
          sync_prospect_to_crm: syncProspects,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error saving CRM settings:', error);
    }
  };

  if (isLoading) {
    return (
      <div className='integrations-page'>
        <h2>Integrations</h2>
        <div className='overlay'>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className='integrations-page'>
      <div className='main-header'>
        <h2>Integrations</h2>
        <p>Connect Dream to your tools and accelerate the way you work</p>
      </div>
      <div className='integration-set'>
        <div>
          <h3>Connect a CRM</h3>
          <p>
            Choose which CRM to connect from the list below. Don’t see your CRM?
            Contact your Account Manager.
          </p>
        </div>
        <div className='link-set'>
          <knit-auth ref={knitRef}>
            <Button
              variant='primary'
              slot='trigger'
              disabled={isCreatingSession}
            >
              {isCreatingSession ? (
                <>
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                  {' Verifying integration state'}
                </>
              ) : (
                <>
                  <PlugIcon />
                  {!isLoading ? (
                    <>
                      {crmData && crmData.length > 0
                        ? `Edit CRM Connections (${crmData.length})`
                        : 'You do not currently have a CRM connected'}
                    </>
                  ) : (
                    'Edit CRM Connections'
                  )}
                </>
              )}
            </Button>
          </knit-auth>
          {crmData && crmData.length > 0 && (
            <div className='settings-area'>
              <h6>CRM sync settings</h6>
              <Form.Check
                type='checkbox'
                id='syncProspectsCheckbox'
                label='When contacting a new prospect in a campaign, create a contact in my CRM'
                checked={syncProspects}
                onChange={(e) => {
                  setSyncProspects(e.target.checked);
                  saveSettings({ syncProspects: true });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
