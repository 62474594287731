import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';

function GenericEmailGuidanceModal({ show, handleClose, guidance, onSave }) {
  const [inputValue, setInputValue] = useState(guidance);

  useEffect(() => {
    setInputValue(guidance);
  }, [guidance]);

  const handleSave = () => {
    onSave(inputValue);
  };

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header closeButton>
          Generic Email Guidance
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form'>
            <Form.Group controlId='formGenericEmailGuidance'>
              <Form.Label>Guidance</Form.Label>
              <Form.Text>
                Explain what kind of email addresses to look for that may be
                more relevant. For example, if you're searching for restaurant
                contacts, ignoring "reservations@" is probably advisable.
              </Form.Text>
              <Form.Control
                as='textarea'
                rows={5}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Form.Group>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button variant='primary' onClick={handleSave}>
            Save guidance
          </Button>
          <Button variant='outline-primary' onClick={handleClose}>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default GenericEmailGuidanceModal;
