import React from 'react';
import DreamSideModal from './DreamSideModal';
import DreamModalInner from './DreamModalInner';
import './imported-prospects-list-modal.css';

function ImportedProspect({ show, onHide, prospect }) {
  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>{prospect.name}</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='imported-prospect-data'>
            {Object.keys(prospect).map((key) => {
              const value = prospect[key];
              return (
                <div className='value-set' key={key}>
                  <label>{key}</label>
                  <p>{value}</p>
                </div>
              );
            })}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default ImportedProspect;
