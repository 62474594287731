import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import api from '../../../api';
import { debounce } from '../../../utils/debounce'; // Adjust the import path if necessary

import ListList from './ListList';
import DatapointsList from './DatapointsList';

function EditCompaniesModal({
  show,
  handleClose,
  refreshFunnels,
  refreshDatapoints,
  oagentId,
  stepView,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [allFunnels, setAllFunnels] = useState([]);
  const [existingFunnels, setExistingFunnels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDataPoints, setShowDataPoints] = useState(
    stepView === 'datapoints',
  );

  useEffect(() => {
    setShowDataPoints(stepView === 'datapoints');
  }, [stepView, show]);

  useEffect(() => {
    const fetchFunnels = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();

        // Fetch all funnels
        const allFunnelsResponse = await api.get('/funnels', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAllFunnels(allFunnelsResponse.data.funnels);

        // Fetch existing funnels for the oagent
        const existingFunnelsResponse = await api.get(
          `/oagents/${oagentId}/funnels`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setExistingFunnels(existingFunnelsResponse.data);
      } catch (error) {
        console.error('Error fetching funnels:', error);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchFunnels();
    }
  }, [show, oagentId, getAccessTokenSilently]);

  const isFunnelInExisting = (funnelId) =>
    existingFunnels.some((funnel) => funnel.id === funnelId);

  const handleAddFunnel = async (funnelId) => {
    try {
      const token = await getAccessTokenSilently();
      // Optimistically update the state
      setExistingFunnels([
        ...existingFunnels,
        allFunnels.find((f) => f.id === funnelId),
      ]);
      await api.post(`/oagents/${oagentId}/funnel/${funnelId}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      refreshFunnels();
    } catch (error) {
      console.error('Error adding funnel:', error);
    }
  };

  const handleRemoveFunnel = async (funnelId) => {
    const optimist = existingFunnels.find((x) => x.id === funnelId);

    try {
      const token = await getAccessTokenSilently();
      setExistingFunnels(existingFunnels.filter((f) => f.id !== funnelId));

      await api.delete(`/oagents/${oagentId}/funnel/${funnelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Optimistically update the state
      refreshFunnels();
      refreshDatapoints();
    } catch (error) {
      setExistingFunnels((prev) => {
        return [...prev, optimist];
      });
      console.error('Error removing funnel:', error);
    }
  };

  if (loading) {
    return (
      <DreamSideModal show={show} handleClose={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>Edit company sources</DreamModalInner.Header>
        <DreamModalInner.Body>
          {!showDataPoints ? (
            <ListList
              allFunnels={allFunnels}
              existingFunnels={existingFunnels}
              isFunnelInExisting={isFunnelInExisting}
              handleAddFunnel={handleAddFunnel}
              handleRemoveFunnel={handleRemoveFunnel}
            />
          ) : (
            <DatapointsList
              oagentId={oagentId}
              refreshDatapoints={debounce(refreshDatapoints, 500)}
            />
          )}
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          {!showDataPoints && (
            <div className='campaigns'>
              <Button variant='primary' onClick={() => setShowDataPoints(true)}>
                Edit datapoints <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          )}
          {showDataPoints && (
            <Button variant='primary' onClick={handleClose}>
              Save & Close
            </Button>
          )}
          {!showDataPoints && (
            <Button variant='outline-primary' onClick={handleClose}>
              Close
            </Button>
          )}
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default EditCompaniesModal;
