import React from 'react';
import { Button } from 'react-bootstrap';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as ManualGrid } from '../../icons/manual-grid.svg';

import './funnel.css';

function AddColumnModal({ onColumnTypeSelect, show, handleClose }) {
  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        <DreamModalInner.Header>Add column</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form add-column-modal'>
            <div className='choice-set'>
              <label>Column source</label>
              <div
                className='option'
                onClick={() => onColumnTypeSelect('research')}
              >
                <div className='red-icon icon-wrapper'>
                  <ResearchPointIcon />
                </div>
                <div className='card-right'>
                  <p className='label'>Add research</p>
                  <p>
                    Use a research agent to get information about your companies
                  </p>
                </div>
                <div className='plus-wrapper'>
                  <PlusIcon />
                </div>
              </div>
              <div
                className='option'
                onClick={() => onColumnTypeSelect('manual')}
              >
                <div className='icon-wrapper'>
                  <ManualGrid />
                </div>
                <div className='card-right'>
                  <p className='label'>Add content manually</p>
                  <p>Create a column you’ll edit yourself</p>
                </div>
                <div className='plus-wrapper'>
                  <PlusIcon />
                </div>
              </div>
            </div>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <div></div>
          <Button onClick={handleClose} variant='outline-primary'>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default AddColumnModal;
