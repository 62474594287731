import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './DreamModal.css';

let openModals = 0; // Counter to keep track of open modals

const DreamSideModal = ({
  show,
  handleClose,
  children,
  position = 'right',
  size = 'md',
  paired,
}) => {
  const [modalIndex, setModalIndex] = useState(0);

  useEffect(() => {
    if (show) {
      setModalIndex(openModals);
      openModals += 1;
      updateModalOverlays();
    }
    return () => {
      if (show) {
        openModals -= 1;
        updateModalOverlays();
      }
    };
  }, [show]);

  const updateModalOverlays = () => {
    const modals = Array.from(
      document.getElementsByClassName('ReactModal__Overlay'),
    );
    modals.forEach((modal, index) => {
      if (index < openModals - 1) {
        modal.classList.add('transparent-overlay');
      } else {
        modal.classList.remove('transparent-overlay');
      }
    });
  };

  const customStyles = {
    content: {
      position: 'fixed',
      zIndex: 1040 + modalIndex * 10,
      transition: 'transform 0.3s ease-out',
      minWidth: size === 'lg' ? '1152px' : '300px',
    },
  };

  switch (position) {
    case 'left':
      customStyles.content = {
        ...customStyles.content,
        left: `${32 + modalIndex * 32}px`,
        top: '32px',
        bottom: '32px',
        transform: show ? 'translateX(0%)' : 'translateX(-100%)',
      };
      break;
    case 'right':
      customStyles.content = {
        ...customStyles.content,
        right: `${32 + modalIndex * 32}px`,
        top: '32px',
        bottom: '32px',

        transform: show ? 'translateX(0%)' : 'translateX(100%)',
      };
      break;
    case 'top':
      customStyles.content = {
        ...customStyles.content,
        top: `${32 + modalIndex * 32}px`,
        left: '32px',
        right: '32px',
        transform: show ? 'translateY(0%)' : 'translateY(-100%)',
      };
      break;
    case 'bottom':
      customStyles.content = {
        ...customStyles.content,
        bottom: `${32 + modalIndex * 32}px`,
        left: '32px',
        right: '32px',
        transform: show ? 'translateY(0%)' : 'translateY(100%)',
      };
      break;
    default:
      break;
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel='Dream Side Modal'
      overlayClassName='dream-side-modal-overlay'
      className={`dream-side-modal ${size === 'lg' && 'large'} ${paired ? 'paired' : ''}`}
    >
      {children}
    </Modal>
  );
};

export default DreamSideModal;
