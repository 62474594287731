import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Subscription from './settings/Subscription';
import LinkedAccounts from './settings/LinkedAccounts';
import Integrations from './settings/Integrations';
import Team from './settings/Team';
import CompanySettings from './settings/CompanySettings'; // Import the new CompanySettings component
import { useAuth0 } from '@auth0/auth0-react';
import './settings/settings.css';
import api from '../api';
import YourDetails from './settings/YourDetails';
import WebTracking from './settings/WebTracking';

const Settings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [key, setKey] = useState('your-details');
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [canWriteIntegrations, setCanWriteIntegrations] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBillingDetailsVisibility = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/subscription-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShowBillingDetails(response.data.can_write_subscription);
      } catch (error) {
        console.error('Error fetching billing details visibility:', error);
      }
    };

    fetchBillingDetailsVisibility();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const segments = location.pathname.split('/');
    const currentTab = segments.pop();
    if (segments.length > 1) {
      setKey(currentTab);
    }
  }, [location.pathname]);

  const handleSelect = (k) => {
    if (k === 'billing-details') {
      window.open(process.env.REACT_APP_STRIPE_BILLING_URL, '_blank');
      return;
    }

    navigate(`/settings/${k}`);
    setKey(k);
  };

  useEffect(() => {
    const checkIntegrationPermission = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/knit-link/can-write-integrations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCanWriteIntegrations(response.data.permission);
      } catch (error) {
        console.error('Error checking integration permission:', error);
      }
    };

    checkIntegrationPermission();
  }, [getAccessTokenSilently]);

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper settings-page settings'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Settings</h3>
          </div>
        </div>
        <div className='settings-main'>
          <Tabs
            activeKey={key}
            onSelect={handleSelect}
            className='dream-left-tabs settings-tabs'
          >
            <Tab eventKey='your-details' title='Your details'>
              <YourDetails />
            </Tab>
            <Tab eventKey='subscription' title='Subscription'>
              <Subscription />
            </Tab>
            <Tab eventKey='account-links' title='Account Links'>
              <LinkedAccounts />
            </Tab>
            <Tab
              eventKey='billing-details'
              title='Billing Details'
              disabed={!showBillingDetails}
            ></Tab>
            <Tab
              eventKey='integrations'
              title='Integrations'
              disabled={!canWriteIntegrations}
            >
              <Integrations />
            </Tab>
            <Tab eventKey='team' title='Team'>
              <Team />
            </Tab>
            <Tab eventKey='web-tracking' title='Web Tracking'>
              <WebTracking />
            </Tab>

            <Tab eventKey='company-settings' title='Company Settings'>
              <CompanySettings />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Settings;
