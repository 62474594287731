import './add-new-row.css';

class AddNewRowEdit {
  constructor() {
    this.container = document.createElement('div');
    this.input = document.createElement('input');
    this.icon = document.createElement('img');
    this.input.type = 'text';
    this.input.placeholder = 'Type a company name or LinkedIn url...';
    this.icon.src = '/add-new-company.svg';
    this.icon.classList.add('add-new-company-icon'); // Assuming CSS styles for icon

    this.container.classList.add('add-new-row-editor');
    this.container.appendChild(this.icon);
    this.container.appendChild(this.input);
  }

  // Initialization method to set up editor
  init(params) {
    this.params = params;
    this.input.value = ''; // Set initial value
    this.input.addEventListener('change', this.onValueChange.bind(this));

    // Apple's auto-focus workaround, defer focus until component is in the DOM
    setTimeout(() => this.input.focus());
  }

  // Handle input value change
  onValueChange(event) {
    this.value = event.target.value;
  }

  // Get the GUI element for the editor
  getGui() {
    return this.container;
  }

  // Return the final value to the grid
  getValue() {
    return this.value !== undefined ? this.value : this.input.value;
  }

  // Called once when grid is ready to insert the element
  afterGuiAttached() {
    if (this.input) {
      this.input.focus();
    }
  }

  // Clean up the editor
  destroy() {
    // Remove event listener if needed
    this.input.removeEventListener('change', this.onValueChange.bind(this));
  }

  // Optionally specify if the editor is a popup
  isPopup() {
    return false;
  }
}

export default AddNewRowEdit;
