import React from 'react';
import '../campaign-new.css';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import FakePic from '../../feed/items/FakePic';

function MissingCompaniesModal({ missingCompanies, show, handleClose }) {
  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          Companies with no added prospects
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='missing-companies-modal-list'>
            {missingCompanies.map((company, index) => {
              return (
                <div className='company-entry' key={index}>
                  <FakePic url={company.logo_url} type='org' />
                  {company.name}
                </div>
              );
            })}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default MissingCompaniesModal;
