import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api';
import NoCampaignsView from './campaigns/NoCampaignsView';
import CreateCampaignModal from './campaigns/CreateCampaignModal';

import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as DropdownIcon } from '../icons/dropdown.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';
import CampaignOverviewStats from './campaigns/CampaignOverviewStats';

import ConfirmModal from './confirm-modals/ConfirmModal';

import './campaigns/campaigns.css';
import './campaigns/campaign-overview-stats.css';

const Campaigns = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [oagents, setOagents] = useState([]);
  const [campaignsLoading, setCampaignsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);

  const archiveOagent = (id) => setShowConfirmArchiveModal(id);
  const handleConfirmArchive = async () => {
    const idToArchive = showConfirmArchiveModal;
    const tmp = oagents.find((x) => x.id === idToArchive);

    try {
      setOagents((oagents) => {
        console.log(oagents, idToArchive);
        return oagents.filter((x) => x.id !== idToArchive);
      });
      setShowConfirmArchiveModal(false);
      const token = await getAccessTokenSilently();
      await api.delete(`/oagents/${idToArchive}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      if (tmp) {
        setOagents((oagents) => {
          return [...oagents, tmp];
        });
      }
      alert('Error archiving campaign, please try again');
      setShowConfirmArchiveModal(idToArchive);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCampaignsLoading(true);
        const token = await getAccessTokenSilently();

        // Fetch oagents
        const oagentsResponse = await api.get('/oagents', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const oagents = oagentsResponse.data;
        setOagents(oagents.filter((x) => !x.archived));
        setCampaignsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const editCampaignLink = (oagent) => {
    if (oagent.draft) {
      return `/campaign-wizard/${oagent.id}`;
    }
    return `/campaign/${oagent.id}`;
  };

  if (campaignsLoading) {
    return (
      <div className='page-wrapper'>
        <div className='page-inner-wrapper campaigns campaigns-index'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <h3>Campaigns</h3>
            </div>
          </div>
        </div>
        <div className='campaigns-index-lower-area'>
          <div className='campaign-overview-stats-outer-wrapper'>
            <div className='campaign-overview-stats'>
              <div className='fake-entry'></div>
              <div className='fake-entry'></div>
            </div>
          </div>
          <div className='campaigns-list'>
            <h4>Your campaigns</h4>
            <div className='center-spinner'>
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper campaigns campaigns-index'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Campaigns</h3>
          </div>
          <div className='buttons-area'>
            <Button variant='primary' onClick={() => setShowCreateModal(true)}>
              Create new campaign <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className='campaigns-index-lower-area'>
          {oagents.length > 0 && <CampaignOverviewStats />}
          {oagents.length === 0 && (
            <NoCampaignsView showCreateModal={() => setShowCreateModal(true)} />
          )}
          {oagents.length > 0 && (
            <div className='campaigns-list'>
              <h4>
                Your campaigns <span>({oagents.length})</span>
              </h4>
              <div className='pseudo-table'>
                <div className='header-row'>
                  <div className='cell campaign-name'>Campaign name</div>
                  <div className='remaining-cells'>
                    <div className='cell state'>State</div>
                    <div className='cell known-orgs'>Companies</div>
                    <div className='cell prospects'>Prospects</div>
                    <div className='cell messages-sent'>Messages Sent</div>
                    <div className='cell replies'>Replied</div>
                    <div className='cell calls-booked'>Booked</div>
                    <div className='cell action-dropdown'></div>
                  </div>
                </div>
                <div className='body'>
                  {oagents
                    .sort((a, b) => {
                      const aVal = a.autopilot ? 100 : a.draft ? 1 : 0;
                      const bVal = b.autopilot ? 100 : b.draft ? 1 : 0;

                      return aVal === bVal
                        ? a.name > b.name
                          ? 1
                          : -1
                        : bVal - aVal;
                    })
                    .map((oagent) => {
                      return (
                        <div className='body-row' key={oagent.id}>
                          <div className='cell campaign-name'>
                            <Link to={editCampaignLink(oagent)}>
                              {oagent.name}
                            </Link>
                          </div>
                          <div className='remaining-cells'>
                            <div className='cell state'>
                              {oagent.autopilot && (
                                <div className='tag positive'>Active</div>
                              )}
                              {oagent.draft && (
                                <div className='tag neutral'>Paused</div>
                              )}
                            </div>
                            <div className='cell known-orgs'>
                              {oagent?.dash_data?.total_orgs || '-'}
                            </div>
                            <div className='cell prospects'>
                              {oagent?.dash_data?.total_prospects || '-'}
                            </div>
                            <div className='cell messages-sent'>
                              {oagent?.dash_data?.messages_sent || '-'}
                            </div>
                            <div className='cell replies'>
                              {oagent?.dash_data?.replies || '-'}
                            </div>
                            <div className='cell calls-booked'>
                              {oagent?.dash_data?.booked || '-'}
                            </div>
                            <div className='cell action-dropdown'>
                              <Dropdown>
                                <Dropdown.Toggle as={Button} variant='link'>
                                  <DropdownIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => archiveOagent(oagent.id)}
                                  >
                                    <div className='red-icon'>
                                      <TrashIcon />
                                    </div>
                                    Archive
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
        <CreateCampaignModal
          show={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
        />
        {showConfirmArchiveModal && (
          <ConfirmModal
            show
            confirmLabel='Archive Campaign'
            bodyText={
              <>
                <p>Are you sure you want to archive this campaign?</p>
                <br />
                <p>
                  {' '}
                  Archiving will remove the campaign from view, pause it, and
                  reject any outstanding approvals but <b>will NOT</b>
                  &nbsp;prevent agents from replying.
                </p>
              </>
            }
            title='Confirm archive'
            handleClose={() => setShowConfirmArchiveModal(false)}
            handleConfirm={handleConfirmArchive}
          />
        )}
      </div>
    </div>
  );
};

export default Campaigns;
