import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as FollowUpIcon } from '../../icons/follow-up.svg';
import { ReactComponent as NewThreadIcon } from '../../icons/new-thread.svg';
import { ReactComponent as BookedIcon } from '../../icons/campaign-booked.svg';
import { ReactComponent as ReplyIcon } from '../../icons/campaign-reply.svg';
import { ReactComponent as BounceIcon } from '../../icons/bounce.svg';
import { ReactComponent as OOOIcon } from '../../icons/holiday.svg';
import { ReactComponent as UserRemovedIcon } from '../../icons/trash.svg';
import { ReactComponent as NotInterestedIcon } from '../../icons/stop.svg';

import './campaign-new.css';

const CampaignPosition = ({ sequenceState, oagentId }) => {
  if (!sequenceState) {
    return null;
  }

  const renderSequenceRow = (label, count, icon, type) => (
    <div className={`sequence-row ${type}`}>
      <span>
        {icon}
        {label}
      </span>
      <span>{count}</span>
    </div>
  );

  return (
    <div className='campaign-position'>
      <h6>Sequence progress</h6>
      <div className='header-row'>
        <span>Sequence</span>
        <span>Prospects at stage</span>
      </div>
      <div className='sequence-progress'>
        {renderSequenceRow(
          'Initial email',
          sequenceState.email_stages.find((stage) => stage.email_order === 0)
            ?.prospects.length || 0,
          <NewThreadIcon />,
        )}
        {sequenceState.email_stages
          .sort((a, b) => a.email_order - b.email_order)
          .map(
            (stage, index) =>
              stage.email_order > 0 &&
              renderSequenceRow(
                `Follow-up ${stage.email_order}`,
                stage.prospects.length,
                <FollowUpIcon />,
              ),
          )}
        {renderSequenceRow(
          'Finished',
          sequenceState.finished.length,
          <ReplyIcon />,
        )}
        {renderSequenceRow(
          'Reply',
          sequenceState.replied.length,
          <ReplyIcon />,
          'positive',
        )}
        {renderSequenceRow(
          'Booked',
          sequenceState.booked.length + sequenceState.success.length,
          <BookedIcon />,
          'positive',
        )}
        {renderSequenceRow(
          'Bounce',
          sequenceState.bounce.length,
          <BounceIcon />,
          'negative',
        )}
        {renderSequenceRow(
          'Out-of-office',
          sequenceState.ooo.length,
          <OOOIcon />,
          'negative',
        )}
        {renderSequenceRow(
          'No interest',
          sequenceState.not_interested.length,
          <NotInterestedIcon />,
          'negative',
        )}
        {renderSequenceRow(
          'User removed',
          sequenceState.user_removed.length,
          <UserRemovedIcon />,
          'negative',
        )}
      </div>
      <div className='footer-link'>
        <Link to={`/campaign/${oagentId}/messaging?view=edit`}>
          View & manage Sequence
        </Link>
      </div>
    </div>
  );
};

export default CampaignPosition;
