import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import JobsListModal from '../../misc/JobsListModal';

const JobData = ({ value, row }) => {
  const [showJobsListModal, setShowJobsListModal] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState([]);
  const [selectJobCompanyData, setSelectedJobCompanyData] = useState(null);

  const handleJobDataClick = (jobData, company) => {
    setSelectedJobData(jobData);
    setSelectedJobCompanyData(company);
    setShowJobsListModal(true);
  };

  let jobs = [];
  try {
    jobs = JSON.parse(value);
  } catch (e) {
    console.error(`Could not parse `, value);
  }

  return (
    <>
      <Button
        variant='link'
        className='tiny-link-button'
        onClick={() => handleJobDataClick(jobs, row)}
      >
        {`${jobs.length} ${jobs.length === 1 ? 'job' : 'jobs'} found`}
      </Button>

      {showJobsListModal && (
        <JobsListModal
          show={showJobsListModal}
          onHide={() => setShowJobsListModal(false)}
          jobs={selectedJobData}
          company={selectJobCompanyData}
        />
      )}
    </>
  );
};

export default JobData;
