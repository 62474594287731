import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as BulbIcon } from '../../../icons/bulb.svg';
import { ReactComponent as CheckCircleIcon } from '../../../icons/check-circle-filled.svg';

const EditModeResearchPoints = ({
  researchPoints,
  additionalDataPoints,
  activeResearchPoints,
  setActiveResearchPoints,
  activeAdditionalData,
  setActiveAdditionalData,
}) => {
  const handleToggleResearchPoint = (point) => {
    setActiveResearchPoints((prev) =>
      prev.some((p) => p.research_point_id === point.id)
        ? prev.filter((p) => p.research_point_id !== point.id)
        : [...prev, { ...point, research_point_id: point.id }],
    );
  };

  const handleToggleAdditionalData = (data) => {
    setActiveAdditionalData((prev) =>
      prev.some((d) => d.key === data.key)
        ? prev.filter((d) => d.key !== data.key)
        : [...prev, data],
    );
  };

  return (
    <div className='email-research-points'>
      <div className='header'>
        <h6>Edit Your Datapoint Selections</h6>
        <p>Select or deselect datapoints to modify their usage in emails.</p>
      </div>
      <div className='research-points-list'>
        {additionalDataPoints.map((adp) => {
          const isActive = activeAdditionalData.some((ad) => ad.key === adp);
          return (
            <Button
              key={adp}
              className={`research-point-button ${isActive ? 'active' : ''}`}
              onClick={() => handleToggleAdditionalData({ key: adp })}
            >
              {isActive ? <CheckCircleIcon /> : <BulbIcon />}
              {adp}
            </Button>
          );
        })}

        {researchPoints.map((rp) => {
          const isActive = activeResearchPoints.some(
            (arp) => arp.research_point_id === rp.id,
          );
          return (
            <Button
              key={rp.id}
              className={`research-point-button ${isActive ? 'active' : ''}`}
              onClick={() => handleToggleResearchPoint(rp)}
            >
              {isActive ? <CheckCircleIcon /> : <BulbIcon />}
              {rp.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default EditModeResearchPoints;
