import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';

import './funnel.css';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function AdColumnConfigModal({ show, handleClose, funnelId, handleAdd }) {
  const [submitting, setSubmitting] = useState(false);
  const [key, setKey] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  const handleCreate = async () => {
    try {
      setSubmitting(true);
      const token = await getAccessTokenSilently();
      await api.post(
        `/funnels/${funnelId}/additional-data-key`,
        {
          key,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      handleAdd();
      handleClose();
    } catch (e) {
      alert('Error adding column, please refresh and try again');
    } finally {
      setSubmitting(false);
    }
  };

  if (submitting) {
    return (
      <DreamSideModal show={show}>
        <DreamModalInner>
          <DreamSideModalLoader>Adding Column</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        <DreamModalInner.Header>Add content manually</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form ad-column-config-modal'>
            <Form.Group>
              <Form.Label>Column title</Form.Label>
              <Form.Text>What title do you want to give your column?</Form.Text>
              <Form.Control
                type='text'
                value={key}
                onChange={(e) => setKey(e.target.value)}
              />
            </Form.Group>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={handleCreate} disabled={!key}>
            Add column{' '}
            <div className='yellow-icon'>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </Button>
          <Button onClick={handleClose} variant='outline-primary'>
            Cancel
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default AdColumnConfigModal;
