import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import './conversation-opts.css';
function PauseConversationSection({
  pauseConversation,
  setPauseConversation,
  pauseConversationReason,
  setPauseConversationReason,
  showPaused,
  reactivate,
}) {
  if (showPaused) {
    return (
      <div className='disabled-pause-section'>
        <div>
          <Form.Label>Pause this conversation</Form.Label>
          <Form.Text>
            All sequence emails to this prospect will be stopped. We’ll notify
            you about any messages from your prospect, but won’t send any
            automatic messages.
          </Form.Text>
        </div>
        <div className='already-paused'>You have paused this conversation</div>
        <Button
          variant='outline-primary'
          className='reactivate-button'
          onClick={reactivate}
        >
          Activate this conversation
        </Button>
      </div>
    );
  }
  return (
    <div className='pause-conversation-group'>
      <Form.Group>
        <Form.Label>Pause this conversation</Form.Label>
        <Form.Text>
          All sequence emails to this prospect will be stopped. We’ll notify you
          about any messages from your prospect, but won’t send any automatic
          messages.
        </Form.Text>
        <Form.Check
          type='checkbox'
          label='Pause conversation'
          checked={pauseConversation}
          onChange={(e) => setPauseConversation(e.target.checked)}
        />
      </Form.Group>
      {pauseConversation && (
        <div className='pause-reason'>
          <Form.Label>Select a reason to pause</Form.Label>
          <Select
            classNames={{
              control: () => `big-react-select-control`,
            }}
            value={pauseConversationReason}
            options={[
              { label: 'Conversation was successful', value: 'success' },
              {
                label: 'I want to continue the conversation myself',
                value: 'continue',
              },
              { label: 'Other', value: 'other' },
            ]}
            onChange={(selected) => {
              setPauseConversationReason(selected);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PauseConversationSection;
