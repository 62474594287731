import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import CsvUploader from '../form-components/CsvUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';

function FurtherUploader({ funnelId, runFunnel, disabled }) {
  const [isCsvUploaderOpen, setIsCsvUploaderOpen] = useState(false);
  const [csvData, setCsvData] = useState({
    id: null,
    selectedColumns: [],
    type: '',
  });

  useEffect(() => {
    setCsvData({
      id: null,
      selectedColumns: [],
      type: '',
    });
  }, [funnelId]);

  const handleAdd = () => {
    runFunnel(null, null, csvData);
    setIsCsvUploaderOpen(false);
  };

  return (
    <div className='further-upload-wrapper'>
      <Button
        className='further-uploader-button'
        onClick={() => setIsCsvUploaderOpen(true)}
        disabled={disabled}
      >
        {disabled ? (
          <>
            <Spinner size='sm' /> Adding
          </>
        ) : (
          <>
            Add companies <FontAwesomeIcon icon={faPlus} />
          </>
        )}
      </Button>
      {isCsvUploaderOpen && (
        <DreamSideModal show handleClose={() => setIsCsvUploaderOpen(false)}>
          <DreamModalInner>
            <DreamModalInner.Header>Add more companies</DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='further-upload-text dream-side-modal-form'>
                <p className='main-description'>
                  You can add more companies from another CSV to this list using
                  the form below.
                </p>
                <CsvUploader onCsvDataChange={(data) => setCsvData(data)} />
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <Button onClick={() => handleAdd()} disabled={!csvData.id}>
                Upload companies{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => setIsCsvUploaderOpen(false)}
              >
                Cancel
              </Button>
            </DreamModalInner.Footer>
          </DreamModalInner>
        </DreamSideModal>
      )}
    </div>
  );
}

export default FurtherUploader;
