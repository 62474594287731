import React from 'react';
import { Button } from 'react-bootstrap';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import StageAdditionalOptions from '../StageAdditionalOptions';
import { ReactComponent as AgentFilterIcon } from '../../icons/agent-filter.svg';
import functionManifests from '../../utils/function-manifests';

const AgentFilterEditModal = ({
  show,
  onHide,
  editingFilterIndex,
  handleMetadataChange,
  companyFilters,
  editFilter,
}) => {
  console.log(editingFilterIndex, companyFilters);
  return (
    <DreamSideModal show={show} onHide={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header closeButton>
          <div className='funnel-icon-wrapper'>
            <AgentFilterIcon />
          </div>
          Edit Filter
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='add-agent-filter-modal'>
            <p className='top-description'>
              Provide additional filtering against specified criteria.
            </p>
            {editingFilterIndex !== null && (
              <StageAdditionalOptions
                manifest={functionManifests.company_filter}
                stage={companyFilters[editingFilterIndex]}
                handleMetadataChange={handleMetadataChange}
              />
            )}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button
            variant='primary'
            onClick={() => {
              if (editingFilterIndex !== null) {
                editFilter(editingFilterIndex, {
                  ...companyFilters[editingFilterIndex],
                });
              }
            }}
          >
            {editingFilterIndex !== null ? 'Save settings' : 'Add filter'}
          </Button>
          <Button variant='outline-primary' onClick={onHide}>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default AgentFilterEditModal;
