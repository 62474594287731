import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ProspectsSettings from './ProspectsSettings';
import ProspectsList from './ProspectsList';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';

import '../campaign-new.css';

function Prospects({ oagentId, advance, nextText, wizard }) {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [isInitialCheck, setIsInitialCheck] = useState(true);
  const [prospects, setProspects] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const [forceRunSummaryFetch, setForceRunSummaryFetch] = useState(false);

  const pusherChannel = usePusher();

  const assessSyncing = (data) => {
    const processingOrWaiting =
      data &&
      data.status_counts
        .filter(
          (item) => item.status === 'processing' || item.status === 'waiting',
        )
        .reduce((sum, item) => sum + parseInt(item.count, 10), 0);
    setIsSyncing(processingOrWaiting > 0);
  };

  const fetchDetails = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/oagents/${oagentId}/import-run-summary/prospects`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      assessSyncing(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  useEffect(() => {
    checkProspects();
    fetchDetails();

    if (!pusherChannel) return;
    const pusherCallback = (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'import_run_update') {
          assessSyncing(data.prospect_summary);
        }
      }
    };

    pusherChannel.bind('oagent_status_update', pusherCallback);

    // Cleanup on unmount
    return () => {
      pusherChannel.unbind('oagent_status_update', pusherCallback);
    };
  }, [oagentId, pusherChannel]);

  const checkProspects = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/prospects`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProspects(response.data);
    } catch (err) {
      console.error('Error fetching prospects:', err);
      setError('Error fetching prospects: ' + err.message);
    } finally {
      setIsInitialCheck(false);
    }
  };

  const handleAdvance = async () => {
    advance();
  };

  const syncProspects = async (force) => {
    try {
      // Optimistically update
      setIsSyncing(true);

      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/wizard-prospect-sync`,
        { force },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setForceRunSummaryFetch(!forceRunSummaryFetch);
    } catch (err) {
      console.error('Error syncing prospects:', err);
      setIsSyncing(false);
      setError('Error syncing prospects: ' + err.message);
    }
  };

  return (
    <div className='prospect-step-wrapper'>
      <div className='left-side'>
        {error && <Alert variant='danger'>{error}</Alert>}
        <ProspectsSettings
          oagentId={oagentId}
          wizard={wizard}
          nextFn={handleAdvance}
          exitWithoutSaving={() => {}}
          setCanSearch={setCanSearch}
        />

        {canSearch && (
          <div className='big-sync-button-area'>
            <Button
              className={`big-sync-button ${isSyncing && 'syncing'}`}
              onClick={() => syncProspects(false)}
              disabled={isSyncing}
            >
              {isSyncing && (
                <>
                  <Spinner /> Finding prospects
                </>
              )}
              {!isSyncing && <>Find prospects</>}
            </Button>
          </div>
        )}
      </div>
      <div className='right-side'>
        <ProspectsList
          embedded
          oagentId={oagentId}
          nextFn={handleAdvance}
          nextText={nextText}
          openSettings={() => {}}
          prospects={prospects}
          setProspects={setProspects}
          isInitialCheck={isInitialCheck}
          canSearch={canSearch}
          forceRunSummaryFetch={forceRunSummaryFetch}
        />
      </div>
    </div>
  );
}

export default Prospects;
