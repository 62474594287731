import React, { useState, useEffect } from 'react';
import { Spinner, Alert, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

import { ReactComponent as TickIcon } from '../../icons/check-circle.svg';

const WebTracking = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [tagId, setTagId] = useState(null);
  const [hasDomains, setHasDomains] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [domainChecking, setDomainChecking] = useState(false);
  const [copied, setCopied] = useState(false);

  const fetchTagId = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/tracking-check/tag-id', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTagId(response.data.tag_id);
    } catch (error) {
      console.error('Error fetching tag ID:', error);
      throw error;
    }
  };

  const checkForDomains = async () => {
    try {
      setDomainChecking(true);
      const token = await getAccessTokenSilently();
      const response = await api.get('/tracking-check/check-domain', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setHasDomains(response.data.domain);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setHasDomains(false);
      } else {
        console.error('Error checking domain:', error);
        throw error;
      }
    } finally {
      setDomainChecking(false);
    }
  };

  const copyToClipboard = async () => {
    const code = `<script async src="${process.env.REACT_APP_TRACKING_FILE}?id=${tagId}"></script>`;
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      setFetchError(null);

      try {
        await Promise.all([fetchTagId(), checkForDomains()]);
      } catch (error) {
        setFetchError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className='team-loading'>
        <Spinner />
      </div>
    );
  }

  if (fetchError) {
    return (
      <Alert variant='danger'>
        <b>Error:</b> {fetchError}
      </Alert>
    );
  }

  return (
    <div className='section web-tracking-settings'>
      <div className='heading'>
        <h5>Web Tracking</h5>
        <p>Setup web tracking and get insights into your prospect lifecycle</p>
      </div>
      <div className='tracking-wrapper'>
        <label>Tracking status:</label>
        {!hasDomains ? (
          <div className='track-result'>
            <div className='negative dot'></div>
            <span>Web tracking is not enabled</span>
          </div>
        ) : (
          <div className='track-result'>
            <div className='positive dot'></div>
            <span>Your tracking is live for {hasDomains}</span>
          </div>
        )}
        <div className='button-wrapper'>
          <Button onClick={checkForDomains} variant='outline-primary'>
            {domainChecking ? <Spinner size='sm' /> : 'Check status'}
          </Button>
        </div>
      </div>

      <div className='how-does-it-work-wrapper'>
        <h5>How does it work?</h5>
        <div className='boxes'>
          <div className='box'>
            <span>1</span>
            <h6>Add it to your website</h6>
            <p>Add the tracking tag code below onto your site.</p>
          </div>
          <div className='box'>
            <span>2</span>
            <h6>Create your campaign</h6>
            <p>
              Include links to your website in your emails. Tracking will begin.
            </p>
          </div>
          <div className='box'>
            <span>3</span>
            <h6>Track warm leads</h6>
            <p>
              Whenever a prospect visits your site after clicking the link,
              we'll let you know.
            </p>
          </div>
        </div>
      </div>

      <div className='tracking-tag-info'>
        <div className='heading'>
          <h6>Your tracking tag</h6>
          <p>
            Add this code to the Head portion of your website. Ensure this loads
            on all pages you want to track entries on.
          </p>
        </div>
        <div className='code-area'>
          <pre>
            &lt;script async src="
            {`http://usedream.ai/tracking.js?id=${tagId}`}"&gt;&lt;/script&gt;
          </pre>
          <Button variant='outline-primary' onClick={copyToClipboard}>
            {copied ? (
              <>
                <TickIcon /> Copied
              </>
            ) : (
              'Copy tag'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WebTracking;
