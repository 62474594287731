import React from 'react';

import { ReactComponent as AddNewCompanyIcon } from './../../../icons/add-new-company.svg';

import './add-new-row.css';

function AddNewRow(props) {
  return (
    <div className='add-new-row'>
      <AddNewCompanyIcon />
      Add a company
    </div>
  );
}

export default AddNewRow;
