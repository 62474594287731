import React, { useEffect, useState } from 'react';
import { Spinner, Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import { useAuth0 } from '@auth0/auth0-react';

function joinWithLimit(array, limit, MoreComponent) {
  if (array.length <= limit) {
    return array.join(', ');
  } else {
    const limitedArray = array.slice(0, limit);
    return (
      <>
        <span>{limitedArray.join(', ')}</span>
        <span>
          <MoreComponent>+ {array.length - limit} more...</MoreComponent>
        </span>
      </>
    );
  }
}

/*
  Commented out sections essentially let you test your settings but this doesn't make sense really given the various changes that have been made to the application.

  Keeping it in in case I change my mind.
*/

function TestProspectSearch({
  show,
  handleClose,
  metadata,
  type,
  oagentId,
  refreshProspectSearch,
  editMode = false,
  prospectSearchId = null,
}) {
  const { getAccessTokenSilently } = useAuth0();
  // const [knownOrgs, setKnownOrgs] = useState([]);
  // const [selectedKnownOrg, setSelectedKnownOrg] = useState(null);
  const [jobTitles, setJobTitles] = useState([]);
  const [loadingTitles, setLoadingTitles] = useState(true);
  // const [loadingOrgs, setLoadingOrgs] = useState(true);
  // const [prospects, setProspects] = useState([]);
  // const [loadingProspects, setLoadingProspects] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  // const [noResults, setNoResults] = useState(false);
  const [error, setError] = useState(null);
  const [showAllTitles, setShowAllTitles] = useState(false);
  const pusherChannel = usePusher();

  useEffect(() => {
    // fetchKnownOrgs();

    if (!metadata.agent_intuition) {
      // Just submit straight away
      fetchJobTitles(handleSaveSettings);
    } else {
      fetchJobTitles();
    }
  }, [oagentId, metadata]);

  // const fetchKnownOrgs = async () => {
  //   // setLoadingOrgs(true);
  //   try {
  //     const token = await getAccessTokenSilently();
  //     const response = await api.get(
  //       `/oagents/${oagentId}/possible-known-orgs`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     );
  //     // setKnownOrgs(response.data);
  //   } catch (error) {
  //     console.error('Error fetching known orgs:', error);
  //   } finally {
  //     setLoadingOrgs(false);
  //   }
  // };

  const fetchJobTitles = async (callback) => {
    setLoadingTitles(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/prospect-search-test/alternate-job-titles',
        {
          metadata,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setJobTitles(response.data);

      // We do this when there are no agent intuition
      // settings required
      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      console.error('Error fetching job titles:', error);
    } finally {
      setLoadingTitles(false);
    }
  };

  // const handleProspectSearch = async () => {
  //   const endpoint = '/prospect-search-test/job-title-prospect-search';
  //   setLoadingProspects(true);
  //   try {
  //     const token = await getAccessTokenSilently();
  //     const response = await api.post(
  //       endpoint,
  //       {
  //         known_org_id: selectedKnownOrg.value,
  //         metadata: {
  //           ...metadata,
  //           job_titles: jobTitles, // We want the job titles we retrieved from the backend
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     );

  //     const searchId = response.data.search_id;

  //     // Listen for the Pusher event
  //     pusherChannel.bind(`prospect_search_test_update`, (data) => {
  //       if (data.search_id === searchId) {
  //         if (data.error) {
  //           setError(data.error);
  //           setLoadingProspects(false);
  //         } else {
  //           setProspects(data.results);
  //           setLoadingProspects(false);
  //           if (data.results.length === 0) {
  //             setNoResults(true);
  //           } else {
  //             setNoResults(false);
  //           }
  //         }
  //       }
  //     });

  //     // Clean up the Pusher event bindings when the component unmounts
  //     return () => {
  //       pusherChannel.unbind(`prospect_search_test_update`);
  //     };
  //   } catch (error) {
  //     console.error('Error starting prospect search:', error);
  //     setLoadingProspects(false);
  //   }
  // };

  const handleSaveSettings = async (givenTitles) => {
    setSavingSettings(true);
    const httpMethod = editMode && prospectSearchId ? 'put' : 'post';

    try {
      const token = await getAccessTokenSilently();
      const endpoint =
        editMode && prospectSearchId
          ? `/oagents/${oagentId}/prospect-search/${prospectSearchId}`
          : `/oagents/${oagentId}/prospect-search`;

      await api[httpMethod](
        endpoint,
        {
          type,
          metadata: {
            ...metadata,
            job_titles: givenTitles || jobTitles,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      refreshProspectSearch();

      setSavingSettings(false);
      handleClose();
    } catch (error) {
      console.error(
        `Error saving prospect search (${httpMethod.toUpperCase()}):`,
        error,
      );
      setSavingSettings(false);
      setError('Failed to save settings. Please try again.');
    }
  };

  if (savingSettings || loadingTitles) {
    return (
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {loadingTitles ? 'Loading Job Titles' : 'Saving Settings'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} onHide={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>Test Prospect Search</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='test-prospect-search dream-side-modal-form'>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className='job-titles-section'>
              <Form.Label>
                Job titles your agent will search based on your guidance
              </Form.Label>
              <div className='job-titles'>
                <>
                  {showAllTitles ? (
                    <div>
                      {jobTitles.join(', ')}
                      <span
                        className='more-link'
                        onClick={() => setShowAllTitles(false)}
                      >
                        Show Less
                      </span>
                    </div>
                  ) : (
                    <div>
                      {joinWithLimit(jobTitles, 40, ({ children }) => (
                        <span
                          className='more-link'
                          onClick={() => setShowAllTitles(true)}
                        >
                          {children}
                        </span>
                      ))}
                    </div>
                  )}
                </>
              </div>
            </div>
            {/* <div className='known-orgs-section'>
              <Form.Label>Test your settings (optional)</Form.Label>
              <Form.Text>
                Select a company from your list to see what prospects would be
                returned
              </Form.Text>
              {loadingOrgs ? (
                <div className='loading'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <div className='test-company-wrapper'>
                  <Select
                    value={selectedKnownOrg}
                    onChange={setSelectedKnownOrg}
                    options={knownOrgs.map((org) => ({
                      value: org.id,
                      label: org.name,
                    }))}
                    isDisabled={loadingOrgs}
                    classNames={{
                      control: () => `big-react-select`,
                    }}
                  />
                  <Button
                    variant='outline-primary'
                    onClick={handleProspectSearch}
                  >
                    Run test
                  </Button>
                </div>
              )}
            </div> */}
            {/* 
            {noResults && (
              <div className='empty-state'>
                <h6>No results found</h6>
              </div>
            )} */}

            {/* {prospects.length > 0 && !noResults && (
              <div className='prospect-test-area'>
                <label>Returned prospects</label>
                <div className='prospects'>
                  {prospects.map((prospect, index) => (
                    <div key={index} className='prospect-wrapper'>
                      <div className='icon-area'>
                        <a
                          href={prospect.linkedin_url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                      <div className='main-text-area'>
                        <p>
                          {prospect.name} - <strong>{prospect.title}</strong>
                        </p>
                        {prospect.reason && (
                          <p className='reason'>{prospect.reason}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button
            variant='primary'
            onClick={() => handleSaveSettings()}
            disabled={loadingTitles}
          >
            Save Settings
          </Button>
          <Button variant='outline-primary' onClick={handleClose}>
            Change Settings
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default TestProspectSearch;
