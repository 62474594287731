import React, { memo, useEffect } from 'react';

function TagsCell(props) {
  const { tags } = props;

  if (!tags || tags.length === 0) {
    return <></>;
  }
  return (
    <div className='searched-orgs-tag-wrapper'>
      {tags.map((tag) => (
        <div className='tag' key={tag}>
          {tag}
        </div>
      ))}
    </div>
  );
}

export default memo(TagsCell);
