import React, { useState } from 'react';

const DiscoveryContext = ({ value }) => {
  let data = {};
  try {
    data = JSON.parse(value);
  } catch (e) {
    // No action
  }
  return <span>{data.reason || ''}</span>;
};

export default DiscoveryContext;
