import React, { useEffect, useState } from 'react';
import { Alert, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { usePusher } from '../../contexts/PusherContext';
import api from '../../api';
import './outbound-queue-banner.css';

import { ReactComponent as EmailSendIcon } from '../../icons/email-send.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as ContactCardIcon } from '../../icons/contact-card.svg';
import { ReactComponent as StopIcon } from '../../icons/stop.svg';
import { ReactComponent as BookedIcon } from '../../icons/booked.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const OutboundQueueBanner = ({ oagentId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [statusData, setStatusData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [countdown, setCountdown] = useState(null);

  const fetchCampaignStatus = async () => {
    setFetchError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/campaign-status/${oagentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStatusData(response.data);
    } catch (error) {
      setFetchError(error.message || 'Error fetching data');
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignStatus();
  }, [oagentId]);

  useEffect(() => {
    if (!pusherChannel || !oagentId) return;

    const handleStatusUpdate = (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        fetchCampaignStatus();
      }
    };

    pusherChannel.bind('oagent_status_update', handleStatusUpdate);

    return () => {
      if (pusherChannel) {
        pusherChannel.unbind('oagent_status_update', handleStatusUpdate);
      }
    };
  }, [oagentId, pusherChannel]);

  const getCountdownTimer = (time) => {
    if (!time) return null;

    const now = new Date();
    const diff = new Date(time) - now;
    if (diff <= 0) return 'now';

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    let formattedDuration = '';
    if (days > 0) {
      formattedDuration += `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else if (hours > 0) {
      formattedDuration += `${hours}h ${minutes}m ${seconds}s`;
    } else {
      formattedDuration += `${minutes}m ${seconds}s`;
    }

    return formattedDuration;
  };

  useEffect(() => {
    let intervalId;
    if (statusData && statusData.nextSend) {
      intervalId = setInterval(() => {
        setCountdown(getCountdownTimer(statusData.nextSend));
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [statusData]);

  if (fetchError) {
    return (
      <div className='outbound-queue-banner'>
        <Alert variant='danger'>
          <div className='left'>
            <span>Error loading scheduled emails: {fetchError}</span>
          </div>
        </Alert>
      </div>
    );
  }

  if (!statusData || initialLoading) {
    return <div className='fake-banner-box'></div>;
  }

  const {
    prospects,
    emailSetupActive,
    scheduledEmailCount,
    rejectedEmailCount,
    approvedEmails,
    isAutoApproval,
    delayedSendingCount,
    sentEmailCount,
    sendingActive,
    hasFutureToWrite,
    emailsExhausted,
    meetingsBooked,
    prospectSourcingActive,
  } = statusData;

  const prospectsWithEmail = prospects.filter((x) => x.email).length;
  const prospectsTotal = prospects.length;
  const showSetupBox = emailSetupActive || scheduledEmailCount > 0;
  const showSendingBox = approvedEmails > 0;
  const showApprovalBox = scheduledEmailCount > 0;
  const approvalBoxInWarning =
    approvedEmails < scheduledEmailCount - rejectedEmailCount &&
    !isAutoApproval;

  const writingBoxShowWaiting =
    !emailSetupActive && hasFutureToWrite && showSetupBox;

  return (
    <div className='campaign-progress'>
      <div
        className={`banner-box ${prospectSourcingActive ? 'active' : 'done'}`}
      >
        <div className='active-tag'>
          {prospectSourcingActive && <Spinner />}
          {!prospectSourcingActive && <div className='done-tag'>Done</div>}
        </div>
        <div className='main-body'>
          <h6>Sourcing prospects</h6>
          <p>
            Found {prospectsWithEmail}/{prospectsTotal} emails
          </p>
        </div>
        <div className='count'>
          {prospectsWithEmail} <ContactCardIcon />
        </div>
      </div>
      <div
        className={`banner-box ${showSetupBox ? (emailSetupActive ? 'active' : 'done') : 'inactive'}
          ${writingBoxShowWaiting ? 'warning' : ''}
          `}
      >
        <div className='active-tag'>
          {!showSetupBox && <EditIcon />}
          {showSetupBox && (
            <>
              {emailSetupActive && <Spinner />}
              {!emailSetupActive && showSetupBox && !hasFutureToWrite && (
                <div className='done-tag'>Done</div>
              )}
              {hasFutureToWrite && !emailSetupActive && showSetupBox ? (
                <div className='waiting-tag'>Waiting</div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div className='main-body'>
          <h6>Writing emails</h6>
          <p>
            {showSetupBox && (
              <>
                {' '}
                {emailSetupActive ? (
                  'Generating messages'
                ) : hasFutureToWrite ? (
                  'Waiting before follow-ups'
                ) : (
                  <Link to={`/campaign/${oagentId}/messaging`}>
                    View your generated emails
                  </Link>
                )}
              </>
            )}
            {!showSetupBox && <>Turn on autopilot to start writing</>}
          </p>
        </div>
        <div className='count'>
          {!writingBoxShowWaiting && (
            <>
              {showSetupBox ? (
                scheduledEmailCount > prospectsWithEmail ? (
                  scheduledEmailCount
                ) : (
                  `${scheduledEmailCount}/${prospectsWithEmail}`
                )
              ) : (
                <ClockIcon />
              )}
            </>
          )}
          {writingBoxShowWaiting ? <ClockIcon /> : <></>}
        </div>
      </div>
      <div
        className={`banner-box ${showApprovalBox ? (approvedEmails < scheduledEmailCount - rejectedEmailCount && isAutoApproval ? 'active' : 'done') : 'inactive'} ${approvalBoxInWarning ? 'warning' : ''}`}
      >
        <div className='active-tag'>
          {!showApprovalBox && <EmailIcon />}
          {showApprovalBox && (
            <>
              {approvedEmails < scheduledEmailCount - rejectedEmailCount &&
                isAutoApproval && <Spinner />}
              {approvalBoxInWarning && (
                <div className='waiting-tag'>Waiting</div>
              )}
              {approvedEmails === scheduledEmailCount - rejectedEmailCount &&
                showApprovalBox && <div className='done-tag'>Done</div>}
            </>
          )}
        </div>
        <div className='main-body'>
          <h6>Approving emails</h6>
          {showApprovalBox && (
            <>
              {approvalBoxInWarning && <p>You have emails to approve.</p>}
              {approvedEmails < scheduledEmailCount - rejectedEmailCount &&
                isAutoApproval && <p>Checking email content</p>}
              {approvedEmails === scheduledEmailCount - rejectedEmailCount && (
                <p>All emails handled</p>
              )}
            </>
          )}
          {!showApprovalBox && <p>Turn on autopilot for emails</p>}
        </div>
        <div className='count'>
          {showApprovalBox ? (
            `${approvedEmails}/${scheduledEmailCount - rejectedEmailCount}`
          ) : (
            <ClockIcon />
          )}
          {showApprovalBox && (
            <span className='red'>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id={`tooltip-${oagentId}-rejected-email`}>
                    Total rejected emails
                  </Tooltip>
                }
              >
                <span>({rejectedEmailCount})</span>
              </OverlayTrigger>
            </span>
          )}
        </div>
      </div>
      <div
        className={`banner-box ${showSendingBox ? (sendingActive && sentEmailCount !== approvedEmails ? 'active' : 'done') : 'inactive'} ${delayedSendingCount > 0 && sentEmailCount !== approvedEmails ? 'warning' : ''}`}
      >
        <div className='active-tag'>
          {!showSendingBox && <EmailSendIcon />}
          {showSendingBox && (
            <>
              {sentEmailCount === approvedEmails &&
                showSendingBox &&
                !sendingActive &&
                (delayedSendingCount === 0 ||
                  sentEmailCount === approvedEmails) && (
                  <div className='done-tag'>Done</div>
                )}
              {delayedSendingCount > 0 &&
                !sendingActive &&
                sentEmailCount !== approvedEmails && (
                  <div className='waiting-tag'>Waiting</div>
                )}
              {sendingActive && sentEmailCount !== approvedEmails && (
                <Spinner />
              )}
            </>
          )}
        </div>
        <div className='main-body'>
          <h6>Sending emails</h6>
          {sendingActive && <p>Sending scheduled mail</p>}
          {delayedSendingCount > 0 &&
            !sendingActive &&
            sentEmailCount !== approvedEmails && (
              <p>
                Next send in {countdown}{' '}
                {countdown &&
                  countdown !== 'now' &&
                  countdown.split(' ')[1] !== 'seconds' && (
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-${oagentId}`}>
                          If longer than expected, your senders may have reached
                          their daily limits and or be outside your selected
                          hours.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </OverlayTrigger>
                  )}
              </p>
            )}
          {sentEmailCount === approvedEmails &&
            showSendingBox &&
            !sendingActive && <p>All approved emails sent</p>}
          {!showSendingBox && <p>Turn on autopilot for email</p>}
        </div>
        <div className='count'>
          {showSendingBox ? (
            `${sentEmailCount}/${approvedEmails}`
          ) : (
            <ClockIcon />
          )}
        </div>
      </div>
      <div
        className={`banner-box ${sentEmailCount > 0 ? 'done' : 'inactive'} ${emailsExhausted && sentEmailCount !== 0 ? 'danger' : ''}`}
      >
        <div className='active-tag'>
          {emailsExhausted && sentEmailCount !== 0 && <StopIcon />}
          {(!emailsExhausted || sentEmailCount === 0) && <BookedIcon />}
        </div>
        <div className='main-body'>
          <h6>Meetings booked</h6>
          {sentEmailCount > 0 && !emailsExhausted && <p>Agents ready</p>}
          {sentEmailCount === 0 && <p>Agents ready</p>}
          {sentEmailCount !== 0 && emailsExhausted && <p>Add more prospects</p>}
        </div>
        <div className='count'>
          {sentEmailCount > 0 ? meetingsBooked : <ClockIcon />}
        </div>
      </div>
    </div>
  );
};

export default OutboundQueueBanner;
