import React, { useEffect, useState } from 'react';
import './campaign-new.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

import { ReactComponent as ProspectIcon } from '../../icons/prospect.svg';
import { ReactComponent as OrgFakeIcon } from '../../icons/org-fake.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../icons/speech-bubble.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';

import TaskList from '../tasks/TaskList';
import Inbox from './Inbox';
import { usePusher } from '../../contexts/PusherContext';
import OutboundQueueBanner from './OutboundQueueBanner';
import Burndown from './Burndown';
import CampaignPosition from './CampaignPosition.js';
import NotFoundPage from '../misc/NotFoundPage.js';

function getCurrentTimeWithTimezone() {
  const now = new Date();

  // Manually construct the date and time in YYYY-MM-DD HH:mm:ss format
  const formattedDateTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

  // Get the timezone string (e.g., Europe/London)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    dateTime: formattedDateTime,
    timezone: timeZone,
  };
}

function Campaign() {
  const { id } = useParams(); // Extract step from URL parameters
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [oagent, setOagent] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialBurndownLoading, setInitialBurndownLoading] = useState(true);
  const [initialCampaignPositionLoading, setInitialCampaignPositionLoading] =
    useState(true);
  const [autopilotLoading, setAutopilotLoading] = useState(false);
  const [burndown, setBurndown] = useState(null);
  const [sequenceState, setSequenceState] = useState(null);
  const [campaignNotFound, setCampaignNotFound] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setCampaignNotFound(false);
  }, [id]);

  const playOrPause = async () => {
    try {
      setAutopilotLoading(true);
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${id}/set-autopilot/${oagent?.autopilot ? 'off' : 'on'}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setOagent((oagent) => ({
        ...oagent,
        autopilot: !oagent?.autopilot,
      }));
    } catch (e) {
      console.error(`Unable to update autopilot`, e);
    } finally {
      setAutopilotLoading(false);
    }
  };

  const fetchBurndown = async () => {
    try {
      const { dateTime, timezone } = getCurrentTimeWithTimezone();
      const token = await getAccessTokenSilently();
      const response = await api.post(
        `/campaign-burndown/${id}`,
        { dateTime, timezone },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setBurndown(response.data);
    } catch (error) {
      console.error('Error fetching burndown');
    } finally {
      setInitialBurndownLoading(false);
    }
  };

  const fetchOagent = async (isInitialLoading = false) => {
    try {
      if (isInitialLoading) setInitialLoading(true);
      setCampaignNotFound(false);

      const token = await getAccessTokenSilently();

      // Fetch oagent details
      const [oagentResponse] = await Promise.all([
        api.get(`/oagents/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const oagentData = oagentResponse.data;

      setOagent(oagentData);

      if (oagentData.draft) {
        navigate('/campaign-wizard/' + oagentData.id);
      }

      if (isInitialLoading) setInitialLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        setCampaignNotFound(true);
      }

      console.error('Error fetching oagent or campaign stats:', error);
      if (isInitialLoading) setInitialLoading(false);
    }
  };

  const fetchSequenceState = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/campaign-status/${id}/campaign-position`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSequenceState(response.data);
    } catch (error) {
      console.error('Error fetching campaign position');
    } finally {
      setInitialCampaignPositionLoading(false);
    }
  };

  useEffect(() => {
    if (!pusherChannel || !id) return;
    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(id, 10)) {
        fetchOagent();
        fetchBurndown();
        fetchSequenceState();
      }
    });

    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [id, pusherChannel, id]);

  useEffect(() => {
    fetchOagent(true);
    fetchBurndown();
    fetchSequenceState();
  }, [id, getAccessTokenSilently]);

  if (campaignNotFound) {
    return (
      <div className='page-wrapper'>
        <NotFoundPage />
      </div>
    );
  }
  return (
    <div className='page-wrapper'>
      <div
        className={`page-inner-wrapper campaign-view ${initialLoading ? 'first-loading' : ''}`}
      >
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/campaigns'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to All Campaigns
                </Link>
              </div>
            </div>
            <h3>
              <b>Campaign:</b> {oagent?.name}
            </h3>
          </div>
          <div className='buttons-area autopilot'>
            <Button
              variant='primary'
              onClick={playOrPause}
              disabled={autopilotLoading}
            >
              {!autopilotLoading && (
                <>
                  {!oagent?.autopilot ? (
                    <>
                      Start Campaign <FontAwesomeIcon icon={faPlay} />
                    </>
                  ) : (
                    <>
                      Pause Campaign <FontAwesomeIcon icon={faPause} />
                    </>
                  )}
                </>
              )}
              {autopilotLoading && (
                <>
                  {oagent?.autopilot ? 'Disabling' : 'Enabling'}{' '}
                  <Spinner size='sm' />
                </>
              )}
            </Button>
          </div>
        </div>

        <div className='campaign-view-lower-area'>
          <div className='outbound-banner'>
            <OutboundQueueBanner
              oagentId={id}
              autopilotStatus={oagent?.autopilot}
            />
          </div>
          <div className='actions-row'>
            <div className='buttons-left'>
              <Link to={`/campaign-companies/${id}`}>
                <Button variant='outline-primary'>
                  <OrgFakeIcon />
                  Companies
                </Button>
              </Link>
              <Link to={`/campaign-prospects/${id}`}>
                <Button variant='outline-primary'>
                  <ProspectIcon />
                  Prospects
                </Button>
              </Link>
              <Link to={`/campaign/${id}/messaging`}>
                <Button variant='outline-primary' onClick={() => navigate()}>
                  <SpeechBubbleIcon />
                  Messaging
                </Button>
              </Link>
            </div>
            <div className='settings-right'>
              <Link to={`/campaign-settings/${id}`}>
                <div>
                  <SettingsIcon />
                  Settings
                </div>
              </Link>
            </div>
          </div>

          <div className='main-area'>
            <div className='left-side-wrapper'>
              <div
                className={`burndown-wrapper ${initialBurndownLoading ? 'loading' : ''}`}
              >
                <h4>Upcoming message sends</h4>
                <div className='key'>
                  <div className='key-item initial'>
                    <div className='bauble'></div>
                    <p>Initial email</p>
                  </div>
                  <div className='key-item follow-up'>
                    <div className='bauble'></div>
                    <p>Follow up</p>
                  </div>
                  <div className='key-item unapproved_user'>
                    <div className='bauble'></div>
                    <p>Needs your approval</p>
                  </div>
                  <div className='key-item unapproved_chain'>
                    <div className='bauble'></div>
                    <p>Follow-ups to unapproved</p>
                  </div>
                </div>
                <Burndown burndown={burndown} />
              </div>
              <Inbox campaignId={id} />
            </div>
            <div className='camp-right-side-wrapper'>
              <div className='task-list-wrapper'>
                <TaskList campaignId={id} />
              </div>
              <div className='campaign-position-wrapper'>
                <CampaignPosition sequenceState={sequenceState} oagentId={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaign;
