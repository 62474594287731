const predefinedResearchPoints = [
  {
    name: 'Search Google for information',
    type: 'datapoint',
    prefill: {
      name: ({ title }) => `${title}`,
      definition: ({ what_to_search_for }) =>
        `Search Google to find out ${what_to_search_for}`,
      example: ({ example }) => `${example}`,
    },
    additional_options: {
      title: {
        input_type: 'text',
        description: 'Title for your research',
        long_description: 'For example Opening Hours, Cultural Values etc',
      },
      what_to_search_for: {
        input_type: 'text',
        description: 'What to search Google for',
        long_description: 'The information that you would like to find out',
      },
    },
    sources: ['google', 'openai'],
  },
  {
    name: 'Find a social media username',
    type: 'datapoint',
    prefill: {
      name: ({ site }) => `${site} handle`,
      definition: ({ site }) => `What is their username on ${site}`,
      example: 'username',
    },
    additional_options: {
      site: {
        input_type: 'text',
        description: 'Which social media site?',
        long_description: 'e.g. Instagram, Twitter etc',
      },
    },
    sources: ['google'],
  },
  {
    name: 'Name of person with {job title}',
    type: 'datapoint',
    prefill: {
      name: ({ job_title }) => `${job_title} Name`,
      definition: ({ job_title }) => `What is the name of the ${job_title}`,
      example: 'Jane Smith',
    },
    additional_options: {
      job_title: {
        input_type: 'text',
        description: `The job title you're looking for`,
        long_description: `For example CEO, CFO, Chairman, CTO`,
      },
    },
    sources: ['linkedin', 'google', 'openai'],
  },
];

export { predefinedResearchPoints };
