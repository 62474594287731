import React, { memo } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as SoftProcessingIcon } from '../../../icons/soft-processing.svg';
import { ReactComponent as NotProcessingIcon } from '../../../icons/not-processing.svg';

function Processing({ id, statusObject, prioritized }) {
  const politeProgress = {
    SEARCH_TERMS_GENERATED: 'Search terms generated, now reading articles.',
    ARTICLES_READ: 'Articles read, considering',
    WRAPPING_UP: 'Updating database',
  };

  const detailsObject = statusObject?.jobDetails[0];
  const progress = detailsObject ? detailsObject?.progress?.step : false;
  let progressExplanation = prioritized
    ? 'Research queued for processing'
    : progress
      ? politeProgress[progress]
      : 'Researching';

  if (!progressExplanation) progressExplanation = progress;

  return (
    <OverlayTrigger
      placement='top'
      trigger={['hover', 'focus']}
      overlay={<Tooltip id={`tooltip-${id}`}>{progressExplanation}</Tooltip>}
    >
      <span className={prioritized ? 'prioritized' : ''}>
        <div className={`soft-processing-frame`}>
          {prioritized ? <NotProcessingIcon /> : <SoftProcessingIcon />}
        </div>
      </span>
    </OverlayTrigger>
  );
}

export default memo(Processing);
