import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import TimezoneSelect from 'react-timezone-select';
import CreatableSelect from 'react-select/creatable';

import api from '../../api'; // Ensure this path is correct

const CompanySettings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [settings, setSettings] = useState({
    default_timezone: 'GMT',
    override_email: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [canAmendSettings, setCanAmendSettings] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/settings', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // translate the response data into the format needed by the select box
      const timezoneData = response.data.default_timezone
        ? {
            value: response.data.default_timezone,
            label: response.data.default_timezone,
          }
        : { value: 'GMT', label: 'GMT' };
      setSettings({
        default_timezone: timezoneData,
        override_email: response.data.override_email,
        bcc_emails: response.data.bcc_emails,
      });
      setCanAmendSettings(response.data.can_amend_settings);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setErrorMessage('Error fetching company settings');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleChange = (timezone) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      default_timezone: timezone,
    }));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (settings.override_email) {
      setShowConfirmModal(true);
    } else {
      saveSettings();
    }
  };

  const saveSettings = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        '/settings',
        {
          default_timezone: settings.default_timezone.value,
          override_email: settings.override_email,
          bcc_emails: settings.bcc_emails,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
      setErrorMessage('Error updating company settings');
    } finally {
      setIsLoading(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <div className='company-settings'>
      {isLoading ? (
        <div className='empty-state'>
          <Spinner animation='border' />
        </div>
      ) : (
        <>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
          <div className='settings-set'>
            <h3>Communication Settings</h3>
            <Form>
              <Form.Group controlId='defaultTimezone'>
                <Form.Label>Default Timezone</Form.Label>
                <TimezoneSelect
                  value={settings.default_timezone}
                  onChange={handleChange}
                  isDisabled={!canAmendSettings}
                />
                <Form.Text>
                  Timezone that outbound communication (e.g. emails) will be
                  scheduled for if it's not possible to determine the prospects
                  local time.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId='overrideEmail'>
                <Form.Label>Override Email</Form.Label>
                <Form.Control
                  type='email'
                  name='override_email'
                  value={settings.override_email}
                  onChange={handleEmailChange}
                  disabled={!canAmendSettings}
                />
                <Form.Text>
                  If set, all emails will be sent to this address{' '}
                  <b>(NOTE: This is only recommended for testing)</b>
                </Form.Text>
              </Form.Group>

              <Form.Group controlId='bccEmail'>
                <Form.Label>BCC Emails</Form.Label>
                <CreatableSelect
                  isMulti
                  value={(settings.bcc_emails || []).map((x) => ({
                    label: x,
                    value: x,
                  }))}
                  onChange={(values) => {
                    setSettings((prev) => {
                      return {
                        ...prev,
                        bcc_emails: values.map((x) => x.value),
                      };
                    });
                  }}
                />
                <Form.Text>
                  Your agent will add all listed emails in BCC to every outbound
                  email.
                </Form.Text>
              </Form.Group>
              <div className='save-button-wrapper'>
                <Button onClick={handleSave} disabled={!canAmendSettings}>
                  Save Settings
                </Button>
              </div>
            </Form>
          </div>

          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Override Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                The override email is meant for testing purposes only. All
                emails produced by Dream will be sent to this address. Are you
                sure you want to save this setting?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </Button>
              <Button variant='primary' onClick={saveSettings}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CompanySettings;
