import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import { ReactComponent as DraftIcon } from '../../../icons/draft.svg';
import { ReactComponent as MagicWandIcon } from '../../../icons/magic-wand.svg';
import { ReactComponent as NoMessageViewIcon } from '../../../icons/no-message-view.svg';
import { ReactComponent as LockIcon } from '../../../icons/lock.svg';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import VisualLoadingIndicator from './VisualLoadingIndicator';
import ShowActiveDraft from './ShowActiveDraft';
import DraftAndEmailViewer from './DraftAndEmailViewer';
import EmailResearchPoints from './EmailResearchPoints';
import ConfirmModal from '../../confirm-modals/ConfirmModal';
import EditModeResearchPoints from './EditModeResearchPoints';

const EmailSample = ({
  selectedEmail,
  selectedProspectId,
  oagentId,
  enableSend,
  refreshEmailList,
  optimisticActivateDraft,
  editMode,
  setEditMode,
  forceState,
  forceSave,
  setEditMirror,
  setEditModeSaving,
  wizard,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [emailDetails, setEmailDetails] = useState(selectedEmail);
  const [view, setView] = useState('generated');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [visual, setVisual] = useState(null);
  const [section, setSection] = useState(null);
  const [prospectImage, setProspectImage] = useState(null);
  const [biography, setBiography] = useState(null);
  const [orgImage, setOrgImage] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectionReason, setSelectionReason] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [drafts, setDrafts] = useState([]);
  const [generatedEmail, setGeneratedEmail] = useState(null);
  const [generatedSubject, setGeneratedSubject] = useState(null);
  const [showDraftSwitchConfirm, setShowDraftSwitchConfirm] = useState(false);
  const [generatedData, setGeneratedData] = useState({});
  const [stateUpdated, setStateUpdated] = useState(false);
  const [isNewThread, setIsNewThread] = useState(selectedEmail.is_new_thread);
  const [delay, setDelay] = useState(selectedEmail.delay);
  const [draftActivateError, setDraftActivateError] = useState(null);
  const [generationError, setGenerationError] = useState(null);
  const [showUpdateApprovalsModal, setShowUpdateApprovalsModal] =
    useState(false);

  /* The below are all used for edit mode */
  const [availableResearchPoints, setAvailableResearchPoints] = useState([]);
  const [activeResearchPoints, setActiveResearchPoints] = useState([]);
  const [availableAdditionalData, setAvailableAdditionalData] = useState([]);
  const [activeAdditionalData, setActiveAdditionalData] = useState([]);

  const [regeneratingUnapproved, setRegeneratingUnapproved] = useState(false);

  useEffect(() => {
    if (editMode) {
      const fetchResearchAndDataPoints = async () => {
        try {
          const token = await getAccessTokenSilently();

          const [
            compatibleResponse,
            activeResearchResponse,
            additionalDataResponse,
            activeAdditionalDataResponse,
          ] = await Promise.all([
            api.get(`/analyst/compatible-research-points/${oagentId}`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            api.get(
              `/oagents/${oagentId}/email-research-points/${selectedEmail.id}`,
              { headers: { Authorization: `Bearer ${token}` } },
            ),
            api.get(`/oagents/${oagentId}/additional-data-options`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            api.get(
              `/oagents/${oagentId}/email-additional-data/${selectedEmail.id}`,
              { headers: { Authorization: `Bearer ${token}` } },
            ),
          ]);

          setAvailableResearchPoints(compatibleResponse.data);
          setActiveResearchPoints(activeResearchResponse.data);
          setAvailableAdditionalData(additionalDataResponse.data.uniqueKeys);
          setActiveAdditionalData(activeAdditionalDataResponse.data);
        } catch (error) {
          console.error('Error fetching research points and data:', error);
        }
      };

      fetchResearchAndDataPoints();
    }
  }, [editMode, oagentId, selectedEmail.id, getAccessTokenSilently]);

  /*
    We have to wait till our handleGenerate function updates drafts and then we update them on our memory container
  */

  useEffect(() => {
    setGeneratedData((prevData) => ({
      ...prevData,
      [selectedEmail.id]: {
        ...(prevData[selectedEmail.id] || {}),
        drafts,
      },
    }));
  }, [drafts]);

  /*
    Allows us to have the Save and exit functionality in the edit mode
  */
  useEffect(() => {
    setEditMirror(emailDetails);
  }, [emailDetails]);

  useEffect(() => {
    if (forceState) {
      setEmailDetails(forceState);
    }
  }, [forceState]);

  useEffect(() => {
    setGenerationError(null);
    setIsNewThread(selectedEmail.is_new_thread);
    setDelay(selectedEmail.delay);
  }, [selectedEmail]);

  useEffect(() => {
    if (!editMode) {
      setView('generated');
      setGeneratedEmail(null);
      setGeneratedSubject(null);
      setVisual(null);
      setSection(null);
      setProspectImage(null);
      setBiography(null);
      setOrgImage(null);
      setSelectionReason(null);
      setSelectedProfile(null);
      setSelectedProfileId(null);
      setDrafts([]);
    }
  }, [editMode]);

  useEffect(() => {
    setEmailDetails(selectedEmail);
    setView('generated');
    setLoading(false);
    setProgress(0);

    // Check if there is previously generated data for the selectedEmail
    if (generatedData[selectedEmail.id]) {
      const data = generatedData[selectedEmail.id];
      setGeneratedEmail(data.generatedEmail);
      setGeneratedSubject(data.generatedSubject);
      setVisual(data.visual);
      setSection(data.section);
      setProspectImage(data.prospectImage);
      setBiography(data.biography);
      setOrgImage(data.orgImage);
      setSelectionReason(data.selectionReason);
      setSelectedProfile(data.selectedProfile);
      setSelectedProfileId(data.selectedProfileId);
      setDrafts(data.drafts || []);
    } else {
      // Clear state if no previously generated data exists
      setGeneratedEmail(null);
      setGeneratedSubject(null);
      setVisual(null);
      setSection(null);
      setProspectImage(null);
      setBiography(null);
      setOrgImage(null);
      setSelectionReason(null);
      setSelectedProfile(null);
      setSelectedProfileId(null);
      setDrafts([]);
    }
  }, [selectedEmail]);

  const handleGenerateEmail = async () => {
    setLoading(true);
    setGeneratedEmail(null);
    setGeneratedSubject(null);
    setBiography(null);
    setProspectImage(null);
    setOrgImage(null);
    setProgress(0);
    setVisual(null);
    setSelectionReason(null);
    setSelectedProfile(null);
    setSelectedProfileId(null);
    setSection(null);
    setDrafts([]);
    setGenerationError(null);

    try {
      const token = await getAccessTokenSilently();
      const contents =
        emailDetails.email_order === 0
          ? emailDetails.contents
          : `Please note this is a follow-up in a series of emails we have sent without reply. No need to mention this fact unless otherwise instructed but just for context.\n${emailDetails.contents}`;

      const dataForGenerate = {
        email_id: emailDetails.id,
        oagent_id: oagentId,
        profile_id: null,
        prospect_id: selectedProspectId,
        email_text: contents,
        subject_type: 'agent_generate',
        subject_value: emailDetails.subject_value,
      };

      if (editMode) {
        dataForGenerate.edit_mode_research_points = activeResearchPoints.map(
          (x) => x.research_point_id,
        );
        dataForGenerate.edit_mode_additional_data = activeAdditionalData.map(
          (x) => x.key,
        );
      }

      const response = await api.post(
        '/emails/generate-sample',
        dataForGenerate,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { sample_id } = response.data;

      pusherChannel.bind(`email_sample_generation_update`, (data) => {
        if (data.sample_id === sample_id) {
          if (data.percent !== undefined) {
            setProgress(data.percent);

            if (data.update) {
              console.log(data.update);
              const {
                visual,
                section,
                draftActive,
                draft,
                supervisionNotes,
                prospectImage,
                orgImage,
                biography,
                selectedProfile,
                selectionReason,
                email,
                subject,
                selectedProfileId,
              } = data.update;

              setVisual(visual || null);
              setSection(section || null);

              if (prospectImage) setProspectImage(prospectImage);
              if (orgImage) setOrgImage(orgImage);
              if (biography) setBiography(biography);
              if (selectedProfile) setSelectedProfile(selectedProfile);
              if (selectedProfileId) setSelectedProfileId(selectedProfileId);
              if (selectionReason) setSelectionReason(selectionReason);

              const draftUpdate = (prevDrafts) => {
                let updatedDrafts = [...prevDrafts];

                if (draftActive > updatedDrafts.length) {
                  updatedDrafts.push({ id: draftActive });
                }

                // - 2 because the draftActive on the supervision note action is for the previous draft and we're already +1 indexed
                if (supervisionNotes) {
                  updatedDrafts[draftActive - 2].supervisionNotes =
                    supervisionNotes;
                }

                // - 1 because draftActive is this one and we're +1 indexed
                if (draft) {
                  updatedDrafts[draftActive - 1].draft = draft;
                }

                updatedDrafts = updatedDrafts.map((x) => ({
                  ...x,
                  active: false,
                }));
                updatedDrafts[draftActive - 1].active = true;
                return updatedDrafts;
              };

              if (draftActive) {
                setDrafts(draftUpdate);
              }

              // Store individual updated data in generatedData
              setGeneratedData((prevData) => ({
                ...prevData,
                [selectedEmail.id]: {
                  ...(prevData[selectedEmail.id] || {}),
                  generatedEmail: email || generatedEmail,
                  generatedSubject: subject || generatedSubject,
                  visual: visual || prevData[selectedEmail.id]?.visual,
                  section: section || prevData[selectedEmail.id]?.section,
                  prospectImage:
                    prospectImage || prevData[selectedEmail.id]?.prospectImage,
                  biography: biography || prevData[selectedEmail.id]?.biography,
                  orgImage: orgImage || prevData[selectedEmail.id]?.orgImage,
                  selectionReason:
                    selectionReason ||
                    prevData[selectedEmail.id]?.selectionReason,
                  selectedProfile:
                    selectedProfile ||
                    prevData[selectedEmail.id]?.selectedProfile,
                  selectedProfileId:
                    selectedProfileId ||
                    prevData[selectedEmail.id]?.selectedProfileId,
                },
              }));

              // Set the state updated flag to true
              setStateUpdated(true);
            }
          } else {
            setLoading(false);
            if (data.error) {
              setGenerationError({ error: data.error, email: data.email });
              console.error('Error generating email sample:', data.error);
            } else {
              const newGeneratedEmail = data.email;
              const newGeneratedSubject = data.subject;

              // Store generated data in the dictionary
              setGeneratedData((prevData) => ({
                ...prevData,
                [selectedEmail.id]: {
                  ...(prevData[selectedEmail.id] || {}),
                  generatedEmail: newGeneratedEmail,
                  generatedSubject: newGeneratedSubject,
                },
              }));

              setGeneratedEmail(newGeneratedEmail);
              setGeneratedSubject(newGeneratedSubject);
              setView('generated');
            }
          }
        }
      });

      return () => {
        pusherChannel.unbind(`email_sample_generation_update`);
      };
    } catch (error) {
      console.error('Error starting email sample generation:', error);
      setLoading(false);
    }
  };

  const handleDetailsChange = async (field, value) => {
    if (field === 'delay') {
      setDelay(value);
      handleUpdateEmailDetails(isNewThread, value);
      refreshEmailList();
    }

    if (field === 'isNewThread') {
      setIsNewThread(value);
      handleUpdateEmailDetails(value, delay);
      refreshEmailList();
    }
  };

  const handleUpdateEmailDetails = async (isNewThread, delay) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/emails/sequence-email-details/${selectedEmail.id}`,
        { isNewThread, delay },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      // Show success message or perform additional actions if needed
    } catch (error) {
      alert('Error updating details');
      // Show error message if needed
    }
  };

  // React to state update
  useEffect(() => {
    if (stateUpdated) {
      if (!generatedEmail || !generatedSubject || !selectedProfileId) {
        return;
      }

      if (selectedEmail.email_order !== 0) {
        return;
      }

      enableSend({
        prospect_id: selectedProspectId,
        content: generatedEmail,
        subject: generatedSubject,
        oagent_id: oagentId,
        profile_id: selectedProfileId,
        profile_name: selectedProfile,
        email_id: selectedEmail.id,
      });
      // Reset the state updated flag
      setStateUpdated(false);
    }
  }, [
    stateUpdated,
    selectionReason,
    selectedProfile,
    selectedProfileId,
    generatedEmail,
    generatedSubject,
    selectedEmail,
    selectedProspectId,
    oagentId,
  ]);

  useEffect(() => {
    // Null check should prevent it from happening first time
    if (forceSave !== null) {
      handleSavePrompt(true);
    }
  }, [forceSave]);

  const handleSavePrompt = async (saveFromEditMode) => {
    setLoading(true);
    /*
      NB:  Can be triggered by forceSave changes
    */
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/emails/sequence-email/${emailDetails.id}`,
        {
          contents: emailDetails.contents,
          subject_value: emailDetails.subject_value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (saveFromEditMode) {
        // If we're in edit mode we need to separately save the additional data points and the research points that we've done

        await api.put(
          `/oagents/${oagentId}/email-bulk-update/${emailDetails.id}`,
          {
            researchPointIds: activeResearchPoints.map(
              (x) => x.research_point_id,
            ),
            additionalDataKeys: activeAdditionalData.map((x) => x.key),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        api
          .get(`/emails/unapproved-count/${emailDetails.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.data?.unapproved_count > 0) {
              setShowUpdateApprovalsModal(response.data.unapproved_count);
            }
          })
          .catch((err) => {
            console.error(`Error loading unapproved emails `, err);
          });

        setEditMode(false);
      }

      if (!saveFromEditMode) {
        handleGenerateEmail();
        setView('generated');
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      alert('Error saving, please check and try again');
      setLoading(false);
    } finally {
      setEditModeSaving(false);
      refreshEmailList();
    }
  };

  const handleDraftSwitch = async () => setShowDraftSwitchConfirm(true);

  const handleDraftActivateConfirm = async () => {
    try {
      setShowDraftSwitchConfirm(false);
      optimisticActivateDraft(selectedEmail.id);

      const token = await getAccessTokenSilently();
      await api.put(
        `/emails/sequence-email/${emailDetails.id}/activate`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      refreshEmailList();
    } catch (error) {
      console.error('Error activating draft:', error);
      setDraftActivateError(
        'An error occured activating your draft, please check and try again',
      );
    }
  };

  /*
    I have a nagging suspicion that there's some edge case I recall where we might not have emailDetails
    So this is well protected
  */
  const displayNextTo = () => {
    try {
      if (!emailDetails) {
        return '';
      }
      if (emailDetails.email_order === 0) {
        return 'Initial email';
      } else if (emailDetails.is_new_thread) {
        return 'New thread';
      } else {
        return 'Follow up';
      }
    } catch (e) {
      return '';
    }
  };

  const handleRegenUnapproved = async () => {
    try {
      const token = await getAccessTokenSilently();
      setRegeneratingUnapproved(true);
      await api.post(
        `/emails/rewrite-unapproved/${oagentId}/${emailDetails.id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setShowUpdateApprovalsModal(false);
    } catch (e) {
      console.error('Could not rewrite unapproved ', e);
    } finally {
      setRegeneratingUnapproved(false);
    }
  };
  return (
    <div className='email-sample-wrapper'>
      {view === 'generated' && (
        <div className='email-sample-header'>
          <h5>
            <b>Message preview:</b>{' '}
            {displayNextTo() ? `${displayNextTo()}` : ''}
          </h5>
          <div className='button-wrapper'>
            <Button
              variant='outline-primary'
              className='generate-button'
              onClick={handleGenerateEmail}
            >
              Generate Message
            </Button>
            {selectedEmail?.draft && (
              <Button
                variant='primary'
                className='save-and-add-button'
                onClick={handleDraftSwitch}
              >
                Save and add to sequence
              </Button>
            )}
          </div>
        </div>
      )}
      {draftActivateError && (
        <Alert variant='danger'>{draftActivateError}</Alert>
      )}
      {generationError && (
        <Alert variant='danger'>
          <div className='detailed-email-error-explanation'>
            {generationError.email ? (
              <>
                <p>
                  Your agent was unable to generate your email due to an issue
                  with your prompt. Here are the issues it reported.
                </p>
                {generationError.email.adapted && (
                  <p>
                    <b>"{generationError.email.adapted}"</b>
                  </p>
                )}
                <ul>
                  {generationError.email.issues_preventing_writing.map(
                    (issue, i) => {
                      return <li key={i}>{issue}</li>;
                    },
                  )}
                </ul>
              </>
            ) : (
              <>
                <p>
                  <b>Unable to generate email</b>
                </p>
                <p>{generationError.error}</p>
              </>
            )}
          </div>
        </Alert>
      )}
      <div className='email-sample-area'>
        <div className='email-sample-top-options'>
          {view === 'generated' ? (
            <div className='further-email-options'>
              {(wizard || editMode || selectedEmail.draft) && (
                <>
                  <Button
                    variant='outline-primary'
                    className='customize-prompt-button'
                    onClick={() => setView('prompt')}
                  >
                    <MagicWandIcon /> Customise Prompt
                  </Button>
                  <div className='right-side'>
                    <Form.Group
                      className={`${selectedEmail.email_order === 0 && 'transparent'}`}
                    >
                      <span>Wait</span>
                      <Form.Control
                        type='number'
                        value={delay}
                        size='lg'
                        onChange={(e) =>
                          handleDetailsChange('delay', e.target.value)
                        }
                      />
                      <span>days after last contact</span>
                    </Form.Group>
                    <span
                      className={`${selectedEmail.email_order === 0 && 'transparent'}`}
                    >
                      <input
                        type='checkbox'
                        className='sexy-checkbox'
                        checked={isNewThread}
                        disabled={selectedEmail.email_order === 0}
                        onChange={(e) =>
                          handleDetailsChange('isNewThread', e.target.checked)
                        }
                      />
                      <span>Start a new thread</span>
                    </span>
                  </div>
                </>
              )}
              {!wizard && !editMode && !selectedEmail.draft && (
                <>
                  <Button
                    variant='outline-danger'
                    className='unlock-and-edit'
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    <LockIcon />
                    Unlock and edit
                  </Button>
                </>
              )}
            </div>
          ) : (
            <>
              <h6>Customise Prompt</h6>
              <div className='multi-right-buttons'>
                <Button
                  className='customize-prompt-button'
                  variant='outline-primary'
                  onClick={() => setView('generated')}
                >
                  Cancel
                </Button>
                {editMode ? (
                  <Button
                    className='save-prompt-button'
                    onClick={() => {
                      setView('generated');
                      handleGenerateEmail();
                    }}
                    disabled={loading}
                  >
                    Generate sample
                  </Button>
                ) : (
                  <Button
                    variant='primary'
                    className='save-prompt-button'
                    onClick={() => handleSavePrompt(false)}
                    disabled={loading}
                  >
                    Save Prompt
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <div className='email-sample-main-area'>
          {view === 'prompt' ? (
            <div className='prompt-edit-outer-wrapper'>
              {editMode ? (
                <EditModeResearchPoints
                  researchPoints={availableResearchPoints}
                  additionalDataPoints={availableAdditionalData}
                  activeResearchPoints={activeResearchPoints}
                  setActiveResearchPoints={setActiveResearchPoints}
                  activeAdditionalData={activeAdditionalData}
                  setActiveAdditionalData={setActiveAdditionalData}
                />
              ) : (
                <EmailResearchPoints
                  oagentId={oagentId}
                  emailId={selectedEmail.id}
                />
              )}
              <div className='prompt-view-wrapper'>
                {isNewThread && (
                  <Form.Group>
                    <Form.Label>Subject Guidance</Form.Label>
                    <Form.Control
                      type='text'
                      value={emailDetails.subject_value}
                      onChange={(e) =>
                        setEmailDetails({
                          ...emailDetails,
                          subject_value: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Body Guidance</Form.Label>
                  <Form.Control
                    as='textarea'
                    value={emailDetails.contents}
                    onChange={(e) =>
                      setEmailDetails({
                        ...emailDetails,
                        contents: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </div>
          ) : (
            <div className='generated-email-view-wrapper'>
              {loading ? (
                <>
                  <VisualLoadingIndicator
                    visual={visual}
                    section={section}
                    prospectImage={prospectImage}
                    orgImage={orgImage}
                  />
                  <ProgressBar now={progress} animated />
                  <ShowActiveDraft
                    drafts={drafts}
                    biography={biography}
                    selectedProfile={selectedProfile}
                    selectionReason={selectionReason}
                  />
                </>
              ) : generatedEmail ? (
                <DraftAndEmailViewer
                  generatedEmail={generatedEmail}
                  generatedSubject={generatedSubject}
                  isNewThread={selectedEmail.is_new_thread}
                  drafts={drafts}
                  oagentId={oagentId}
                  emailId={selectedEmail.id}
                  prospectId={selectedProspectId}
                />
              ) : (
                <div className='empty-state'>
                  {selectedEmail?.draft ? (
                    <>
                      <div className='draft-icon'>
                        <DraftIcon />
                      </div>
                      <div>
                        <h6>Draft message</h6>
                        <p>
                          Customise your prompt, then save your message to add
                          it to your outbound sequence.
                        </p>
                      </div>
                      <Button
                        variant='outline-primary'
                        className='customize-prompt-button'
                        onClick={() => setView('prompt')}
                      >
                        <MagicWandIcon /> Customise Prompt
                      </Button>
                    </>
                  ) : !wizard && !editMode ? (
                    <>
                      <div className='lock-icon'>
                        <LockIcon />
                      </div>
                      <div>
                        <h6>
                          This message is active, unlock it to make changes
                        </h6>
                        <p>
                          Once you unlock, you will be able to test changes
                          before making changes to active campaigns
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <NoMessageViewIcon />
                      <div>
                        <h6>See what Dream can do, generate your message</h6>
                        <p>
                          Choose your sample prospect from the menu, then select
                          ‘Generate message’.
                        </p>
                        <p>
                          {' '}
                          If you want to take control over what your message
                          looks like you can choose ‘customise prompt’ to tell
                          your agent what to include.
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {showDraftSwitchConfirm && (
        <ConfirmModal
          show
          handleClose={() => setShowDraftSwitchConfirm(false)}
          title='Save and add to sequence'
          bodyText='Are you sure you want to activate this draft? Doing so will mean it can be sent to prospects in your campaign.'
          confirmLabel='Activate email'
          cancelLabel='Continue editing'
          handleConfirm={handleDraftActivateConfirm}
        />
      )}

      {showUpdateApprovalsModal && (
        <ConfirmModal
          show
          verticalButtons
          handleClose={() => setShowUpdateApprovalsModal(false)}
          title='Update existing approvals'
          bodyText={
            <>
              <p>
                You have {showUpdateApprovalsModal} outstanding approvals using
                your old prompt, would you like to update these to use your new
                sequence
              </p>
            </>
          }
          confirmLabel={
            regeneratingUnapproved ? (
              <>
                <Spinner size='sm' /> Regenerating
              </>
            ) : (
              'Yes, rewrite those emails'
            )
          }
          confirmDisabled={regeneratingUnapproved}
          cancelLabel='No, keep existing messages'
          centered
          handleConfirm={handleRegenUnapproved}
        />
      )}
    </div>
  );
};

export default EmailSample;
