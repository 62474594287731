import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { Spinner, Alert, Button } from 'react-bootstrap';
import EmailList from '../../emails/EmailList';
import { useAuth0 } from '@auth0/auth0-react';
import SalesTeam from './SalesTeam';
import '../../emails/emails.css';
import api from '../../../api';
import EmailSample from './EmailSample';

import FakePic from '../../feed/items/FakePic';

import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { useSidebar } from '../../../contexts/SidebarContext';

const Messaging = ({ oagentId, enableSend, wizard }) => {
  const [emailList, setEmailList] = useState([]);
  const [selectedSequenceId, setSelectedSequenceId] = useState('');
  const [loading, setLoading] = useState(true);
  const [emailListRefresh, setEmailListRefresh] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [error, setError] = useState(null);
  const [prospects, setProspects] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);

  // We use all these for edit mode
  const [editMode, setEditMode] = useState(false);
  const [editMirror, setEditMirror] = useState(null);
  const [returnState, setReturnState] = useState(null);
  const [forceState, setForceState] = useState(null);
  const [forceSave, setForceSave] = useState(null); // Must be null not false
  const [editModeSaving, setEditModeSaving] = useState(false);

  const { setImposedWarnings } = useSidebar();

  const { getAccessTokenSilently } = useAuth0();

  const fetchProspects = useCallback(
    async (token) => {
      try {
        const response = await api.get(`/oagents/${oagentId}/prospects`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const prospectOptions = response.data.map((prospect) => ({
          value: prospect.id,
          label: (
            <div className='prospect-in-select'>
              <FakePic url={prospect.logo_url} />
              {prospect.name} ({prospect.org_name})
            </div>
          ),
        }));
        setProspects(prospectOptions);
      } catch (error) {
        console.error('Error fetching prospects:', error);
        setError('Could not fetch prospects');
      }
    },
    [oagentId],
  );

  const fetchInitialSequence = useCallback(
    async (token, retainEmail) => {
      try {
        const response = await api.get(
          `/oagents/${oagentId}/email-strategy-details`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.data.sequence_id) {
          setSelectedSequenceId(response.data.sequence_id);
          const sequenceResponse = await api.get(
            `/emails/${response.data.sequence_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const activeEmails = sequenceResponse.data.emails.filter(
            (x) => x.active || x.draft,
          );
          setEmailList(activeEmails);
          setCurrentEmail(retainEmail || activeEmails[0]);
        }
      } catch (error) {
        console.error('Error fetching email strategy details:', error);
        setLoading(false);
        setError('Could not fetch email strategy details');
      }
    },
    [oagentId],
  );

  useEffect(() => {
    const refresh = async () => {
      const token = await getAccessTokenSilently();
      fetchInitialSequence(token, currentEmail);
    };
    refresh();
  }, [emailListRefresh]);

  const syncMessaging = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const syncResponse = await api.post(
        `/oagents/messaging-sync`,
        { oagent_id: oagentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setSelectedProspect(syncResponse.data.preferred_prospect.id);

      // Once sync is done, fetch the initial sequence
      await fetchInitialSequence(token, currentEmail);
      await fetchProspects(token);
    } catch (error) {
      console.error('Error syncing messaging:', error);
      setError('Could not sync messaging');
    } finally {
      setLoading(false);
    }
  }, [oagentId, getAccessTokenSilently, fetchInitialSequence, fetchProspects]);

  const handleEmailSelect = (email) => setCurrentEmail(email);

  const optimisticActivateDraft = (emailId) => {
    if (emailId === currentEmail?.id) {
      setCurrentEmail((currentEmail) => {
        return {
          ...currentEmail,
          draft: false,
          active: true,
        };
      });
    }

    setEmailList((emailList) => {
      return emailList.map((email) => {
        if (email.id === emailId) {
          return {
            ...email,
            active: true,
            draft: false,
          };
        }
        return email;
      });
    });
  };

  useEffect(() => {
    syncMessaging();
  }, [syncMessaging]);

  const handleSetEditMode = (value) => {
    // We can just come back to this if we like
    // We need the trackChange because otherwise we wouldn't be able to force the same state
    // through more than once which would mean on the second update, emailDetails don't get reverted
    setReturnState({
      ...editMirror,
      trackChange: new Date(),
    });
    setEditMode(value);
  };

  const endEditMode = () => {
    setEditMode(false);
    setForceState(returnState);
  };

  const saveEditMirror = () => {
    setEditModeSaving(true);
    setForceSave(!forceSave);
  };

  const handleBeforeUnload = useCallback(
    (event) => {
      if (editMode) {
        // Standard behavior for `beforeunload` requires preventing the default
        event.preventDefault();
        // Most browsers also require setting the returnValue
        event.returnValue = '';
      }
    },
    [editMode],
  );

  useEffect(() => {
    if (editMode) {
      // Add event listener when edit mode is enabled
      setImposedWarnings(true);
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      setImposedWarnings(false);
      // Remove event listener when edit mode is disabled
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    // Clean up listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [editMode, handleBeforeUnload]);

  return (
    <div className='messaging-wrapper'>
      {editMode && (
        <div className='edit-mode-banner'>
          <EditIcon />
          <div className='edit-banner-left'>
            <p>You're editing this email</p>
            <p>Your changes won't get applied until you Save and exit</p>
          </div>
          <div className='edit-banner-right'>
            <Button
              variant='outline-primary'
              onClick={() => endEditMode()}
              disabled={editModeSaving}
            >
              Exit without saving
            </Button>
            <Button
              variant='outline-primary'
              onClick={() => saveEditMirror()}
              disabled={editModeSaving}
            >
              {editModeSaving ? (
                <>
                  <Spinner size='sm' /> Saving
                </>
              ) : (
                'Save and exit'
              )}
            </Button>
          </div>
        </div>
      )}
      <div className='message-wrapper'>
        <div className='left-side'>
          <div className='prospect-choose-area'>
            <div className='set'>
              <h5>Sample Prospect</h5>
              <p>Which prospect do you want to test messaging for.</p>
            </div>
            {!loading && (
              <Select
                className='react-select'
                classNamePrefix='react-select'
                options={prospects}
                value={prospects.find(
                  (prospect) => prospect.value === selectedProspect,
                )}
                onChange={(option) => setSelectedProspect(option.value)}
              />
            )}
            {loading && (
              <div className='fake-select-loading'>
                <Spinner size='sm' /> Loading
              </div>
            )}
          </div>
          <div className='sequence-editing-area'>
            {loading ? (
              <div className='loading-state'></div>
            ) : (
              <div className='set'>
                <div>
                  <h5>Email sequence</h5>
                  <p>
                    Create your email sequence, and preview how they’ll be
                    tailored to your prospects.
                  </p>
                </div>
                <EmailList
                  sequenceId={selectedSequenceId}
                  emails={emailList}
                  setEmails={setEmailList}
                  oagentId={oagentId}
                  onChooseEmail={handleEmailSelect}
                  selectedEmail={currentEmail}
                  refreshEmails={fetchInitialSequence}
                  editMode={editMode}
                  onDelete={(emailId) => {
                    if (emailId === currentEmail.id) {
                      setCurrentEmail(emailList[0]);
                    }
                  }}
                  wizard={wizard}
                />
              </div>
            )}
          </div>
          <div className={editMode ? 'transparent-sales-team' : ''}>
            <SalesTeam oagentId={oagentId} />
          </div>
        </div>
        <div className='right-side'>
          {error && <Alert variant='danger'>{error}</Alert>}
          {currentEmail && (
            <EmailSample
              selectedEmail={currentEmail}
              oagentId={oagentId}
              selectedProspectId={selectedProspect}
              enableSend={enableSend}
              editMode={editMode}
              setEditMode={handleSetEditMode}
              refreshEmailList={() => setEmailListRefresh(!emailListRefresh)}
              optimisticActivateDraft={optimisticActivateDraft}
              setEditMirror={setEditMirror}
              forceState={forceState}
              forceSave={forceSave}
              setEditModeSaving={setEditModeSaving}
              wizard={wizard}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Messaging;
