import React, { memo, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import FakePic from '../../feed/items/FakePic';
const FP = memo(FakePic);

function NameCell(props) {
  const { value, data } = props;

  return (
    <div className='logo-name'>
      {data.has_synced_org ? (
        <FP url={data.logo_url} type='org' />
      ) : (
        <div className='spinner-wrapper'>
          <Spinner
            animation='border'
            className='org-syncing-spinner'
            size='sm'
          />
        </div>
      )}
      <span>{value}</span>
    </div>
  );
}

export default memo(NameCell);
