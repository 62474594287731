import React, { useState } from 'react';

import './conversation-opts.css';

import { ReactComponent as ContactCardIcon } from '../../icons/contact-card.svg';
import { ReactComponent as BusinessIcon } from '../../icons/business.svg';
import { ReactComponent as ForwardIcon } from '../../icons/forward.svg';
import { ReactComponent as PauseIcon } from '../../icons/pause.svg';
import { ReactComponent as ReactivateIcon } from '../../icons/reactivate.svg';
import { Alert, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DreamSideModal from './DreamSideModal';
import DreamModalInner from './DreamModalInner';
import PauseConversationSection from './PauseConversationSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import DreamSideModalLoader from './DreamSideModalLoader';

function ConversationOpts({
  onHide,
  conversation,
  offlineToggle,
  forward,
  setConversation,
  linkedIn,
  koLinkedIn,
  koDomain,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [showForward, setShowForward] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [forwardEmails, setForwardEmails] = useState('');
  const [pauseConversation, setPauseConversation] = useState(
    conversation.offline,
  );
  const [pauseConversationReason, setPauseConversationReason] = useState('');
  const [isForwarding, setIsForwarding] = useState(false);
  const [forwardingError, setForwardingError] = useState(false);

  const openForward = () => {
    setShowPause(false);
    setShowForward(true);
    setForwardEmails('');
  };

  const openPause = () => {
    setShowPause(true);
    setShowForward(false);
  };

  const forwardConversation = async () => {
    try {
      setIsForwarding(true);
      const token = await getAccessTokenSilently(); // Get the access token
      await api.post(
        `/conversations/${conversation.id}/forward-emails`,
        {
          emailAddresses: forwardEmails.split(','),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (pauseConversation) {
        handlePauseConversation();
      }

      setShowForward(false);
    } catch {
      setForwardingError(true);
    } finally {
      setIsForwarding(false);
    }
  };

  const handlePauseConversation = async (reactivate) => {
    try {
      setIsForwarding(true);

      if (reactivate) {
        setPauseConversation(false);
      }

      const token = await getAccessTokenSilently(); // Get the access token
      await api.put(
        `/conversations/${conversation.id}/toggle-offline`,
        {
          offline: reactivate ? false : pauseConversation,
          reason: pauseConversationReason
            ? pauseConversation.value
            : 'None Given',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setShowPause(false);

      setConversation((conversation) => {
        return {
          ...conversation,
          offline: reactivate ? false : pauseConversation,
        };
      });
    } catch {
      // setPausingError(true);
    } finally {
      setIsForwarding(false);
    }
  };

  return (
    <>
      {showForward && !isForwarding && (
        <DreamSideModal show handleClose={() => setShowForward(false)}>
          <DreamModalInner>
            <DreamModalInner.Header>
              Takeover conversation
            </DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='dream-side-modal-form conversation-opts-form'>
                {forwardingError && (
                  <Alert variant='danger'>
                    Error forwarding email, please try again later.
                  </Alert>
                )}
                <Form.Group>
                  <Form.Label>Forwarding emails</Form.Label>
                  <Form.Text>
                    Your conversation to date will be forwarded to these
                    addresses, you can carry it on from there. Separate multiple
                    emails with a comma.
                  </Form.Text>
                  <Form.Control
                    as='textarea'
                    value={forwardEmails}
                    onChange={(e) => setForwardEmails(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <PauseConversationSection
                    pauseConversation={pauseConversation}
                    setPauseConversation={setPauseConversation}
                    pauseConversationReason={pauseConversationReason}
                    setPauseConversationReason={setPauseConversationReason}
                    showPaused={conversation.offline}
                    reactivate={() => handlePauseConversation(true)}
                  />
                </Form.Group>
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <Button
                onClick={() => forwardConversation()}
                disabled={!forwardEmails}
              >
                Takeover conversation
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => setShowForward(false)}
              >
                Cancel
              </Button>
            </DreamModalInner.Footer>
          </DreamModalInner>
        </DreamSideModal>
      )}

      {showPause && !isForwarding && (
        <DreamSideModal show handleClose={() => setShowPause(false)}>
          <DreamModalInner>
            <DreamModalInner.Header>Pause conversation</DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='dream-side-modal-form'>
                <PauseConversationSection
                  pauseConversation={pauseConversation}
                  setPauseConversation={setPauseConversation}
                  pauseConversationReason={pauseConversationReason}
                  setPauseConversationReason={setPauseConversationReason}
                  showPaused={conversation.offline}
                  reactivate={() => handlePauseConversation(true)}
                />
              </div>
            </DreamModalInner.Body>
            {!conversation.offline && (
              <DreamModalInner.Footer>
                <Button
                  onClick={() => handlePauseConversation()}
                  disabled={!pauseConversation}
                >
                  Toggle status
                  <div className='yellow-icon'>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </Button>
                <Button
                  variant='outline-primary'
                  onClick={() => setShowPause(false)}
                >
                  Cancel
                </Button>
              </DreamModalInner.Footer>
            )}
          </DreamModalInner>
        </DreamSideModal>
      )}

      {isForwarding && (
        <DreamSideModal show>
          <DreamModalInner>
            <DreamSideModalLoader>Loading</DreamSideModalLoader>
          </DreamModalInner>
        </DreamSideModal>
      )}

      <DreamModalInner.OptsMenu closeCascade={onHide}>
        <div className='dream-opts-list'>
          <h4>Conversation actions</h4>
          <div className={`action ${linkedIn ? '' : 'disabled'}`}>
            <ContactCardIcon />
            {linkedIn ? (
              <a href={linkedIn} target='_blank' rel='noopener noreferrer'>
                View prospect
              </a>
            ) : (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Profile unavailable</Tooltip>}
              >
                <span>View prospect</span>
              </OverlayTrigger>
            )}
          </div>
          <div className={`action ${koLinkedIn || koDomain ? '' : 'disabled'}`}>
            <BusinessIcon />
            {koLinkedIn || koDomain ? (
              <a
                href={koLinkedIn || koDomain}
                target='_blank'
                rel='noopener noreferrer'
              >
                View company
              </a>
            ) : (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Company unavailable</Tooltip>}
              >
                <span>View company</span>
              </OverlayTrigger>
            )}
          </div>
          {forward && (
            <div className='action' onClick={openForward}>
              <ForwardIcon />
              Takeover this conversation
            </div>
          )}
          {offlineToggle && (
            <div className='action' onClick={openPause}>
              {!conversation.offline ? (
                <>
                  <PauseIcon />
                  Pause this conversation
                </>
              ) : (
                <>
                  <ReactivateIcon />
                  Activate this conversation
                </>
              )}
            </div>
          )}
        </div>
      </DreamModalInner.OptsMenu>
    </>
  );
}

export default ConversationOpts;
