import React from 'react';
import linkedinCountries from '../linkedin-countries';
import linkedinIndustries from '../linkedin-industries';
import { ReactComponent as RecentHiringIcon } from '../../icons/recent-hiring.svg';

const recentlyHiredManifest = {
  name: 'Recent Hires Search',
  type: 'recent_hires_search',
  icon: <RecentHiringIcon />,
  is_foundational_type: true,
  description:
    'Search for companies that have recently hired people in certain roles.',
  match_language: 'Companies matching criteria',
  match_sample_text: 'recent hires found',
  list_language: 'Companies retrieved',
  auto_generate_filter: false,
  additional_options: {
    job_titles: {
      description: 'Job Titles',
      long_description: 'Which job titles should we look for listings for',
      input_type: 'multiselect-jobtitle-help',
      dev_name: 'job_titles',
    },
    industries: {
      description: 'Industries',
      long_description: 'Which industries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinIndustries,
      dev_name: 'industries',
    },
    locations: {
      description: 'Countries',
      long_description: 'Which countries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinCountries,
      dev_name: 'countries',
    },
    hired_in_the_last_days: {
      description: 'Days in the job',
      long_description: 'How many days back should we look for new hires',
      input_type: 'number',
      dev_name: 'hired_in_the_last_days',
      short: true,
    },
    min_headcount: {
      description: 'Min headcount',
      long_description: 'The minimum size of the new employer',
      input_type: 'number',
      short: true,
      dev_name: 'min_headcount',
    },
    max_headcount: {
      description: 'Max headcount',
      long_description: 'The maximum size of the new employer',
      input_type: 'number',
      short: true,
      dev_name: 'max_headcount',
    },
  },
  tests: [],
  credit_cost: 2,
  speed_description: (
    <p>
      Agent will retrieve up to <b>300</b> companies per hour.
    </p>
  ),
};

export default recentlyHiredManifest;
