import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import './funnel.css';

const DownloadCSVButton = ({ name, downloadData }) => {
  if (!downloadData) {
    return <></>;
  }

  return (
    <button
      className='download-csv-button'
      onClick={() => downloadData({ skipPinnedBottom: true })}
      disabled={!downloadData}
    >
      <DownloadIcon /> Download CSV
    </button>
  );
};

export default DownloadCSVButton;
