import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';

import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import './crm.css';

function CRMFields({ onChange, selectedFields, type }) {
  const { getAccessTokenSilently } = useAuth0();
  const [availableFields, setAvailableFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relativeInputs, setRelativeInputs] = useState(
    selectedFields.map(() => ({ amount: 0, unit: 'days' })),
  ); // For managing relative inputs

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get(`/crm/${type}/fields`, { headers });

        setAvailableFields(response.data.fields);
      } catch (error) {
        console.error('Error fetching fields:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, [getAccessTokenSilently]);

  const addFieldFilter = () => {
    onChange([
      ...selectedFields,
      { key: '', value: '', type: '', operator: '=' },
    ]);
    setRelativeInputs([...relativeInputs, { amount: 0, unit: 'days' }]); // Initialize relative inputs
  };

  const removeFieldFilter = (index) => {
    onChange(selectedFields.filter((_, i) => i !== index));
    setRelativeInputs(relativeInputs.filter((_, i) => i !== index)); // Remove relative inputs
  };

  const updateFieldFilter = (index, update) => {
    const newFields = [...selectedFields];
    newFields[index] = { ...newFields[index], ...update };
    onChange(newFields);
  };

  const handleRelativeInputChange = (index, amount, unit) => {
    // Update the state showing amount and unit for the user
    const updatedInputs = [...relativeInputs];
    updatedInputs[index] = { amount, unit };
    setRelativeInputs(updatedInputs);

    // Convert to milliseconds and update field filter
    const unitToMilliseconds = {
      days: 24 * 60 * 60 * 1000,
      weeks: 7 * 24 * 60 * 60 * 1000,
      months: 30 * 24 * 60 * 60 * 1000,
    };
    const valueInMilliseconds = amount * unitToMilliseconds[unit];
    updateFieldFilter(index, { value: valueInMilliseconds });
  };

  // Map operators to plain English
  const operatorOptions = {
    '=': 'Equals',
    '!=': 'Does not equal',
    '>': 'Greater than',
    '<': 'Less than',
    '>=': 'Greater than or equal to',
    '<=': 'Less than or equal to',
  };

  const renderFieldFilter = (filter, index) => {
    const currentField = availableFields.find(
      (field) => field.key === filter.key,
    );

    return (
      <>
        <div key={index} className='field-filter-group'>
          <div className='field-wrapper'>
            <div className='field-topper'>
              <Select
                value={{ value: filter.key, label: filter.key }}
                options={availableFields.map((field) => ({
                  value: field.key,
                  label: field.key,
                }))}
                onChange={(selected) => {
                  const selectedField = availableFields.find(
                    (field) => field.key === selected.value,
                  );
                  if (selectedField) {
                    updateFieldFilter(index, {
                      key: selected.value,
                      type: selectedField.type,
                    });
                  }
                }}
                placeholder='Select Field'
              />
              {filter.type && (
                <Form.Control
                  as='select'
                  value={filter.operator}
                  onChange={(e) =>
                    updateFieldFilter(index, { operator: e.target.value })
                  }
                >
                  {filter.type === 'text' && (
                    <>
                      <option value='='>{operatorOptions['=']}</option>
                      <option value='!='>{operatorOptions['!=']}</option>
                    </>
                  )}
                  {filter.type === 'numeric' && (
                    <>
                      {Object.entries(operatorOptions).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </>
                  )}
                  {filter.type === 'date' && (
                    <>
                      {Object.entries(operatorOptions).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              )}
            </div>
            <div className='field-mid-section'>
              {filter.type && (
                <>
                  {filter.type === 'date' && (
                    <>
                      <div className='date-radio-line'>
                        <Form.Check
                          type='radio'
                          id={`exact-${index}`}
                          label='Exact Date'
                          name={`date-type-${index}`}
                          checked={filter.comparison !== 'relative'}
                          onChange={() =>
                            updateFieldFilter(index, { comparison: undefined })
                          }
                        />
                        <Form.Check
                          type='radio'
                          id={`relative-${index}`}
                          label='Relative Date'
                          name={`date-type-${index}`}
                          checked={filter.comparison === 'relative'}
                          onChange={() =>
                            updateFieldFilter(index, { comparison: 'relative' })
                          }
                        />
                      </div>
                      {filter.comparison === 'relative' ? (
                        <div className='relative-date-inputs'>
                          <Form.Control
                            type='number'
                            value={relativeInputs[index].amount}
                            onChange={(e) =>
                              handleRelativeInputChange(
                                index,
                                parseInt(e.target.value, 10) || 0,
                                relativeInputs[index].unit,
                              )
                            }
                            placeholder='Amount'
                          />
                          <Form.Control
                            as='select'
                            value={relativeInputs[index].unit}
                            onChange={(e) =>
                              handleRelativeInputChange(
                                index,
                                relativeInputs[index].amount,
                                e.target.value,
                              )
                            }
                          >
                            <option value='days'>Days</option>
                            <option value='weeks'>Weeks</option>
                            <option value='months'>Months</option>
                          </Form.Control>
                        </div>
                      ) : (
                        <Form.Control
                          type='date'
                          value={filter.value}
                          onChange={(e) =>
                            updateFieldFilter(index, { value: e.target.value })
                          }
                        />
                      )}
                    </>
                  )}
                  {filter.type !== 'date' &&
                    (currentField &&
                    currentField.values &&
                    currentField.values.length > 0 ? (
                      // Render multi-select for fields with predefined values
                      <Select
                        isMulti
                        value={
                          filter.value
                            ? filter.value.map((val) => ({
                                value: val,
                                label: val,
                              }))
                            : []
                        }
                        options={currentField.values.map((value) => ({
                          value,
                          label: value,
                        }))}
                        onChange={(selectedOptions) =>
                          updateFieldFilter(index, {
                            value: selectedOptions.map(
                              (option) => option.value,
                            ),
                          })
                        }
                        placeholder='Select Values'
                      />
                    ) : (
                      // Render standard input for other fields
                      <Form.Control
                        type='text'
                        value={filter.value}
                        onChange={(e) =>
                          updateFieldFilter(index, { value: e.target.value })
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </div>
          <div className='trash-icon-wrapper'>
            <TrashIcon onClick={() => removeFieldFilter(index)} />
          </div>
        </div>
        <p className='and'>AND</p>
      </>
    );
  };

  if (loading) {
    return (
      <div className='spinner-wrapper dream-side-modal-form-input-loading'>
        <Spinner animation='border' />
        <span className='spinner-loader'>Loading fields...</span>
      </div>
    );
  }

  return (
    <div className='crm-filter-set'>
      {selectedFields.map(renderFieldFilter)}
      <Button
        variant='outline-primary'
        onClick={addFieldFilter}
        className='add-crm-filter-button'
      >
        Add Field Filter <PlusIcon />
      </Button>
    </div>
  );
}

export default CRMFields;
